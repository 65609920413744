//Skus Table

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions



import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import {
	useLocation,
	useHistory
} from "react-router-dom";

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';

//Common Utilities
import RestrictInputNumber from "../common/RestrictInputNumber";

//Search Tools
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

//Column Toggles
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';

//Icons
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

//Export
import ExportCSV from '../common/ExportCSV';

//Datetime formatting
import dayjs from 'dayjs'; //Used with new datetimepickers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//Common Utilities
import SearchInput from "../common/SearchInput"; //Don't forget localization adapter and switch to DayJS!



/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;


//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 30000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

const CopyContent = (content) => {
	navigator.clipboard.writeText(content);
	//alert("Copied the text: " + content);
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}


const SkusTable = (props) => {
	document.title = "Skus";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Products"));
	dispatch(setCurrentMenuItem("/skus"));

	const rowRefs = useRef([]);

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;


	/* Params */
	const params = new URLSearchParams(useLocation().search);
	// These parameters can help auto-fill an autocomplete search input!
	// Links from: ProductsTable
	var productparameter = false;
	var productidparameter = params.get('ProductID');
	var productnameparamter = params.get('ProductName');
	if (productidparameter){
		productparameter = [{
				ProductID: productidparameter,
				Name: productnameparamter
			}
		];
	}


	//Container and Key Sizes
	const containersizes = "260px";
	const keysizes = "130px";

	//Search Configuration:
	const activesearchescount = 8;

	//Search Select Inputs
	const defaultselectinputs = [
		{ value: "Sku", text: "Sku" },
		{ value: "Title", text: "Title" },
		{ value: "products", text: "Product" },
		{ value: "components", text: "Components" },
		{ value: "Gtin", text: "GTIN" },
		{ value: "Location", text: "Location" },
		{ value: "SkuEnding", text: "Sku Ending" },
		{ value: "NotSku", text: "Not Sku" },
		{ value: "CreatedAfter", text: "Created After" },
		{ value: "CreatedBefore", text: "Created Before" },
		{ value: "Configured", text: "Configured" }
	];


	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		Sku: {
			type: "Sku",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		Title: {
			type: "Title",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		//Products AutoComplete
		products: {
			type: "products",
			keyedby: "ProductID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey: "Name", //This is the column we are searching from the table
			value: (productparameter ? productparameter : []),
			multiple: true,
			//inputValue:"", //Text Search! This *CAN* be used... but seems nicer without it!
			mode: "autocomplete",
			limit: 10, //Database return limit, keep below ~100
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
			debouncetime:200
		},
		//Components Autocomplete - Static - Feed values manually or from a dataset (LoadItems)
		components: {
			type: "components",
			keyedby: "ID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey: "Name", //This is the column we are searching from the table
			value: [], //TRY: Feed values into this from DB results on grid (LoadItems)
			menuitems: [],
			mode: "autocompletestatic",
			defaultsearchterm: "",
			limit: 20, //Database return limit, keep below ~100
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			multiple: true,
			grow: 1,
			debouncetime:200
		},
		Gtin: {
			type: "Gtin",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		Location: {
			type: "Location",
			value: "",
			mode: "like",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		SkuEnding: {
			type: "SkuEnding",
			value: "",
			mode: "right",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		NotSku: {
			type: "NotSku",
			value: "",
			mode: "not",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		//Standard DateTimeAfter
		CreatedAfter: {
			type: "CreatedAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 50); //Set to 50 years ago
				// return date;
				return dayjs().subtract(10, 'year');

			})(),
			mode: "datetimeafter",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		//Standard DateTimeBefore
		CreatedBefore: {
			type: "CreatedBefore",
			value: dayjs(), //new Date, 
			mode: "datetimebefore",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		Configured: {
			type: "Configured",
			value: "",
			mode: "bool",
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
	}





	/* ##########################  UseState Variables  ########################## */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true, //Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		integrations: [],
		integrationsloaded: false,
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "asc",
		orderby: "Sku",
		selectedcount: 0,
		rowsperpage: 250,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Reserved for basic searchpairs.
				searchpair1: inputdefaults.Sku,
				searchpair2: inputdefaults.NotSku,
				searchpair3: inputdefaults.SkuEnding,
				searchpair4: inputdefaults.products,
				searchpair5: inputdefaults.components,
				searchpair7: inputdefaults.Title,
				searchpair6: inputdefaults.Location,
				searchpair8: inputdefaults.Configured
			},
			nestedrelationships: ['skuintegrations.integration'],
			skulistname: "",
			skulist: "",
			skuscreated: "" //Placeholder for option to open these skus.
		},
		savecosts: false,
		allowcomponentupdate: false,
		newcomponenttype: "",
		addcomponentmode: false,
		showproductcomponents: false
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}



	/* ##########################  Top Button Refs and Functions  ########################## */
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnEditSelected = useRef();
	const btnAddRow = useRef();
	//const btnDeleteSelected = useRef();
	const btnResetSearches = useRef();
	const btnExport = useRef();
	const btnAddIntegration = useRef();
	const btnSkuList = useRef();
	const btnImportSkus = useRef();
	//const btnCreateList = useRef();


	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnEditSelected.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		//btnDeleteSelected.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnAddIntegration.current.setAttribute("disabled", true);
		btnSkuList.current.setAttribute("disabled", true);
		btnImportSkus.current.setAttribute("disabled", true);
		//btnCreateList.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnEditSelected.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		//btnDeleteSelected.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnAddIntegration.current.removeAttribute("disabled");
		btnSkuList.current.removeAttribute("disabled");
		btnImportSkus.current.removeAttribute("disabled");
		//btnCreateList.current.removeAttribute("disabled");
	}





	/* ##########################  Column States - Colstates  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Sku: true,
		Title: true,
		Gtin: false,
		ProductID: false,
		btnAllowOldRecords: true,
		Product: true, //Child table value
		Location: true,
		Components: false, //Derived from colstates below
		SkuComponents: false,
		ProductComponents: false,
		Discount: true,
		CalcCost: true,
		Cost: true,
		GlobalTGI: true,
		Integrations: true, //Derived from relationship
		updated_at: true
	});

	const UpdateColState = (colstate) => {
		setColState(colstate);
	}



	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	const [key5, setKey5] = useState(uuidv4());
	const [key6, setKey6] = useState(uuidv4());
	const [key7, setKey7] = useState(uuidv4());
	const [key8, setKey8] = useState(uuidv4());


	//Used for <SearchInput />
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Only rerender table if searchvalue wasn't blank!
		console.log("Typeof: " + typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value);
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'object') {
			console.log("Object");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value.length > 0) {
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'string') {
			console.log("String");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value !== "") {
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'number') {
			//Doesn't matter, 0 or 1, we still need to allow this bool to clear
			console.log("number");
			ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		//Special Provision to reload if choosing components:
		if (searchpair.type === 'components') {
			console.log("components");
			ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
		//Ensures the input is rerendered and will work correctly:
		setKey1(uuidv4());
		setKey2(uuidv4());
		setKey3(uuidv4());
		setKey4(uuidv4());
		setKey5(uuidv4());
		setKey6(uuidv4());
		setKey7(uuidv4());
		setKey8(uuidv4());
	};

	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1200);

	//AutoComplete Still needs to send an update so that our inputValue can remain between multiple selections:
	const onChangeAutoCompleteInput = (searchpair, searchnumber) => {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
	};

	const ResetSearches = () => {
		history.push('/skus');
		// //Reset all:
		// localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.Sku;
		// localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.NotSku;
		// localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.SkuEnding;
		// localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.products;
		// localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.components;
		// localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Title;
		// localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.Location;
		// localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.Configured;

		// //Set Defaults:
		// localstate.searchoptions.skulist = "";
		// setListName("");
		// localstate.dbreload = true;
		// UpdateState(localstate);
		// //Forcefully rerender search inputs!
		// ResetKeys([], true);
	}

	const ResetKeys = (keyarray, resetallbool) =>{
		if (resetallbool){
			setKey1(uuidv4());
			setKey2(uuidv4());
			setKey3(uuidv4());
			setKey4(uuidv4());
			setKey5(uuidv4());
			setKey6(uuidv4());
			setKey7(uuidv4());
			setKey8(uuidv4());
		} else {
			for (var i=0; i<keyarray.length; i++){
				if (keyarray[i]===1){
					setKey1(uuidv4());
				}
				if (keyarray[i]===2){
					setKey2(uuidv4());
				}
				if (keyarray[i]===3){
					setKey3(uuidv4());
				}
				if (keyarray[i]===4){
					setKey4(uuidv4());
				}
				if (keyarray[i]===5){
					setKey5(uuidv4());
				}
				if (keyarray[i]===6){
					setKey6(uuidv4());
				}
				if (keyarray[i]===7){
					setKey7(uuidv4());
				}
				if (keyarray[i]===8){
					setKey8(uuidv4());
				}
			}
		}
	}

	//Expand Search
	const ToggleExpandSearch = () => {
		localstate.expandsearch = !localstate.expandsearch;

		if (!localstate.expandsearch) {
			for (var i = 5; i <= activesearchescount; i++) {
				//Only rerender if one of the searches wasn't blank!
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'object') {
					if (localstate.searchoptions.searchpairs["searchpair" + i].value.length > 0) {
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'string') {
					console.log(localstate.searchoptions.searchpairs["searchpair" + i].value);
					if (localstate.searchoptions.searchpairs["searchpair" + i].value !== "") {
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
			}
			//Proceed to Reset Partial Searches 5-8:
			localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.components;
			localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Title;
			localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.Location;
			localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.Configured;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}

	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length === 0) {
			//errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
			dispatch(newErrorMessage({ errmsg: "No items selected.", errshow: true, errtimeout: 5, errtype: 'neutral' }));
			dispatch(setErrorTimeout(5));
		} else {
			//If all tests pass, use callback

			//Provision to disable Add Component if sku sku.product.ProductType are not alike.
			if (localstate.griditems[localstate.selectedindexes[0]].hasOwnProperty('product')) {
				//OK, so the product property may exist, but it's value is null.
				if (localstate.griditems[localstate.selectedindexes[0]].product) {
					var producttype = localstate.griditems[localstate.selectedindexes[0]].product.ProductType;
					for (var j = 0; j < localstate.selectedindexes.length; j++) {
						//If no product\producttype set for any of the SKUs, don't allow component updates

						//MRH Note: I noticed this logic below might be somewhat arbitrary. Newcomponenttype could be true or false just depending on the last instance of selected item.
						if (localstate.griditems[localstate.selectedindexes[j]].product) {
							localstate.allowcomponentupdate = true;
							localstate.newcomponenttype = producttype;
							//Passes first test
						} else {
							localstate.allowcomponentupdate = false;
						}
					}
					if (localstate.allowcomponentupdate) {
						if (localstate.selectedindexes.length > 1) {
							for (var k = 1; k < localstate.selectedindexes.length; k++) {
								if (!localstate.griditems[localstate.selectedindexes[k]].product) {
									localstate.allowcomponentupdate = false;
								} else if (localstate.griditems[localstate.selectedindexes[k]].product.ProductType !== producttype) {
									//If even one sku has a different ProductType, don't allow component update.
									localstate.allowcomponentupdate = false;
								}
							}
						}
					}
				}
			}

			UpdateState(localstate);
			fnCallback(value);
		}
	}

	/* ##########################  Auto Complete  ########################## */
	// Similar to regular searchpairs, but loads results from DB - needs stateful.

	//Helpers - Blank icons for pre-set keys
	const BlankIcon = () => (<ArrowDropDownIcon style={{ display: "none" }} />);


	//Product: TO DO
	const [autocompletesearchtype1, setKeyProduct] = useState("Product");
	const [openoptions1, openOptions1] = React.useState(false);
	const [options1, setOptions1] = React.useState([]);
	const [loadingoptions1, setLoadingOptions1] = useState(false);
	const InitOptions1 = () => {
		if (options1.length === 0) {
			//Load (up to limit) options
			AutoCompleteSearch1("");
		}
		openOptions1(true);
	}

	const onChangeOption1 = (event, newvalue) => {
		localstate.searchoptions.searchpairs.searchpair7.type = autocompletesearchtype1;
		localstate.searchoptions.searchpairs.searchpair7.value = newvalue[autocompletesearchtype1];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch1 = debounce(function (searchvalue) {
		setLoadingOptions1(true);
		console.log(searchvalue);
		const postdata = {
			key: autocompletesearchtype1,
			search: searchvalue,
			limit: 20
		};
		axios.post(dbendpoint + "/skus/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setOptions1(res.data.items);
				}
			} else {
				//Non-200 message from server.
				//errors.NewError()
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingOptions1(false);
		});
	}, 600);

	/* ##########################  SKU List ########################## */
	/* Sync changes from this area to the Skus page */
	const [showskulist, setShowSkuList] = useState(false);
	const [openskulistoptions, openSkuSearch] = React.useState(false);
	const [options5, setOptions5] = React.useState([{ Name: "This" }]);
	const [loadingoptions5, setLoadingOptions5] = useState(false);
	//const [skulist, setSkuList] = useState("");
	const [savelistbool, setSaveListBool] = useState(false);
	const [showdeletebtn, showDeleteBtn] = useState(false);
	const [listname, setListName] = useState("");

	const InitSkuSearch = () => {
		if (options5.length === 0) {
			//Load (up to limit) options
			SkuListSearch("");
		}
		openSkuSearch(true);
	}
	const SelectSkuList = (newvalue) => {
		console.log("SelectSkuList: " + newvalue["Name"]);
		console.log("SelectSkuList: " + newvalue["List"]);
		//setSkuList(newvalue["List"]);
		localstate.searchoptions.skulist = newvalue["List"];
		setListName(newvalue["Name"]);
		setSaveListBool(true);
		showDeleteBtn(true);
	}


	///Changes to sku list text area
	const onChangeSkuList = (newvalue) => {
		//setSkuList(newvalue);
		localstate.searchoptions.skulist = newvalue;
		if (newvalue === "") {
			setSaveListBool(false);
		} else {
			setSaveListBool(true);
		}
	}

	//On selection, searchvalue will be "0"
	const SkuListSearch = debounce(function (searchvalue) {
		if (searchvalue === "" || searchvalue === 0) {
			searchvalue = "";
		} else {
			showDeleteBtn(false);
			setSaveListBool(true);
		}
		setLoadingOptions5(true);

		const postdata = {
			search: searchvalue
		};
		axios.post(dbendpoint + "/skulist/get", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setOptions5(res.data.items);
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingOptions5(false);
		});
	}, 600);

	const CloseSkuList = () => {
		setShowSkuList(false);
		//Reload DB?
		localstate.searchoptions.skulistname = listname;
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const SaveList = () => {
		const postdata = {
			listdata: {
				Name: listname,
				List: localstate.searchoptions.skulist
			}
		};
		axios.post(dbendpoint + "/skulist/update", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					console.log(res.data);
					showDeleteBtn(true);
					//errors.NewError({ errmsg: "List Saved!", errshow: true, errtimeout: 5, errtype: "ok" })
					dispatch(newErrorMessage({ errmsg: "List Saved!", errshow: true, errtimeout: 5, errtype: "ok" }));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingOptions5(false);
		});
	}

	const DeleteList = () => {
		const postdata = {
			Name: listname
		};
		axios.post(dbendpoint + "/skulist/delete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					console.log(res.data);
					showDeleteBtn(false);
					setListName("");
					//errors.NewError({ errmsg: "List Deleted!", errshow: true, errtimeout: 5, errtype: "ok" })
					dispatch(newErrorMessage({ errmsg: "List Deleted!", errshow: true, errtimeout: 5, errtype: "ok" }));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingOptions5(false);
		});
	}

	/* ##########################  Import Skus ########################## */
	const [showimportskus, setShowImportSkus] = useState(false);
	const [importbtnbool, setImportButtonBool] = useState(false);
	const [btnloadimported, setBtnLoadImported] = useState(false);

	var skuimporttxt = "";

	const onChangeSkuImportValues = (event) => {
		//Allow import btn
		setImportButtonBool(true);
		skuimporttxt = event.target.value;
	}

	const ImportSkus = () => {
		console.log(skuimporttxt);
		var importtxt = skuimporttxt;
		var skurows = importtxt.split("\n");

		//Setup array of sku objects similar to structure of DB
		var skus = [];
		for (var i = 0; i < skurows.length; i++) {
			//Setup array of values to be assigned to keys later on
			var skuvalues = skurows[i].split("\t");
			//Be sure we have the values required for import..
			if (skuvalues.length < 4) {
				//Not a valid row.
				break;
			} else {
				var skuobject = {
					Sku: skuvalues[0],
					Title: skuvalues[1],
					Gtin: skuvalues[2],
					ProductID: skuvalues[3]
				};
				skus.push(skuobject);
			}
		}
		console.log(skus);
		//Test of $http.post
		/*
		$http.post('v3/skus/create',{skus:skus}).success(function(data){
			console.log(data);
		});
		*/
		axios.post(dbendpoint + "/skus/importskus", { skus: skus }, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					// errors.NewError({
					// 	errmsg: "Skus Imported: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
					// 	errshow: true,
					// 	errtimeout: 5,
					// 	errtype: 'ok'
					// }
					// );
					dispatch(newErrorMessage({
						errmsg: "Skus Imported: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
						errshow: true,
						errtimeout: 5,
						errtype: 'ok'
					}));
					dispatch(setErrorTimeout(5));
					if (res.data['SuccessCount'] > 0) {
						setBtnLoadImported(true);
						localstate.searchoptions.skuscreated = res.data['SuccessfulSkus'];
						UpdateState(localstate);
					}
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingOptions5(false);
		});
	}

	const CloseImportsDrawer = () => {
		setShowImportSkus(false);
	}

	const LoadImportedSkus = () => {
		localstate.searchoptions.skulist = localstate.searchoptions.skuscreated;
		localstate.dbreload = true;
		UpdateState(localstate);
		setShowImportSkus(false);
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	let history = useHistory();

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		if (localstate.clearselection) {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection = true;
		}

		//Need to DEEP clone:
		var newsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));

		//Optional Search Handlers:

		//AUTOCOMPLETE AND SELECT Handler
		//Here, we may want to convert our SearchInput searchpairs of mode "autocomplete" to things
		//that our PHP controller SearchTools can use such as modes "like" or "strict".
		//Keep in mind, if two searchpairs have the same type and the mode is "like", it will normally morph
		//into an OR statement. There are only a few reserved non-OR types: Sku, Model, Name, NotSku, NotModel, SkuStarting
		//This list may be expanded or we may possibly write something to override non-OR types when needed.

		for (var i = 1; i < (activesearchescount + 1); i++) {
			//Products Autocomplete
			if (newsearchpairs["searchpair" + i].mode === "autocomplete" && newsearchpairs["searchpair" + i].type === "products") {
				console.log("^^^^^^^^^^^^^^^^^^^^^");
				newsearchpairs["searchpair" + i].type = "ProductID";
				newsearchpairs["searchpair" + i].mode = "like"; //Same-table search, try to get this working with regular ormode!
			}
			//.... More Rules
			//Some selects may be converted to "like"
		}


		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: newsearchpairs,
				nestedrelationships: localstate.searchoptions.nestedrelationships,
				integrationsloaded: localstate.integrationsloaded,
				skulist: localstate.searchoptions.skulist
			}
		};
		axios.post(dbendpoint + "/skus/get?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;


					//MenuItem Collection
					var menuitems = [];
					//Hold componentarray of unique componentID values
					var componentarray = [];

					//ResetArray is to allow AutoComplete Static to take in new values from LoadItems
					var resetarray = [];

					for (var i = 0; i < resultdata.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey = i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)) {
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Calc Cost
						var cost = 0;
						var ignorecomponent = 0;
						var pullrate = 0;
						var pushrate = 0;

						//Set base cost based on product:
						if (resultdata[i].product) {
							cost += parseFloat(resultdata[i].product.Cost);
							cost += parseFloat(resultdata[i].product.ShippingCost);
						}
						cost -= parseFloat(resultdata[i].Discount);

						if (resultdata[i].skucomponents.length > 0 && resultdata[i].product) {
							for (var j = 0; j < resultdata[i].skucomponents.length; j++) {
								//Configure using Product AND Sku Components
								if (resultdata[i].skucomponents.length > 0 && resultdata[i].product.productcomponents.length > 0) {

									ignorecomponent = 0;
									for (var n = 0; n < resultdata[i].product.productcomponents.length; n++) {
										if (resultdata[i].product.productcomponents[n].ComponentType === resultdata[i].skucomponents[j].ComponentType) {
											//console.log("Component is the same: "+resultdata[i].skucomponents[j].ComponentType);
											ignorecomponent = 1;
											//If they're the same component, do nothing
											if (resultdata[i].product.productcomponents[n].ComponentID === resultdata[i].skucomponents[j].ComponentID) {
												break;
											} else {
												//console.log("Pulling "+ resultdata[i].product.productcomponents[n].details.Name+" of "+resultdata[i].product.productcomponents[n].details.PullRate);
												//console.log("Pushing "+ resultdata[i].skucomponents[j].details.Name+" of "+resultdata[i].skucomponents[j].details.Cost);
												pullrate = parseFloat(resultdata[i].product.productcomponents[n].details.PullRate);
												pushrate = parseFloat(resultdata[i].skucomponents[j].details.Cost);
												cost += (pushrate - pullrate);
											}

										}
									}
									if (ignorecomponent === 0) {
										cost += parseFloat(resultdata[i].skucomponents[j].details.Cost);
									}

								} else {
									//If SKU components, but no product components exist, we still need to add SKU component costs
									//Now check for extra SKU components that would effect COGS
									if (resultdata[i].skucomponents.length > 0) {
										for (var k = 0; k < resultdata[i].skucomponents.length; k++) {
											cost += parseFloat(resultdata[i].skucomponents[k].details.Cost);
										}
									}
								}
							}


							//Collect Components for AutoComplete Static
							for (var k = 0; k < resultdata[i]['skucomponents'].length; k++) {
								if (!componentarray.includes(resultdata[i]['skucomponents'][k]['details']['ID'])) {
									//Add to array of unique component values:
									componentarray.push(resultdata[i]['skucomponents'][k]['details']['ID']);
									//Add to menu items:
									menuitems.push({
										Name: resultdata[i]['skucomponents'][k]['details']['ComponentType'] + ": " + resultdata[i]['skucomponents'][k]['details']['Name'],
										ID: resultdata[i]['skucomponents'][k]['details']['ID'],
										keyedby: "ID",
										ComponentID: resultdata[i]['skucomponents'][k]['details']['ID'],
										ComponentType: resultdata[i]['skucomponents'][k]['details']['ComponentType'],
										searchkey: "Name",
										key: uuidv4(),
										text: resultdata[i]['skucomponents'][k]['details']['Name'] + "this",
										usechip: true,
										chiplabel: resultdata[i]['skucomponents'][k]['details']['Name'],
										chipclass: classes.gradea,
										height: "22px",
										center: false,
										alt: resultdata[i]['skucomponents'][k]['details']['ComponentType']
									});
								}
							}
							//Organize menuitems:
							// Define the priority order for ComponentType
							const priorityTypes = ['CPU', 'RAM', 'Hard Drive', 'Optical', 'Resolution', 'Webcam', 'COA', 'Graphics', 'Wifi', 'Grade', 'Body Grade', 'LCD Grade'];

							menuitems.sort((a, b) => {
								// Determine priority of ComponentType
								const aPriority = priorityTypes.indexOf(a.ComponentType);
								const bPriority = priorityTypes.indexOf(b.ComponentType);

								// Prioritize items based on the defined priorityTypes
								if (aPriority !== -1 && bPriority === -1) return -1; // a is in priorityTypes, b is not
								if (bPriority !== -1 && aPriority === -1) return 1;  // b is in priorityTypes, a is not
								if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority; // Both in priorityTypes, sort by priority

								// If neither is in priorityTypes, sort by ComponentType alphabetically
								if (a.ComponentType < b.ComponentType) return -1;
								if (a.ComponentType > b.ComponentType) return 1;

								// If ComponentType is the same, sort alphabetically by Name
								if (a.Name < b.Name) return -1;
								if (a.Name > b.Name) return 1;

								return 0;
							});
						}
						resultdata[i].CalcCost = cost.toFixed(2);
						resultdata[i].Cost = parseFloat(resultdata[i].Cost).toFixed(2);

						// Sort priority order by ComponentType
						// Any new additions to this priority, check SkuIntegrationsTable, SkusTable, OrdersView, ProductsTable....
						const priorityTypes = ['CPU', 'RAM', 'Hard Drive', 'Optical', 'Resolution', 'Webcam', 'COA', 'Graphics', 'Grade', 'Body Grade', 'LCD Grade'];
						if (typeof resultdata[i].skucomponents !== undefined){
							resultdata[i].skucomponents.sort((a, b) => { 
								// Determine priority of ComponentType
								const aPriority = priorityTypes.indexOf(a.ComponentType);
								const bPriority = priorityTypes.indexOf(b.ComponentType);
	
								// Prioritize items based on the defined priorityTypes
								if (aPriority !== -1 && bPriority === -1) return -1; // a is in priorityTypes, b is not
								if (bPriority !== -1 && aPriority === -1) return 1;  // b is in priorityTypes, a is not
								if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority; // Both in priorityTypes, sort by priority
	
								// If neither is in priorityTypes, sort by ComponentType alphabetically
								if (a.ComponentType < b.ComponentType) return -1;
								if (a.ComponentType > b.ComponentType) return 1;
	
								// If ComponentType is the same, sort alphabetically by Name
								if (a.Name < b.Name) return -1;
								if (a.Name > b.Name) return 1;
	
								return 0;
							});
						}

						if (typeof resultdata[i].product !== undefined && resultdata[i].product !== null){
							console.log(typeof resultdata[i].product);
							if (typeof resultdata[i].product.productcomponents !== null){ 
								resultdata[i].product.productcomponents.sort((a, b) => { 
									// Determine priority of ComponentType
									const aPriority = priorityTypes.indexOf(a.ComponentType);
									const bPriority = priorityTypes.indexOf(b.ComponentType);
		
									// Prioritize items based on the defined priorityTypes
									if (aPriority !== -1 && bPriority === -1) return -1; // a is in priorityTypes, b is not
									if (bPriority !== -1 && aPriority === -1) return 1;  // b is in priorityTypes, a is not
									if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority; // Both in priorityTypes, sort by priority
		
									// If neither is in priorityTypes, sort by ComponentType alphabetically
									if (a.ComponentType < b.ComponentType) return -1;
									if (a.ComponentType > b.ComponentType) return 1;
		
									// If ComponentType is the same, sort alphabetically by Name
									if (a.Name < b.Name) return -1;
									if (a.Name > b.Name) return 1;
		
									return 0;
								});
							}
						}
					}


					for (var m=1; m<(activesearchescount+1); m++){
						//Components Autocomplete Static
						console.log(newsearchpairs["searchpair"+m]);
						if (newsearchpairs["searchpair"+m].mode==="autocompletestatic" && newsearchpairs["searchpair"+m].type==="components"){
							resetarray.push(m);
							localstate.searchoptions.searchpairs["searchpair"+m].menuitems=menuitems;
						}
					}
					//End of AutoComplete Static Code


					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					if (res.data.integrations) {
						//alert("Integrations found!");
						localstate.integrationsloaded = true;
						localstate.integrations = res.data.integrations;
					}
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
					//Ensures the inputs are rerendered and will work correctly:
					ResetKeys(resetarray, false); 
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight - 2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/

	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Skus";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	useEffect(() => {
		document.title = "Skus";
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	});



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			ID: uuidv4(),
			PendingItem: true,
			Sku: "",
			Title: "",
			Gtin: "",
			ProductID: "",
			Location: "",
			Discount: 0,
			GlobalTGI: 100,
			skuintegrations: [],
			updated_at: ""
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}


	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);

		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving skus...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors: [] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				//Do work here, API call.
				const postdata = {
					item: item,
					savecosts: localstate.savecosts
				};
				axios.post(dbendpoint + "/skus/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!

							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});

				//If we have completed all items, clear this interval and update state.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			//errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" });
			dispatch(newErrorMessage({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" }));
			dispatch(setErrorTimeout(5));
			ResetPendingSaves();
		}
	}

	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i - 1) === limit) {
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display = "";
			btnPendingSave.current.style.display = "none";
		}
	}


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				rowRefs.current[i + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i + 'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i + 'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display = "";
		btnPendingSave.current.style.display = "none";
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */ }
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () => {
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Clear any reason for addcomponentmode
		localstate.addcomponentmode = false;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);

	const [changeisaddcomponent, setChangeIsAddComponent] = React.useState(false);
	const [changeisremovecomponent, setChangeIsRemoveComponent] = React.useState(false);
	const [componentselection, setComponentSelection] = React.useState(false);


	const [changeisproduct, setChangeIsProduct] = React.useState(false);
	const [changeiscreatecopy, setChangeIsCreateCopy] = React.useState(false);
	const [productselection, setProductSelection] = React.useState();




	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current = "";
		//Reset Inputs:
		setChangeIsProduct(false);

		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);
		setChangeIsCreateCopy(false);

		//Examples

		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);
		setChangeIsAddComponent(false);
		setChangeIsRemoveComponent(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value === "Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value === "Cost" || event.target.value === "Price" || event.target.value === "GlobalTGI") {
			setChangeIsFloat(true);
		}

		if (event.target.value === "SomeSelectable") {
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value === "Configured") {
			setChangeIsBool(true);
		}

		if (event.target.value === "Product") {
			setChangeIsDBItem(true);
		}

		if (event.target.value === "Date") {
			setChangeIsDate(true);
		}



		if (event.target.value === "Add Component") {
			setChangeIsAddComponent(true);
		}

		if (event.target.value === "Remove Component") {
			setChangeIsRemoveComponent(true);
		}

		if (event.target.value === 'Product') {
			setChangeIsProduct(true);
		}

		if (event.target.value === 'Create Copy') {
			setChangeIsCreateCopy(true);
			setChangeIsText(true);
			changevalue.current = "-COPY"; //This does not set. Probably because the element holding this value isn't rendered yet?
			console.log(changevalue.current);
			console.log("&&&& setting changevalue to copy");
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	};

	const handleChangeBulkValue = (event) => {
		//Currency
		//Change this to RestrictInputNumber(float)
		if (changetype === "Cost" || changetype === "Price" || changetype === "Discount" || changetype === "GlobalTGI") {
			var newvalue = event.target.value;
			var itemkey = changetype;

			var	oldvalue="0";
			
			//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
			newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
			if (newvalue) {
				changevaluefloat.current.value = newvalue;
				changevalue.current = newvalue;
				//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
				const inputElement = changevaluefloat;
				inputElement.type = 'text';
				inputElement.focus();
				inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
				inputElement.type = 'number';
			} else {
				console.log("No newvalue returned, set value in localstate, but leave input untouched.");
				console.log(parseFloat(event.target.value).toFixed(2));
				changevalue.current = parseFloat(event.target.value).toFixed(2);
			}
		}

		if (changetype === "Create Copy"){
			changevalue.current = event.target.value;
		}

		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype === "Location" && event.target.value === "") {
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			//errors.NewError({ errmsg: "Value cannot be blank.", errshow: true, errtimeout: 3, errtype: "neutral" });
			dispatch(newErrorMessage({ errmsg: "Value cannot be blank.", errshow: true, errtimeout: 3, errtype: "neutral" }));
			dispatch(setErrorTimeout(5));

			//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeProduct = (event, product) => {
		setProductSelection(product);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
		btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);
	}

	const handleChangeComponent = (event, component) => {
		setComponentSelection(component);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
		btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);
	}


	//Search for Product\Component (autocomplete):
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions) {
			if (dbitemoptions.length === 0) {
				//Load (up to limit) options
				DBItemSearch("");
			}
			openDBItemOptions(true);
		}
	}


	const DBItemSearch = debounce(function (searchvalue) {
		setLoadingDBItemOptions(true);
		const postdata = {
			key: dbitemkey,
			search: searchvalue,
			limit: 20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype === "Product") {
			table = "products";
		}
		if (changetype === "Add Component" || changetype === "Remove Component") {
			table = "components";
			postdata.producttype = localstate.newcomponenttype;
		}

		axios.post(dbendpoint + "/" + table + "/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					//console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
			setLoadingDBItemOptions(false);
		});
	}, 600);





	const ApplyBulkEdit = () => {
		var items = [];
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype === "Price" ||
			changetype === "Cost" ||
			changetype === "GlobalTGI" ||
			changetype === "Discount" ||
			changetype === "Configured"
		) {
			for (i = 0; i < items.length; i++) {
				items[i][changetype] = changevalue.current;
			}
		}

		if (changetype === "Product") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["ProductID"] = productselection.ProductID;
			}
		}

		//Create fake key for AddComponent - Bulk edit has method to update these.
		if (changetype === "Add Component") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["AddComponentID"] = componentselection.ID;
			}
		}

		//Create fake key for RemoveComponent - Bulk edit has method to update these.
		if (changetype === "Remove Component") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["RemoveComponentID"] = componentselection.ID;
			}
		}

		//Flag for pushing GlobalTGI updates to the child sku integrations
		if (changetype === "Remove Component") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["RemoveComponentID"] = componentselection.ID;
			}
		}

		//Create fake key for CreateCopy - Bulk edit has method to update these.
		if (changetype === "Create Copy") {
			//For some reason changevalue.current doesn't seems to stick when we first initialize. Not sure why.
			if (changevalue.current===null){
				changevalue.current="-COPY";
			}
			for (i = 0; i < items.length; i++) {
				items[i]["CreateCopy"] = 1;
				items[i]["Appendage"] = changevalue.current;
			}
		}


		var postdata = {
			items: items
		}


		axios.post(dbendpoint + "/skus/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					//console.log(res.data);
					if (res.data['SuccessCount'] > 0) {
						// errors.NewError({
						// 	errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
						// 	errshow:true,
						// 	errtimeout: 5,
						// 	errtype:'ok'})
						// }
						dispatch(newErrorMessage({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 5,
							errtype: 'ok'
						}));
						dispatch(setErrorTimeout(5));
					} else if (res.data['FailCount'] > 0){
						dispatch(newErrorMessage({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 5,
							errtype: 'warning'
						}));
					}
				}

				if (res.data.Status === 'Failure') {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' }));
					dispatch(setErrorTimeout(5));
				}

			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' }));
				dispatch(setErrorTimeout(5));
			}
		});
	}


	//Add Component
	const AddComponent = (index) => {
		//Deselect all, select only current row.
		localstate.selectedindexes = [];
		localstate.selectedcount = 0;
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].isSelected = false;
			localstate.griditems[i].GridKey++;
		}
		localstate.selectedindexes.push(index);
		localstate.addcomponentmode = true;

		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);
		setChangeIsCreateCopy(false);
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);
		setChangeIsAddComponent(true);
		setChangeType("Add Component");
		UpdateState(localstate);
		//Checks for unsave items, mismatch producttypes, and sets producttype to allow or disallow component changes.
		RejectIfInvalidSelected(true, setShowBulkEditDrawer);
	}


	//Remove Component
	const RemoveComponent = (rowindex, id) => {
		axios.post(dbendpoint + "/skus/removeskucomponent", { id: id }, defaultpostoptions).then(res => {
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					// errors.NewError({
					// 	errmsg:"Component removed!",
					// 	errshow:true,
					// 	errtimeout: 5,
					// 	errtype:'ok'})
					// }
					dispatch(newErrorMessage({
						errmsg: "Component removed!",
						errshow: true,
						errtimeout: 5,
						errtype: 'ok'
					}));
					dispatch(setErrorTimeout(5));
					//To Do: Remove Component from view:
					var componentindex = localstate.griditems[rowindex].skucomponents.map(function (o) { return o.ID; }).indexOf(id);
					localstate.griditems[rowindex].skucomponents.splice(componentindex, 1);
					UpdateState(localstate);
				}
				if (res.data.Status === 'Failure') {
					//Failure error
					//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: 'neutral' })
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: 'neutral' }));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 10, errtype: 'warning' })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 10, errtype: 'warning' }));
				dispatch(setErrorTimeout(5));
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/skus/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
								dispatch(newErrorMessage({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" }));
								dispatch(setErrorTimeout(5));
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
							dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
							dispatch(setErrorTimeout(5));
						}
					} else {
						//Non-200 message from server.
						//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
						dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
						dispatch(setErrorTimeout(5));
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Sku", "Title", "Gtin", "ProductID", "Location", "Discount", "GlobalTGI"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].select();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].select();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].select();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].select();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		console.log("Restrict Number:" + newvalue);
		//45.
		var len = newvalue.length;
		//len=3
		var index = newvalue.indexOf('.');
		//index=2
		if (index > 0) {
			//true
			var decimalchars = (len - 1) - index;
			//decimalchars = 3-1  - 2 = 0
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}


	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			//Floats
			if (column === "Discount" || column === "Cost" || column==="GlobalTGI") {
				if (!oldvalue){
					console.log("No old value.");
					oldvalue="0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					rowRefs.current[index+column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = rowRefs.current[index+column];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';

				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseFloat(event.target.value).toFixed(2));
					localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
				}



				//CalcCost
				var cost = 0;
				var ignorecomponent = 0;
				var pullrate = 0;
				var pushrate = 0;

				if (localstate.griditems[index].product === null) {
					//errors.NewError({ errmsg: "No product assigned. Cannot determine actual cost.", errshow: true, errtimeout: 5, errtype: "warning" })
					dispatch(newErrorMessage({ errmsg: "No product assigned. Cannot determine actual cost.", errshow: true, errtimeout: 5, errtype: "warning" }));
					dispatch(setErrorTimeout(5));
				} else {
					cost += parseFloat(localstate.griditems[index].product.Cost);
					cost += parseFloat(localstate.griditems[index].product.ShippingCost);
					cost -= parseFloat(localstate.griditems[index].Discount);

					if (localstate.griditems[index].skucomponents.length > 0 && localstate.griditems[index].product) {
						for (var j = 0; j < localstate.griditems[index].skucomponents.length; j++) {
							//Configure using Product AND Sku Components
							if (localstate.griditems[index].skucomponents.length > 0 && localstate.griditems[index].product.productcomponents.length > 0) {

								ignorecomponent = 0;
								for (var n = 0; n < localstate.griditems[index].product.productcomponents.length; n++) {
									if (localstate.griditems[index].product.productcomponents[n].ComponentType === localstate.griditems[index].skucomponents[j].ComponentType) {
										//console.log("Component is the same: "+localstate.griditems[index].skucomponents[j].ComponentType);
										ignorecomponent = 1;
										//If they're the same component, do nothing
										if (localstate.griditems[index].product.productcomponents[n].ComponentID === localstate.griditems[index].skucomponents[j].ComponentID) {
											break;
										} else {
											//console.log("Pulling "+ localstate.griditems[index].product.productcomponents[n].details.Name+" of "+localstate.griditems[index].product.productcomponents[n].details.PullRate);
											//console.log("Pushing "+ localstate.griditems[index].skucomponents[j].details.Name+" of "+localstate.griditems[index].skucomponents[j].details.Cost);
											pullrate = parseFloat(localstate.griditems[index].product.productcomponents[n].details.PullRate);
											pushrate = parseFloat(localstate.griditems[index].skucomponents[j].details.Cost);
											cost += (pushrate - pullrate);
										}

									}
								}
								if (ignorecomponent === 0) {
									cost += parseFloat(localstate.griditems[index].skucomponents[j].details.Cost);
								}

							} else {
								//If SKU components, but no product components exist, we still need to add SKU component costs
								//Now check for extra SKU components that would effect COGS
								if (localstate.griditems[index].skucomponents.length > 0) {
									for (var k = 0; k < localstate.griditems[index].skucomponents.length; k++) {
										cost += parseFloat(localstate.griditems[index].skucomponents[k].details.Cost);
									}
								}
							}
						}
					}
					localstate.griditems[index].CalcCost = cost.toFixed(2);
					rowRefs.current[index + "CalcCost"].textContent = cost.toFixed(2);

					console.log("New Cost: " + cost);
					//localstate.griditems[index].Cost = parseFloat(localstate.griditems[index].Cost).toFixed(2); 
				}




				// if (!oldvalue) {
				// 	oldvalue = "0";
				// }
				// newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				// //If new value...
				// if (newvalue) {
				// 	//Backspace on a decimal should yield same value for old and new
				// 	//console.log("Oldvalue has decimal:"+oldvalue.indexOf(".")+"   Newvalue has decimal:"+newvalue.indexOf(".")+"   Event key:"+event.key);
				// 	if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1 && event.key === "Backspace") {
				// 		//console.log("Scenario1");
				// 		//No need to change front end, simply update griditem
				// 		localstate.griditems[index][column] = newvalue;
				// 	} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
				// 		rowRefs.current[index + column].value = newvalue;
				// 		localstate.griditems[index][column] = newvalue;


				// 		//Maybe handle changes here?
				// 		//CalcCost
				// 		var cost = 0;
				// 		var ignorecomponent = 0;
				// 		var pullrate = 0;
				// 		var pushrate = 0;

				// 		if (localstate.griditems[index].product === null) {
				// 			//errors.NewError({ errmsg: "No product assigned. Cannot determine actual cost.", errshow: true, errtimeout: 5, errtype: "warning" })
				// 			dispatch(newErrorMessage({ errmsg: "No product assigned. Cannot determine actual cost.", errshow: true, errtimeout: 5, errtype: "warning" }));
				// 			dispatch(setErrorTimeout(5));
				// 		} else {
				// 			cost += parseFloat(localstate.griditems[index].product.Cost);
				// 			cost += parseFloat(localstate.griditems[index].product.ShippingCost);
				// 			cost -= parseFloat(localstate.griditems[index].Discount);

				// 			if (localstate.griditems[index].skucomponents.length > 0 && localstate.griditems[index].product) {
				// 				for (var j = 0; j < localstate.griditems[index].skucomponents.length; j++) {
				// 					//Configure using Product AND Sku Components
				// 					if (localstate.griditems[index].skucomponents.length > 0 && localstate.griditems[index].product.productcomponents.length > 0) {

				// 						ignorecomponent = 0;
				// 						for (var n = 0; n < localstate.griditems[index].product.productcomponents.length; n++) {
				// 							if (localstate.griditems[index].product.productcomponents[n].ComponentType === localstate.griditems[index].skucomponents[j].ComponentType) {
				// 								//console.log("Component is the same: "+localstate.griditems[index].skucomponents[j].ComponentType);
				// 								ignorecomponent = 1;
				// 								//If they're the same component, do nothing
				// 								if (localstate.griditems[index].product.productcomponents[n].ComponentID === localstate.griditems[index].skucomponents[j].ComponentID) {
				// 									break;
				// 								} else {
				// 									//console.log("Pulling "+ localstate.griditems[index].product.productcomponents[n].details.Name+" of "+localstate.griditems[index].product.productcomponents[n].details.PullRate);
				// 									//console.log("Pushing "+ localstate.griditems[index].skucomponents[j].details.Name+" of "+localstate.griditems[index].skucomponents[j].details.Cost);
				// 									pullrate = parseFloat(localstate.griditems[index].product.productcomponents[n].details.PullRate);
				// 									pushrate = parseFloat(localstate.griditems[index].skucomponents[j].details.Cost);
				// 									cost += (pushrate - pullrate);
				// 								}

				// 							}
				// 						}
				// 						if (ignorecomponent === 0) {
				// 							cost += parseFloat(localstate.griditems[index].skucomponents[j].details.Cost);
				// 						}

				// 					} else {
				// 						//If SKU components, but no product components exist, we still need to add SKU component costs
				// 						//Now check for extra SKU components that would effect COGS
				// 						if (localstate.griditems[index].skucomponents.length > 0) {
				// 							for (var k = 0; k < localstate.griditems[index].skucomponents.length; k++) {
				// 								cost += parseFloat(localstate.griditems[index].skucomponents[k].details.Cost);
				// 							}
				// 						}
				// 					}
				// 				}
				// 			}
				// 			localstate.griditems[index].CalcCost = cost.toFixed(2);
				// 			rowRefs.current[index + "CalcCost"].textContent = cost.toFixed(2);

				// 			console.log("New Cost: " + cost);
				// 			//localstate.griditems[index].Cost = parseFloat(localstate.griditems[index].Cost).toFixed(2); 
				// 		}
				// 	}
				// } else {
				// 	//Handle possible backspace.
				// 	//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
				// 	if (event.key === "Backspace") {
				// 		if ((oldvalue.indexOf(".") > -1)) {
				// 			localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
				// 		}
				// 	}
				// }
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} 
			//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
			if (column === "NewCheckbox") { //MaterialUI Checkbox
				if (event.target.checked) {
					localstate.griditems[index][column] = 1;
				} else {
					localstate.griditems[index][column] = 0;
				}
			}
			//All others catch all
			if (column === "SomeSelectable" || column==="Title" ||column==="Sku" || column==="Location") {
				//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
				localstate.griditems[index][column] = newvalue;
			}
				
			
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
		}
	}


	/* ##########################  Button Functions  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = !newcolstate[key];
		//Provision for Components Column State:
		if (colstate["SkuComponents"] || colstate["ProductComponents"]) {
			colstate["Components"] = true;
		} else {
			colstate["Components"] = false;
		}
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
			</React.Fragment>
		)
	}
	const SetCustomView1 = () => {
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['Name'] = true;
		newcolstate['Cost'] = true;
		newcolstate['Margin'] = true;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => { setExportMenu(event.currentTarget); }
	const CloseExportMenu = () => { setExportMenu(null); }

	/* View Options Menu */
	const [showViewOptionsMenu, setViewOptionsMenu] = useState(null);
	const ShowViewOptionsMenu = (event) => {
		setViewOptionsMenu(event.currentTarget);
	}
	const CloseViewOptionsMenu = () => {
		setViewOptionsMenu(null);
	}

	//Market Integrations Menu
	const [integrationsmenu, setIntegrationsMenu] = useState(null);
	const openIntegrations = (event) => {
		setIntegrationsMenu(event.currentTarget);
	}
	const closeIntegrations = () => {
		setIntegrationsMenu(null);
	}

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}


	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	const BulkUpdate = () => {
		//Explanation:
		//Currently checkboxes are uncontrolled, they simply add the indexes to the localstate.selectedindexes, uncommitted to state.
		//This avoids an expensive rerender for large grids.
		var i;
		var random;
		for (i = 0; i < localstate.selectedindexes.length; i++) {
			console.log(localstate.griditems[localstate.selectedindexes[i]].Name);
			random = getRandomInt(3);
			//Update view to reflect pending changes
			rowRefs.current[localstate.selectedindexes[i] + "Cost"].value = random;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add("unsavedhighlight");
			//Update localstate
			localstate.griditems[localstate.selectedindexes[i]]["Cost"] = random;
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
		}
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
	}

	const AddIntegration = (integration) => {
		closeIntegrations();
		//Get Selected IDs
		console.log(localstate.selectedindexes);
		//Use Selected IDs to get Skus
		var skus = [];
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			skus.push(localstate.griditems[localstate.selectedindexes[i]]);
		}
		//Send SKUs & Integration
		var postdata = {
			integrationid: integration.ID,
			skus: skus
		}
		axios.post(dbendpoint + "/skus/assignintegrations", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back "login" error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					//errors.NewError({errmsg:"Skus added to "+res.data.integration.Name, errshow:true, errtimeout: 8, errtype:"ok"});
					dispatch(newErrorMessage({ errmsg: "Skus added to " + res.data.integration.Name, errshow: true, errtimeout: 8, errtype: "ok" }));
					dispatch(setErrorTimeout(5));
					localstate.dbreload = true;
					localstate.clearselection = false;
					UpdateState(localstate);
				} else {
					//errors.NewError({ errmsg: "Could not assign one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
					dispatch(newErrorMessage({ errmsg: "Could not assign one or more items.", errshow: true, errtimeout: 8, errtype: "warning" }));
					dispatch(setErrorTimeout(5));
				}

				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(5));
			}
		});

	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "SkusCSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint + "?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
						dispatch(setErrorTimeout(5));
					}
				} else {
					//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
					dispatch(setErrorTimeout(5));
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}

	const CopyCosts = () => {
		//Has Similar functions for showing unsaved items, etc.
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].Cost !== localstate.griditems[i].CalcCost) {
				rowRefs.current[i + "Cost"].value = localstate.griditems[i].Cost = localstate.griditems[i].CalcCost;
				rowRefs.current[i + "SaveStatus"].classList.add(classes.unsavedhighlight);
				localstate.griditems[i].unsaved = true;
			}
		}
		localstate.pendingsaves = true;
		//Provision to forward costs to Sku Integrations
		localstate.savecosts = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
	}


	const CreateList = () => {
		var newskulist = "";
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			newskulist += localstate.griditems[localstate.selectedindexes[i]].Sku + "\n";
		}
		//setSkuList(newskulist);
		localstate.searchoptions.skulist = newskulist;
		setShowSkuList(!showskulist)
	}



	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Sku", numeric: false, label: "Sku", align: "left", allowsort: true, style: { minWidth: "220px" } },
		{ id: "Title", numeric: true, label: "Title", align: "left", allowsort: true, style: {} },
		{ id: "Gtin", numeric: true, label: "Gtin", align: "left", allowsort: false, style: {} },
		{ id: "Product", numeric: true, label: "Product", align: "left", allowsort: false, style: {} },
		{ id: "ProductID", numeric: true, label: "ProductID", align: "left", allowsort: true, style: { width: "40px" } },
		{ id: "Location", numeric: false, label: "Location", align: "left", allowsort: true, style: { width: "40px" } },
		{ id: "Components", numeric: false, label: "Components", align: "left", allowsort: false, style: {} },
		//Notice no entry for SkuComponents or ProductComponents. They potentially go into this column together
		{ id: "Discount", numeric: false, label: "Discount", align: "right", allowsort: true, style: {} },
		{ id: "CalcCost", numeric: false, label: "CalcCost", align: "right", allowsort: false, style: { width: "95px" } },
		{ id: "Cost", numeric: false, label: "Cost", align: "right", allowsort: true, style: { width: "70px" } },
		{ id: "GlobalTGI", numeric: false, label: "GlobalTGI", align: "right", allowsort: true, style: { width: "70px" } },
		{ id: "Integrations", numeric: false, label: "Integrations", align: "left", allowsort: true, style: {} },
		{ id: "updated_at", numeric: false, label: "Updated", align: "left", allowsort: true, style: {} },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Sku") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
								{(headCell.id === "CalcCost") &&
									<IconButton className={classes.transparenticon} size="medium" onClick={() => CopyCosts()}>
										<ArrowForwardIcon color="primary" fontSize="default" style={{ paddingLeft: "5px" }}></ArrowForwardIcon>
									</IconButton>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */

	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div style={{ padding: "8px", overflow: "auto", minWidth: "400px" }}>

				{/* Sku List Drawer*/}
				<Drawer open={showskulist} style={{ width: "600px" }}>
					<Typography variant="h4" gutterBottom align="center">
						Edit Sku List
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							You may load, create, or delete SKU lists here.<br></br>
						</Typography>

						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						<div className={classes.flexautocomplete} style={{ width: "380px" }}>
							<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "80px" }}>
								{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
								<Select
									value={"List"} disableUnderline
									disabled classes={{ disabled: classes.undisabled }}
									IconComponent={BlankIcon}
								>
									<MenuItem value={"List"}>List</MenuItem>
								</Select>
							</FormControl>

							<Autocomplete forcePopupIcon={false} disableClearable style={{ width: "100%" }}
								open={openskulistoptions} onOpen={() => { InitSkuSearch(); }} onClose={() => { openSkuSearch(false); }}
								//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
								onChange={(event, newValue) => SelectSkuList(newValue)}
								onInputChange={(event, value, reason) => {
									//We avoid a reset of the AutoComplete by only passing the listname value when reason===input
									if (reason === 'input') {
										setListName(event.target.value);
										SkuListSearch(event.target.value);
									}
								}
								}
								isOptionEqualToValue={(option, value) => option["Name"] === value}
								getOptionLabel={
									(option) => {
										if (option) {
											return option["Name"];
										} else {
											return "";
										}
									}
								}
								options={options5}
								loading={loadingoptions5}
								defaultValue={localstate.searchoptions.skulistname}
								inputValue={listname}
								renderInput={(params) => (
									<TextField variant="standard"
										className={classes.autocompleteinput}
										{...params}
										InputProps={{
											disableUnderline: true,
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loadingoptions5 ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</div>
						{(savelistbool) &&
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => SaveList()}>
								Save List
							</Button>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseSkuList()}>
							Close
						</Button>

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste SKUs from spreadsheet here."
							onChange={(event) => onChangeSkuList(event.target.value)}
							defaultValue={localstate.searchoptions.skulist}
						/>
						{(showdeletebtn) &&
							<React.Fragment>
								Warning: Deleting a list cannot be undone!<br></br>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => DeleteList()}>
									Delete List
								</Button>
							</React.Fragment>
						}
					</div>
				</Drawer>



				{/* Bulk Edit Drawer */}
				<Drawer open={showbulkeditdrawer} style={{ width: "400px" }}>
					<div style={{ width: "400px", padding: "50px 10px 10px 10px" }}>
						{(!localstate.addcomponentmode) &&
							<div>
								<Typography variant="h4" gutterBottom align="center">
									Edit Selected Items
								</Typography>
								<Typography variant="subtitle1" gutterBottom>
									Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
								</Typography>

								{(changetype === "Create Copy") &&
									<Typography variant="subtitle1" gutterBottom>
										When copying a Sku, you may set the desired appendage. Please also note that the GTIN will not be copied and may need to be assigned later.
									</Typography>
								}
								{(changetype === "Discount") &&
									<Typography variant="subtitle1" gutterBottom>
										Discount is expressed as a positive decimal such as 5.25 for ease of use as opposed to constantly having to type in a negative symbol here and in the grid view.
									</Typography>
								}
							</div>
						}

						{(localstate.addcomponentmode) &&
							<div>
								<Typography variant="h4" gutterBottom align="center">
									Add Component
								</Typography>
							</div>
						}


						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{ height: "35px", textAlign:"center" }}>
							<NewErrorMessage />
						</div>


						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeEditType}
							>
								<MenuItem value={'Product'}>Product</MenuItem>
								<MenuItem value={'GlobalTGI'}>Global TGI</MenuItem>
								<MenuItem value={'Discount'}>Discount</MenuItem>
								<MenuItem value={'Configured'}>Set Configured</MenuItem>
								{(localstate.allowcomponentupdate) &&
									<MenuItem value={'Add Component'}>Add Component</MenuItem>
								}
								{(localstate.allowcomponentupdate) &&
									<MenuItem value={'Remove Component'}>Remove Component</MenuItem>
								}
								{(userPerms.createSku === 1) &&
									<MenuItem value={'Create Copy'}>Copy Sku</MenuItem>
								}
							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
								<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue={(changeiscreatecopy ? "-COPY" : "")} onChange={handleChangeBulkValue} />
							</div>
						}

						{(changeisstatus) &&
							<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
								<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									onChange={handleChangeBulkValue}
								>
									<MenuItem value={"Checked In"}>Checked In</MenuItem>
									<MenuItem value={"Sold"}>Sold</MenuItem>
									<MenuItem value={"Scrap"}>Scrap</MenuItem>
									<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
									<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
								</Select>
							</FormControl>
						}

						{/* Float: Cost */}
						{/* Convert to GlobalTGI */}
						{(changeisfloat && (changetype !== "Discount")) &&
							<div>
								<TextField variant="standard" type="number" step="0.01" inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} required id="standard-required" defaultValue="Value" onChange={handleChangeBulkValue} />
							</div>
						}

						{/* Float: Cost */}
						{/* Convert to GlobalTGI */}
						{(changetype === "Discount") &&
							<div>
								<TextField variant="standard" type="number" step="0.01"
									className={classes.flexnegativeinput}
									inputRef={el => changevaluefloat.current = el}
									style={{ width: "300px" }}
									inputProps={{ min: 0, style: { textAlign: 'right' } }}
									required id="standard-required"
									defaultValue="Value"
									onChange={handleChangeBulkValue} />
							</div>
						}

						{/* EXAMPLE: Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"
									inputRef={el => changevalue.current = el}
									style={{ width: "300px" }}
									required
									id="standard-required"
									defaultValue=""
									onChange={handleChangeBulkValue} />
							</div>
						}


						{/* Change is Some Selectable SomeSelectable */}
						{(changeissomeselectable) &&
							<div>
								<FormControl variant="standard" className={classes.formControl}>
									{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
									{/* Need: Set default value if row.SomeSelectable is null. */}
									{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

									{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
									{/* Override select prop class with new padding specs */}

									<Select
										defaultValue={""}
										onChange={(event) => handleChangeSomeSelectable(event)}
										classes={{
											//icon: classes.nodisplay,
											//select: classes.selectpadding
										}}
										style={{ width: "300px" }}
									>
										<MenuItem value="">
											<em>None (blank string)</em>
										</MenuItem>
										<MenuItem value={10}>Ten (integer)</MenuItem>
										<MenuItem value={"A"}>
											<Chip size="small" label="A" clickable className={classes.gradea} />
										</MenuItem>
										<MenuItem value={"B"}>
											<Chip size="small" label="B" clickable className={classes.gradeb} />
										</MenuItem>
										<MenuItem value={"C"}>
											<Chip size="small" label="C" clickable className={classes.gradec} />
										</MenuItem>
										<MenuItem value={"Bad"}>
											<Chip size="small" label="Bad" clickable className={classes.gradebad} />
										</MenuItem>
										<MenuItem value={"Repair"}>
											<Chip size="small" label="Repair" clickable className={classes.graderepair} />
										</MenuItem>
										<MenuItem value={"Scrap"}>
											<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						}


						{/* Product (Autocomplete) */}
						{(changeisproduct) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding: "0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions(); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeProduct(event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{ padding: "0px" }}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}


						{/* Add\Remove Component (Autocomplete) */}
						{(changeisaddcomponent || changeisremovecomponent) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding: "0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions(); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeComponent(event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.ID} style={{ width: "300px" }}>
												<span style={{ display: "inline-block" }}><b>{option.ComponentType}</b> &rarr; {option.Name}</span>
											</li>
										);
									}}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{ padding: "0px" }}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}


						{/* Date */}
						{(changeisdate) &&
							<FormControl className={classes.formControl} style={{ width: "300px" }}>
								<TextField variant="standard"
									id="datetime-local"
									type="datetime-local"
									defaultValue=""
									onChange={handleChangeBulkValue}
									sx={{ width: 300 }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</FormControl>
						}


						{/* Change Is Bool */}
						{(changeisbool) &&
							<div style={{ marginTop: "20px" }}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">Set Configured Status</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="0"
										name="radio-buttons-group"
										onChange={handleChangeBulkValue}
									>
										<FormControlLabel value="0" control={<Radio />} label="No" />
										<FormControlLabel value="1" control={<Radio />} label="Yes" />
									</RadioGroup>
								</FormControl>
							</div>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => ApplyBulkEdit()}
							ref={el => btnApplyBulkEdit.current = el}>
							Apply Change
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseBulkEditDrawer()}>
							Close
						</Button>

					</div>
				</Drawer>



				{/* Sku Import Drawer*/}
				<Drawer open={showimportskus} style={{ width: "900px" }}>
					<div style={{ width: "900px", padding: "60px 10px 10px 10px" }}>
						<Typography variant="h4" gutterBottom align="center">
							Import SKUs
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							Format your data within excel to have the following items in order within each row:
							SKU, Title, GTIN, Product ID<br></br>
							Then proceed to paste them into the box below without headers.
						</Typography>

						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						{(importbtnbool) &&
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => ImportSkus()}>
								Import SKUs
							</Button>
						}

						{(btnloadimported) &&
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => LoadImportedSkus()}>
								Load Imported Skus
							</Button>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseImportsDrawer()}>
							Close
						</Button>

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste SKU values here."
							onChange={(event) => onChangeSkuImportValues(event)}
						/>

					</div>
				</Drawer>


				{/* Standard Page Header with right floated error message space */}
				<div style={{ minHeight: "50px", paddingTop: "5px" }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{ padding: "5px", minWidth: "270px" }}>{(errors.currenterror.errshow) &&
							<ErrorMessage />
						}</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{ textAlign: "center", margin: "auto", padding: "5px" }}>
							<h2>Skus{(listname !== "") && <React.Fragment>: {listname}</React.Fragment>}</h2>
						</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{ padding: "5px", minWidth: "270px" }}>
							<div style={{ float: "right" }}>
								<ProgressBar />
								<NewErrorMessage />
							</div>
						</Grid>
					</Grid>
				</div>


				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				<>
					{/* Inputs can be Grouped inside of new flex containers: */}
					<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
						{/* Group 1 */}
						<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
							<SearchInput
								searchinput={1}
								key={key1}
								localstate={state}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
							<SearchInput
								searchinput={2}
								key={key2}
								localstate={state}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
							<SearchInput
								searchinput={3}
								key={key3}
								localstate={localstate}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
							<SearchInput
								searchinput={4}
								key={key4}
								localstate={localstate}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
						</div>

						{/* Group 2 */}
						{(localstate.expandsearch) &&
							<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
								<SearchInput
									searchinput={5}
									key={key5}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>

								<SearchInput
									searchinput={6}
									key={key6}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
								<SearchInput
									searchinput={7}
									key={key7}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
								<SearchInput
									searchinput={8}
									key={key8}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
							</div>
						}
					</div>
				</>


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(!showdeleteconfirmation && !showexportconfirmation) &&
								<React.Fragment>

									<Button
										className={(userPerms.createSku === 1 || userPerms.updateSku === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
									</Button>

									<Button
										className={(userPerms.createSku === 1 || userPerms.updateSku === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										style={{ display: "none" }}
										onClick={() => SaveChanges()}
										ref={el => btnPendingSave.current = el}>
										<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
									</Button>




									<Button
										className={(userPerms.createSku === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={() => AddRow()}
										ref={el => btnAddRow.current = el}>
										<AddIcon />&nbsp;Add Sku
									</Button>


									{/* New View Options Menu */}
									{/* Compact a lot of table view options into this one menu to save space! */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowViewOptionsMenu}>
										View Options
									</Button>

									<Menu
										className={classes.bluebtn}
										sx={{ zIndex: 3000 }} // Adjust the z-index here
										color="primary"
										id="view-options-menu"
										anchorEl={showViewOptionsMenu}
										keepMounted
										open={Boolean(showViewOptionsMenu)}
										onClose={CloseViewOptionsMenu}
									>
										<div style={{ display: "inline-block", overflow: "auto", verticalAlign: "top" }}>
											<div style={{ fontWeight: "bold", textAlign: "center" }}>Columns</div>
											<MenuItem disableRipple className={classes.columnmenu}>
												<div style={{ verticalAlign: "top" }}> {/* Optional container for 2 column menu! */}
													<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
														<FormGroup>
															{/* ##########################  Column Toggles  ########################## */}
															<FlexColumnOption value="Title" label="Title" />
															<FlexColumnOption value="Gtin" label="Gtin" />
															<FlexColumnOption value="ProductID" label="ProductID" />
															<FlexColumnOption value="Product" label="Product" />
															<FlexColumnOption value="Location" label="Location" />
															<FlexColumnOption value="SkuComponents" label="Sku Components" />
															<FlexColumnOption value="ProductComponents" label="Product Components" />
															<FlexColumnOption value="Discount" label="Discount" />
															<FlexColumnOption value="CalcCost" label="Calculated Cost" />
															<FlexColumnOption value="Cost" label="Cost" />
															<FlexColumnOption value="GlobalTGI" label="Global TGI" />
															<FlexColumnOption value="Integrations" label="Integrations" />
															<FlexColumnOption value="updated_at" label="updated_at" />
														</FormGroup>
													</div>
												</div>
											</MenuItem>
										</div>
										<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top", maxWidth: "200px", padding: "0px 8px" }}>
											<div style={{ fontWeight: "bold", textAlign: "center" }}>Search Options</div>
											<Button
												className={classes.bluebtn}
												sx={{ width: "100%" }}
												color="primary" variant="contained"
												onClick={() => ToggleExpandSearch()}
											>
												{(!localstate.expandsearch) &&
													<>Expand Search</>
												}
												{(localstate.expandsearch) &&
													<>Collapse Search</>
												}
											</Button>
										</div>
									</Menu>
									{/* END OF VIEW OPTIONS MENU */}


									<Button
										className={(userPerms.updateSku === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={() => RejectIfInvalidSelected(true, setShowBulkEditDrawer)}
										ref={el => btnEditSelected.current = el}>
										Edit Selected
									</Button>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowExportMenu}
										ref={el => btnExport.current = el}>
										Export
									</Button>
									<Menu id="simple-menu"
										anchorEl={showexportmenu}
										keepMounted
										open={Boolean(showexportmenu)}
										onClose={CloseExportMenu}>
										<MenuItem onClick={() => InitExport("selected")}>Export Selected</MenuItem>
										<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 2000)</MenuItem>
									</Menu>

									<Button
										className={(userPerms.createSkuIntegration === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={openIntegrations}
										ref={el => btnAddIntegration.current = el}>
										Add Integration
									</Button>
									<Menu anchorEl={integrationsmenu} keepMounted open={Boolean(integrationsmenu)} onClose={closeIntegrations} >
										{localstate.integrations.map((integration, index) => {
											return (<MenuItem key={uuidv4()} onClick={() => { AddIntegration(integration) }}>
												<img style={{ height: "22px", width: "31px", padding: "0px 7px 0px 0px" }} src={integration.IconURL} />
												{integration.Name}</MenuItem>)

										})}
									</Menu>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => setShowSkuList(!showskulist)}
										{...(localstate.searchoptions.skulist !== '' ? {
											style: {
												textDecoration: "underline"
											}
										} :
											{
												style: {
													textDecoration: "none"
												}
											})}
										ref={el => btnSkuList.current = el}>
										Sku List
									</Button>

									<Button
										className={(userPerms.createSku === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={() => setShowImportSkus(!showimportskus)}
										ref={el => btnImportSkus.current = el}>
										Import SKUs
									</Button>

									{/* <Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => CreateList()}
										ref={el => btnCreateList.current = el}>
										Create List
									</Button> */}

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => ResetSearches()}
										ref={el => btnResetSearches.current = el}>
										<RestartAltIcon />&nbsp;Reset
									</Button>
								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these items?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => DeleteSelected()}>
										Yes, Delete Items
									</Button>&nbsp;&nbsp;
									<Button className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => CancelDelete()}>
										Cancel
									</Button>
								</div>
							}

							{/* Export Mode Confirmation */}
							{(showexportconfirmation) &&
								<div>
									<b>Export Mode</b><br></br>
									In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
									<div style={{ paddingTop: "10px" }}>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("simple")}>
											Simple
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("expanded")}>
											Expanded
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("exhaustive")}>
											Exhaustive
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => setShowExportConfirmation(false)}>
											Cancel
										</Button>
									</div>
								</div>
							}



							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[100, 250, 500]}
								/>
							}
						</div>
					</React.Fragment>
				}

				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount}
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */}
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										rowRefs.current[index + "Sku"] = React.createRef();
										rowRefs.current[index + "Title"] = React.createRef();
										rowRefs.current[index + "Gtin"] = React.createRef();
										rowRefs.current[index + "ProductID"] = React.createRef();
										rowRefs.current[index + "Location"] = React.createRef();
										rowRefs.current[index + "Discount"] = React.createRef();
										rowRefs.current[index + "GlobalTGI"] = React.createRef();
										rowRefs.current[index + "CalcCost"] = React.createRef();
										return (
											<React.Fragment key={row.ID}>
												<tr className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
															{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el => rowRefs.current[index + "Checkbox"] = el}
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

														</div>
													</td>

													{/* SKU */}
													{(colstate.Sku) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + "Sku"] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: "100px" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Sku")}
																onKeyUp={(event) => onChangeValue(event, index, "Sku")}
																defaultValue={row.Sku} />
															<ContentCopyIcon
																className={classes.hoverunit}
																style={{ fontSize: "14px", float: "right", margin: "3px 0px 0px 3px" }}
																onClick={() => CopyContent(row.Sku)} />
														</td>
													}

													{/* Title */}
													{(colstate.Title) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + "Title"] = el}
																className={classes.flexgridinput}
																style={{ minWidth: "100px" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Title")}
																onKeyUp={(event) => onChangeValue(event, index, "Title")}
																defaultValue={row.Title} />
														</td>
													}

													{/* Gtin */}
													{(colstate.Gtin) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + "Gtin"] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: "100px" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Gtin")}
																onKeyUp={(event) => onChangeValue(event, index, "Gtin")}
																defaultValue={row.Gtin} />
														</td>
													}

													{/* Product */}
													{(colstate.Product) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.product) &&
																<span>
																	{row.product.Name}
																</span>
															}
														</td>
													}

													{/* ProductID */}
													{(colstate.ProductID) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.product) &&
																<span>
																	{row.product.ProductID}
																</span>
															}
														</td>
													}

													{/* Location */}
													{(colstate.Location) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + "Location"] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: "100px" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Location")}
																onKeyUp={(event) => onChangeValue(event, index, "Location")}
																defaultValue={row.Location} />
														</td>
													}

													{/* Components */}
													{(colstate.SkuComponents || colstate.ProductComponents) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.skucomponents && colstate.SkuComponents) &&
																<React.Fragment>
																	{row.skucomponents.map((component, componentindex) => {
																		if (component.details === null){
																			return (
																				<div key={componentindex} className={classes.skucomponent} title={"Orphan Component"}>
																					Orphan Component - Remove<CloseIcon className={classes.skucomponentdeleteicon} onClick={(event) => { RemoveComponent(index, component.ID) }} />
																			</div>
																			)
																		} else {
																			return (
																				<div key={componentindex} className={classes.skucomponent} title={component.details.Cost}>
																					{component.details.Name} <CloseIcon className={classes.skucomponentdeleteicon} onClick={(event) => { RemoveComponent(index, component.ID) }} />
																				</div>
																			)
																		}
																		
																	})}
																	<AddIcon style={{ verticalAlign: "bottom" }} className={classes.hoverunit} onClick={(event) => AddComponent(index)} />
																</React.Fragment>
															}

															{(row.product && colstate.ProductComponents) &&
																<div style={{ paddingTop: "3px" }}>
																	{row.product.productcomponents.map((component, componentindex) => {
																		return (
																			<div key={componentindex} className={classes.productcomponent} title={component.details.Cost}>
																				{component.details.Name}
																			</div>
																		)
																	})}
																</div>
															}

														</td>
													}

													{/* Discount */}
													{(colstate.Discount) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "Discount"] = el}
																className={classes.flexgridinput}
																style={{ minWidth: "50px", textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Discount")}
																onKeyUp={(event) => onChangeValue(event, index, "Discount")}
																defaultValue={row.Discount} />
														</td>
													}

													{/* Calc Cost */}
													{(colstate.CalcCost) &&
														<td className={row.CalcCost > row.Cost ? classes.higherprice : (row.CalcCost === row.Cost ? classes.flexgridstaticcontainer : classes.lowerprice)}
															style={{ textAlign: "right" }}
															ref={el => rowRefs.current[index + "CalcCost"] = el}
														>
															{row.CalcCost}
														</td>
													}

													{/* Cost */}
													{(colstate.Cost) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "Cost"] = el}
																className={classes.flexgridinput}
																style={{ minWidth: "50px", textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Cost")}
																onKeyUp={(event) => onChangeValue(event, index, "Cost")}
																defaultValue={row.Cost} />
														</td>
													}

													{/* GlobalTGI */}
													{(colstate.GlobalTGI) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "GlobalTGI"] = el}
																className={classes.flexgridinput}
																style={{ minWidth: "50px", textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "GlobalTGI")}
																onKeyUp={(event) => onChangeValue(event, index, "GlobalTGI")}
																defaultValue={row.GlobalTGI} />
														</td>
													}

													{/* Integrations */}
													{(colstate.Integrations) &&
														<td>
															{row.skuintegrations.map((skuintegration, index) => {
																return (
																	<img style={{ height: "24px", padding: "0px 2px" }} src={skuintegration.integration.IconURL} key={index} />
																)
															})}
														</td>
													}

													{/* Updated Date */}
													{(colstate.updated_at) &&
														<td className={classes.flexgridstaticcontainer}>
															{dayjs(row.updated_at).format("YYYY-MM-DD H:m")}
														</td>
													}

												</tr>
												{/* Try: conditional for any render whatsoever! */}
												{(false) &&
													<tr>
														<td colSpan="100%">
															<div style={{ margin: "25px" }}>
																Expanded column container!
															</div>
														</td>
													</tr>
												}
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table>
				</div>





				{(localstate.rowsperpage > 249 && localstate.totalitems > 250) &&
					<TablePagination className={classes.paginationalign}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[100, 250, 500]}
					/>
				}


			</div>
		</LocalizationProvider>

	);
}

export default SkusTable;
