//Listing Builder
import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams, useHistory } from 'react-router-dom';

import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';
import { css } from '@emotion/css';
import './slate-custom.css';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { v4 as uuidv4 } from 'uuid';


//Let's try slate!
// Import the Slate editor factory.
import isUrl from 'is-url';
import imageExtensions from 'image-extensions';
import { withHistory } from 'slate-history';
import escapeHtml from 'escape-html';
import { Text, Transforms, Editor, Range, createEditor, Element as SlateElement, Node as SlateNode } from 'slate';

import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import { Slider, Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome } from '@uiw/react-color';

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, useSlate, useSlateStatic, ReactEditor, useSelected, useFocused } from 'slate-react';
//import Html from 'slate-html-serializer';
import { jsx } from 'slate-hyperscript';




import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from "@mui/icons-material/Image";
import { IconButton } from "@mui/material";


import { useSelector, useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

// //FlexDocument
// import { FlexDocument } from '../Documents/FlexDocument';

//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Datetime formatting
import Moment from 'react-moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import dayjs from 'dayjs'; //Used with new datetimepickers
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";




import AppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar'; 
import MenuIcon from '@mui/icons-material/Menu';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import PaletteIcon from '@mui/icons-material/Palette';
 

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

//Buttons
import Button from '@mui/material/Button';

//Datetime Pickers
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//DBAutoComplete
import DBAutoComplete from '../common/DBAutoComplete';

//Printing
import { useReactToPrint } from 'react-to-print';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';


/* ##########################  Configuration  ########################## */
const editorTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#EEE',
		},
	},
});



//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
//HTML Deserialize
const deserialize = (el, markAttributes = {}) => {
	if (el.nodeType === Node.TEXT_NODE) {
		return jsx('text', markAttributes, el.textContent)
	} else if (el.nodeType !== Node.ELEMENT_NODE) {
		return null
	}

	const nodeAttributes = { ...markAttributes }

	// define attributes for text nodes
	switch (el.nodeName) {
		case 'STRONG':
			nodeAttributes.bold = true
	}

	const children = Array.from(el.childNodes)
		.map(node => deserialize(node, nodeAttributes))
		.flat()

	if (children.length === 0) {
		children.push(jsx('text', nodeAttributes, ''))
	}

	switch (el.nodeName) {
		case 'BODY':
			return jsx('fragment', {}, children)
		case 'BR':
			return '\n'
		case 'BLOCKQUOTE':
			return jsx('element', { type: 'quote' }, children)
		case 'P':
			return jsx('element', { type: 'paragraph' }, children)
		case 'A':
			return jsx(
				'element',
				{ type: 'link', url: el.getAttribute('href') },
				children
			)
		default:
			return children
	}
}


// const sanitizeValue = (value) => {
// 	if (!value || value.length === 0) {
// 	  return [{ type: 'paragraph', children: [{ text: '' }] }];
// 	}

// 	if (typeof(value)==="string"){
// 		value = JSON.parse(value);
// 	}
  
// 	return value.map(node => {
// 	  if (node.type === 'image') {
// 		return {
// 		  ...node,
// 		  children: [{ text: '' }],
// 		};
// 	  }
  
// 	  if (node.type) {
// 		return {
// 		  ...node,
// 		  children: node.children.map(child => ({
// 			...child,
// 			text: child.text === null || child.text === undefined ? '' : child.text,
// 		  })),
// 		};
// 	  }
  
// 	  if (Text.isText(node)) {
// 		return {
// 		  ...node,
// 		  text: node.text === null || node.text === undefined ? '' : node.text,
// 		};
// 	  }
  
// 	  return node;
// 	});
//   };


const sanitizeValue = (value) => {
	// Check if value is null, undefined or empty
	if (!value || value.length === 0) {
	  return [{ type: 'paragraph', children: [{ text: '' }] }];
	}
  
	// Parse value if it's a string
	if (typeof value === "string") {
	  value = JSON.parse(value);
	}
  
	const sanitizeNode = (node) => {
	  if (node.type === 'image') {
		return {
		  ...node,
		  children: [{ text: '' }],
		};
	  }
  
	  if (node.children) {
		return {
		  ...node,
		  children: node.children.map(child => ({
			...child,
			text: child.text === null || child.text === undefined ? '' : child.text,
		  })),
		};
	  }
  
	  if (node.text !== undefined) {
		return {
		  ...node,
		  text: node.text === null || node.text === undefined ? '' : node.text,
		};
	  }
	  
	  return node;
	};
  
	return value.map(sanitizeNode);
  };
  

// //Convert to HTML
// const serialize = (node) => {
// 	if (typeof node === 'object' && node.text !== undefined) { // Fallback for text nodes
// 		console.log('Text node:', node.text);
// 		let string = escapeHtml(node.text || "");
// 		if (string.includes("\n")) {
// 		  string = string.replace(/\n/g, "<br>");
// 		}
	
// 		let styles = [];
// 		if (node.bold) styles.push("font-weight: bold");
// 		if (node.italic) styles.push("font-style: italic");
// 		if (node.color) styles.push(`color: ${node.color}`);
// 		if (node.fontSize) styles.push(`font-size: ${node.fontSize}px`);
// 		if (node.font) styles.push(`font-family: ${node.font}`);
	
// 		if (styles.length > 0) {
// 		  string = `<span style="${styles.join('; ')}">${string}</span>`;
// 		}
// 		return string;
// 	  }

// 	if (node.type) {
// 		const children = node.children.map(n => serialize(n)).join('');
// 		console.log(`Type: ${node.type}, Children:`, children);

// 		switch (node.type) {
// 			case 'image':
// 				const imgStyles = [
// 					'max-width: 100%',
// 					node.width ? `width: ${escapeHtml(node.width)}` : 'width: 300px',
// 					node.height ? `height: ${escapeHtml(node.height)}` : 'height: auto',
// 				];
// 				const imgTag = `<img src="${escapeHtml(node.url)}" alt="Uploaded image" style="${imgStyles.join('; ')}" />`;
// 				// Wrap in a div with alignment if align is specified
// 				return node.align
// 					? `<div style="text-align: ${escapeHtml(node.align)}">${imgTag}</div>`
// 					: imgTag;
// 			case 'paragraph':
// 				// Add line-height: normal to the <p> tag, combining with text-align if present
// 				const pStyles = ['line-height: normal'];
// 				if (node.align) pStyles.push(`text-align: ${escapeHtml(node.align)}`);
// 				return `<p style="${pStyles.join('; ')}">${children}</p>`;
// 			case 'heading-one':
// 				return `<h1${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</h1>`;
// 			case 'heading-two':
// 				return `<h2${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</h2>`;
// 			case 'heading-three':
// 				return `<h3${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</h3>`;
// 			case 'heading-four':
// 				return `<h4${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</h4>`;
// 			case 'heading-five':
// 				return `<h5${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</h5>`;
// 			case 'bulleted-list': 
// 				// Handle unordered list with optional alignment
// 				return `<ul ${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</ul>`;
// 			case 'numbered-list':
// 				// Handle ordered list with optional alignment
// 				return `<ol ${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</ol>`;
// 			case 'list-item':
// 				// Handle list items
// 				return `<li ${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</li>`;
// 			default:
// 				return `<div ${node.align ? ` style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</div>`;
// 		}
// 	}

// 	if (Array.isArray(node)) {
// 		return node.map(n => serialize(n)).join('');
// 	}

// 	return '';
// };


// const serialize = (node) => {
// 	console.log('Node received:', JSON.stringify(node, null, 2)); // Log the full node
// 	if (typeof node === 'object' && node.text !== undefined) {
// 	  console.log('Text node detected, text:', node.text); // Log the text content
// 	  let string = escapeHtml(node.text || "");
// 	  if (string.includes("\n")) {
// 		string = string.replace(/\n/g, "<br>");
// 	  }
  
// 	  let styles = [];
// 	  if (node.bold) styles.push("font-weight: bold");
// 	  if (node.italic) styles.push("font-style: italic");
// 	  if (node.color) styles.push(`color: ${node.color}`);
// 	  if (node.fontSize) styles.push(`font-size: ${node.fontSize}px`);
// 	  if (node.font) styles.push(`font-family: ${node.font}`);
  
// 	  if (styles.length > 0) {
// 		string = `<span style="${styles.join('; ')}">${string}</span>`;
// 	  }
// 	  return string;
// 	}
  
// 	if (node.type) {
// 	  console.log('Processing type:', node.type, 'with children:', node.children); // Log type and children
// 	  const children = node.children.map(n => serialize(n)).join('');
// 	  console.log(`Type: ${node.type}, Serialized children:`, children); // Log result
// 	  switch (node.type) {
// 		case 'paragraph':
// 		  const pStyles = ['line-height: normal'];
// 		  if (node.align) pStyles.push(`text-align: ${escapeHtml(node.align)}`);
// 		  return `<p style="${pStyles.join('; ')}">${children}</p>`;
// 		case 'bulleted-list':
// 		  return `<ul ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</ul>`;
// 		case 'list-item':
// 		  return `<li ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</li>`;
// 		default:
// 		  return `<div ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</div>`;
// 	  }
// 	}
  
// 	if (Array.isArray(node)) {
// 	  console.log('Array detected, processing items:', node);
// 	  return node.map(n => serialize(n)).join('');
// 	}
  
// 	return '';
//   };

// const serialize = (node) => {
// 	if (node.type) {
// 	  const children = node.children.map(n => serialize(n)).join('');
// 	  switch (node.type) {
// 		case 'paragraph':
// 		  const pStyles = ['line-height: normal'];
// 		  if (node.align) pStyles.push(`text-align: ${escapeHtml(node.align)}`);
// 		  return `<p style="${pStyles.join('; ')}">${children}</p>`;
// 		case 'bulleted-list':
// 		  return `<ul ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</ul>`;
// 		case 'list-item':
// 		  // Extract styles from the first child (assuming consistent styling)
// 		  const firstChild = node.children[0] || {};
// 		  let liStyles = [];
// 		  if (firstChild.color) liStyles.push(`color: ${firstChild.color}`);
// 		  if (firstChild.fontSize) liStyles.push(`font-size: ${firstChild.fontSize}px`);
// 		  if (firstChild.font) liStyles.push(`font-family: ${firstChild.font}`);
// 		  if (node.align) liStyles.push(`text-align: ${escapeHtml(node.align)}`);
// 		  return `<li ${liStyles.length > 0 ? `style="${liStyles.join('; ')}"` : ''}>${children}</li>`;
// 		default:
// 		  return `<div ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</div>`;
// 	  }
// 	}
  
// 	if (typeof node === 'object' && node.text !== undefined) {
// 	  let string = escapeHtml(node.text || "");
// 	  if (string.includes("\n")) {
// 		string = string.replace(/\n/g, "<br>");
// 	  }
  
// 	  let styles = [];
// 	  if (node.bold) styles.push("font-weight: bold");
// 	  if (node.italic) styles.push("font-style: italic");
// 	  // Remove color, fontSize, font from here since they're handled at the li level for list items
// 	  return `<span style="${styles.join('; ')}">${string}</span>`; // Only apply bold/italic
// 	}
  
// 	if (Array.isArray(node)) {
// 	  return node.map(n => serialize(n)).join('');
// 	}
  
// 	return '';
//   };


const serialize = (node) => {
	if (node.type) {
	  const children = node.children ? node.children.map(n => serialize(n)).join('') : '';
	  switch (node.type) {
		case 'image':
		const imgStyles = [
			'max-width: 100%',
			node.width ? `width: ${escapeHtml(node.width)}` : 'width: 300px',
			//node.height ? `height: ${escapeHtml(node.height)}` : 'height: auto',
		];
		const imgTag = `<img src="${escapeHtml(node.url)}" alt="Uploaded image" style="${imgStyles.join('; ')}" />`;
		// Wrap in a div with alignment if align is specified
		return node.align
			? `<div style="text-align: ${escapeHtml(node.align)}">${imgTag}</div>`
			: imgTag;
		case 'paragraph':
		  const pStyles = ['line-height: normal'];
		  if (node.align) pStyles.push(`text-align: ${escapeHtml(node.align)}`);
		  return `<p style="${pStyles.join('; ')}">${children}</p>`;
		case 'bulleted-list':
		  return `<ul ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</ul>`;
		case 'list-item':
		  // Extract styles from the first child (assuming consistent styling)
		  const firstChild = node.children && node.children[0] || {};
		  let liStyles = [];
		  if (firstChild.color) liStyles.push(`color: ${firstChild.color}`);
		  if (firstChild.fontSize) liStyles.push(`font-size: ${firstChild.fontSize}px`);
		  if (firstChild.font) liStyles.push(`font-family: ${firstChild.font}`);
			  if (node.align) liStyles.push(`text-align: ${escapeHtml(node.align)}`);
			  return `<li ${liStyles.length > 0 ? `style="${liStyles.join('; ')}"` : ''}>${children}</li>`;
		  case 'heading-one':
			  return `<h1>${children}</h1>`;
		  case 'heading-two':
			  return `<h2>${children}</h2>`;
		  case 'heading-three':
			  return `<h3>${children}</h3>`;
		  case 'heading-four':
			  return `<h4>${children}</h4>`;
		  case 'heading-five':
			  return `<h5>${children}</h5>`;
		  default:
		  return `<div ${node.align ? `style="text-align: ${escapeHtml(node.align)}"` : ''}>${children}</div>`;
	  }
	}
  
	if (typeof node === 'object' && node.text !== undefined) {
	  let string = escapeHtml(node.text || "");
	  if (string.includes("\n")) {
		string = string.replace(/\n/g, "<br>");
	  }
  
	  let styles = [];
	  if (node.bold) styles.push("font-weight: bold");
	  if (node.italic) styles.push("font-style: italic");
	  if (node.underline) styles.push("text-decoration: underline");
	  if (node.color) styles.push(`color: ${node.color}`);
	if (node.fontSize) styles.push(`font-size: ${node.fontSize}px`);
	  return `<span style="${styles.join('; ')}">${string}</span>`; // Only apply bold/italic
	}
  
	if (Array.isArray(node)) {
	  return node.map(n => serialize(n)).join('');
	}
	return '';
  };


const initialValue = [
	{ type: 'paragraph', children: [{ text: 'A line of text in a paragraph.' }] },
]


const serializeAll = (elementvalue) => {
	if (elementvalue) {
		const elementjson = sanitizeValue(JSON.parse(elementvalue));
		return elementjson.map(node => serialize(node)).join(''); // Join all serialized nodes into a single string
	} else {
		return initialValue;
	}
};


// Extend editor with image handling
const withImages = editor => {
	const { isVoid } = editor;
	editor.isVoid = element => (element.type === 'image' ? true : isVoid(element));
	return editor;
};

const normalizeSlateData = (nodes) => {
	const result = [];
  
	nodes.forEach((node) => {
	  if (['paragraph', 'bulleted-list', 'numbered-list', 'heading-one', 'heading-two', 'heading-three'].includes(node.type)) {
		const { text, ...cleanNode } = node;
		const children = cleanNode.children || [];
		const blockChildren = [];
		const listChildren = [];
  
		children.forEach((child) => {
		  if (['bulleted-list', 'numbered-list'].includes(child.type)) {
			const { text, ...cleanChild } = child;
			cleanChild.children = normalizeSlateData(cleanChild.children);
			listChildren.push(cleanChild);
		  } else if (['paragraph', 'heading-one', 'heading-two', 'heading-three'].includes(child.type)) {
			// Flatten nested block elements
			if (child.children) {
			  blockChildren.push(...normalizeSlateData(child.children));
			} else {
			  blockChildren.push({ ...child, children: [] });
			}
		  } else {
			blockChildren.push(normalizeSlateData([child])[0]);
		  }
		});
  
		if (blockChildren.length > 0) {
		  cleanNode.children = blockChildren;
		  result.push(cleanNode);
		} else {
		  result.push(cleanNode);
		}
		result.push(...listChildren);
	  } else if (node.type === 'list-item') {
		const { text, ...cleanNode } = node;
		cleanNode.children = normalizeSlateData(cleanNode.children);
		result.push(cleanNode);
	  } else {
		result.push(node); // Leaf nodes
	  }
	});
  
	return result;
  };






const ListingBuilder = (props) => {
	document.title = "Listing Builder";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Martin's Section"));
	dispatch(setCurrentMenuItem("/listingbuilder"));

	//const [editor] = useState(() => withReact(createEditor()));
	const editor = useMemo(() => withImages(withHistory(withReact(createEditor()), [])));
	const fonts = ['Arial', 'Times New Roman', 'Roboto', 'Raleway'];

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	const btnAddElement = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		templateloaded: false,
		dbreload: false,
		itemdata: {
			ID: uuidv4(), //I believe it may be neccessary to build out a good looking itemdata so that any DBAutoComplete instances can use the required values.
			Name: null
		},
	});


	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	//let localstate = {...state}; 


	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Ref used for printing
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	//Try Slate:
	// Create a Slate editor object that won't change across renders.
	//const [editor] = useState(() => withReact(createEditor()));
	const initialValue = [
		{
			type: 'paragraph',
			children: [{ text: 'A line of text in a paragraph.' }],
		},
	]

	const [currenteditor, setCurrentEditor] = useState({
		Name: "",
		modules: {
			toolbar: true
		}
	});

	const ImageUpload = (props) => {
		const [imageUrl, setImageUrl] = useState(null);
		const [error, setError] = useState(null);
		const templateindex = props.templateelement.Name.slice(-1);
		const templateroot = props.templateelement.Name.slice(0,-1);
		const onDrop = useCallback(async (acceptedFiles) => {
			try {
				const file = acceptedFiles[0];
				const url = await uploadImage(file);
				//setImageUrl(url);
				//Set localhost:
				localstate.itemdata[props.templateelement.Name] = url;
				UpdateState(localstate);
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
			} catch (err) {
				setError(err.message);
			}
		}, []);

		const onChangeContainerWidth = (event) => {
			console.log(event);
			localstate.itemdata[templateroot+"Width"+templateindex] = parseInt(event.target.value);
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
		}
	
		const { getRootProps, getInputProps } = useDropzone({ onDrop });
	
		return (
			<>
				<div {...getRootProps()} style={{
						textAlign: 'center',
						cursor: 'pointer',
					}}>
					<input {...getInputProps()} />
					{localstate.itemdata[props.templateelement.Name]==="placeholder" &&<div style={{minHeight:"50px"}}><p>Place Image Here</p></div>}
					{(localstate.itemdata[props.templateelement.Name] && localstate.itemdata[props.templateelement.Name]!=="placeholder") &&
						<img src={localstate.itemdata[props.templateelement.Name]} alt="Uploaded" style={{
							maxWidth: '100%'
							}} 
						/>}
					{error && <p style={{color:"red"}}>{error}</p>}
				</div>
				Container Width: <input type="text" defaultValue={localstate.itemdata[templateroot+"Width"+templateindex]} onChange={(event)=>onChangeContainerWidth(event)} />
			</>
			
		);
	};


		// Your uploadImage function
		async function uploadImage(file) {
			return new Promise(async (resolve, reject) => {
				try {
					const foldername = dayjs().format('YYYY-MM');
					const response = await fetch(
						`https://00iojje8z6.execute-api.us-east-2.amazonaws.com/default/uploadImageFromFlex?folder=flextemplates/${foldername}`
					);
					const { uploadURL, filename } = await response.json();
					const uploadResponse = await fetch(uploadURL, {
						method: 'PUT',
						body: file,
						headers: {
							'Content-Type': file.type,
						},
					});
	
					if (!uploadResponse.ok) {
						throw new Error('Upload failed');
					}
					const imageUrl = `https://deluxepcsimages.s3.amazonaws.com/${filename}`;
					resolve(imageUrl);
				} catch (error) {
					reject(error);
				}
			});
		}
	




	//Load Item
	function LoadTemplate() {
		const postdata = {
			ID: localstate.itemdata.ID
		};
		axios.post(dbendpoint + "/listingtemplate/getitem", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;
					// const document =  new DOMParser().parseFromString("<p>This1</p>", 'text/html');
					// localstate.itemdata.Header = deserialize(document.body); 
					localstate.templateloaded = true;
					localstate.dbreload = false;


					//Serialize all on load?
					for (var i=0; i<templateelements.length; i++){
						//Don't convert left side elements, they are handled by an image container, not the editor.
						console.log(templateelements[i].Name);
						if (localstate.itemdata[templateelements[i].Name]){ //Cannot use includes on null values
							if (!templateelements[i].Name.includes("Left") && !templateelements[i].Name.includes("HTML")){
								localstate.itemdata[templateelements[i].Name+"HTML"] = serializeAll(localstate.itemdata[templateelements[i].Name]);
							}
						}
					}
					//localstate.itemdata.HeaderHTML = serializeAll(localstate.itemdata.Header);
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: "neutral" }));
					dispatch(setErrorTimeout(10));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" }));
				dispatch(setErrorTimeout(15));
			}
		});
	}


	useEffect(() => {
		document.title = "Listing Builder";
		if (state.dbreload) {
			localstate.dbreload = false;
			setTimeout(()=>{LoadTemplate();}, 300); // 100ms delay
		}
	}, [state]);


	useEffect(() => {
		if (currenteditor.Name && !currenteditor.Name.includes('Left') && !currenteditor.Name.includes('HTML') ) {
			setTimeout(() => {
				initSlateValue(currenteditor.Name);
			}, 500);
		}
	}, [currenteditor]);


	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	function removeTypeFromTextNodes(value) {
		function traverse(node) {
			if (Array.isArray(node)) {
				node.forEach(traverse);
			} else if (typeof node === 'object' && node !== null) {
				if ('text' in node) {
					delete node.type;
				}
				Object.values(node).forEach(traverse);
			}
		}
	
		traverse(value);
		return value;
	}

	const SaveChanges = () => {
		//Hide current errors:
		dispatch(setErrorTimeout(0));

		if (localstate.itemdata.Name.length === 0) {
			dispatch(newErrorMessage({ errmsg: "No items to save!", errshow: true, errtimeout: 15, errtype: "warning" }));
			dispatch(setErrorTimeout(15));
			return;
		}

		let postdata = {
			item: cloneDeep(localstate.itemdata)
		}

		if (!currenteditor.Name.includes("Left") && !currenteditor.Name.includes("HTML")){ //Ignore handling "Left"s which are always images in which the url does not need stringified.
			var newvalue = removeTypeFromTextNodes(cloneDeep(postdata.item[currenteditor.Name]));
			postdata.item[currenteditor.Name] = JSON.stringify(newvalue);
		}
		
		

		//Can we mutate it here instead?

		console.log(postdata.item[currenteditor.Name]);

		axios.post(dbendpoint + "/listingtemplate/update", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					btnSave.current.style.display = "";
					btnPendingSave.current.style.display = "none";
					//Attempt to replace: btnSave.current.disabled = true;
					localstate.pendingsaves = false;
					//Use what little data we have to populate autocomplete:
					// localstate.itemdata = res.data.item;

					//We need this for subsequent reload of item.
					localstate.itemdata.ID = res.data.item.ID;
					
					localstate.dbreload = true;
					btnAddElement.current.disabled = false;
					//setCurrentEditor({Name:"", toolbar:false});
					setSlateValue([]);
					setCurrentEditor({ Name: "" });
					UpdateState(localstate);
					dispatch(newErrorMessage({ errmsg: "Saved successfully.", errshow: true, errtimeout: 15, errtype: "ok" }));
					dispatch(setErrorTimeout(10));
				}
				if (res.data.Status === "Failure") {
					//Failure error
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" }));
					dispatch(setErrorTimeout(15));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" }));
				dispatch(setErrorTimeout(15));
			}
		});
	}



	const onDBAutoCompleteChange = (newvalue, searchkey) => {
		if (searchkey === "listingtemplates") {
			// console.log("Detect if new...");
			console.log(newvalue);
			if (newvalue) {
				if (newvalue.hasOwnProperty('addnewitem')) {
					if (newvalue.addnewitem === true) {
						localstate.itemdata.PendingItem = 1; //Setup for first creation of item
						localstate.itemdata.Name = cloneDeep(newvalue.newitemvalue);
						//Allow save of new item:
						btnSave.current.style.display = "none";
						btnPendingSave.current.style.display = "";
						UpdateState(localstate);
					}
				} else {
					localstate.dbreload = true;
					localstate.itemdata.ID = newvalue.ID;
					UpdateState(localstate);
				}
			}
		}
	}



	/* View Options Menu */
	const [showAddElementMenu, setViewOptionsMenu] = useState(null);
	const ShowAddElementMenu = (event) => {
		setViewOptionsMenu(event.currentTarget);
	}
	const CloseAddElementMenu = () => {
		setViewOptionsMenu(null);
	}

	const AddElement = (elementname) => {
		CloseAddElementMenu();
		//Add provision for adding placeholder for "Left"s
		if (elementname.includes("Left") || elementname.includes("HTML")){
			localstate.itemdata[elementname] = "placeholder";
		}
		//Need to activate the right side too. Fill data for it:
		if (elementname.includes("Left")){
			const rightside = elementname.replace("Left","Right");
			localstate.itemdata[rightside] = initialValue;
		}
		setCurrentEditor({
			Name: elementname
		});
	}

	const onChangeSlateValue = (value) => {
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		console.log(value);
		//might be jumping us out of focus?
		//setSlateValue(value);
		localstate.itemdata[currenteditor.Name] = value;
	}


	const [slatevalue, setSlateValue] = useState([]);

	const initSlateValue = (elementname) => {
		if (localstate.itemdata[elementname]) {
			const newslatevalue = normalizeSlateData(sanitizeValue(JSON.parse(localstate.itemdata[elementname])));
			console.log("##### Slate Value: #####");
			console.log(newslatevalue);
			setSlateValue(newslatevalue);
		} else {
			setSlateValue(initialValue);
		}
	}



	const deleteElement = (templateelement) => {
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		//console.log(localstate.itemdata[templateelement.Name]);
		localstate.itemdata[templateelement.Name] = null;
		setCurrentEditor({ Name: "" });
		UpdateState(localstate);
	}




	// Grok

	//Custom Toolbar Component
	const FullToolbar = ({ editor, templateelement }) => {
		const [textcolor, setTextColor] = React.useState('#222')
		const [opencolorselection, setOpenColorSelection] = useState(false);
		const setAlignment = (alignment) => {
			const { selection } = editor;
			if (!selection) return;
			Transforms.setNodes(
				editor,
				{ align: alignment },
				{ match: n => Editor.isBlock(editor, n), mode: 'all' }
			);
		};

		const toggleMark = (mark) => {
			const isActive = Editor.nodes(editor, {
				match: n => n[mark] === true,
				mode: 'all',
			}).next().value;
			if (isActive) {
				Editor.removeMark(editor, mark);
			} else {
				Editor.addMark(editor, mark, true);
			}
		};

		const setBlockType = (type) => {
			const { selection } = editor;
			if (!selection) return;
	
			const nodes = Array.from(Editor.nodes(editor, { at: selection, match: n => Editor.isBlock(editor, n), mode:'all' }));
			nodes.forEach(([node, path]) => {
				// Set the block type
				Transforms.setNodes(editor, { type }, { at: path });
	
				// Ensure all child nodes do not have a type property
				if (SlateElement.isElement(node)) {
					node.children.forEach((child, index) => {
						const childPath = path.concat(index);
						if (SlateElement.isElement(child)) {
							Transforms.setNodes(editor, { type: 'paragraph' }, { at: childPath });
						} else if (Text.isText(child)) {
							// Do not set type on text nodes
							Transforms.unsetNodes(editor, 'type', { at: childPath });
						}
					});
				}
			});
		};

		const setColor = (color) => {
			Editor.addMark(editor, 'color', color);
		};

		
		const CustomFont = {
			isFontActive(editor, font) {
				const [match] = Array.from(
				  Editor.nodes(editor, {
					match: (n) => Text.isText(n) && n.font === font,
					mode: 'all',
				  })
				);
				return !!match;
			  },
			
			  toggleFont(editor, font) {
				const isActive = CustomFont.isFontActive(editor, font);
				Transforms.setNodes(
				  editor,
				  { font: isActive ? null : font },
				  { match: (n) => Text.isText(n), split: true }
				);
			  },
		  };


		const toggleList = (listType) => {
			if (!editor.selection) {
			  console.log('No selection, exiting toggleList');
			  return;
			}
		  
			const isActive = Editor.nodes(editor, {
			  match: (n) => n.type === listType,
			  mode: 'all',
			  at: editor.selection,
			}).next().value;
		  
			if (isActive) {
			  Transforms.unwrapNodes(editor, {
				match: (n) => n.type === listType,
				split: true,
				at: editor.selection,
			  });
			  Transforms.setNodes(
				editor,
				{ type: 'paragraph' },
				{ match: (n) => Editor.isBlock(editor, n) && n.type === 'list-item', at: editor.selection }
			  );
			} else {
			  Transforms.setNodes(
				editor,
				{ type: 'list-item' },
				{ match: (n) => Editor.isBlock(editor, n) && n.type === 'paragraph', at: editor.selection }
			  );
			  Transforms.wrapNodes(
				editor,
				{ type: listType, children: [] },
				{ match: (n) => n.type === 'list-item', at: editor.selection }
			  );
			}
		  };

		  return(
			<ThemeProvider theme={editorTheme}>
				  <AppBar
					  sx={{ boxShadow: 0, border:"1px solid #CCC"}}
					  position="static" enableColorOnDark>
					  <MuiToolbar variant="dense" sx={{ flexWrap: 'wrap', justifyContent: "center"}}>
						  {/* Font Select */}
						  <Select
							  defaultValue={"Roboto"}
							  //onChange={handleFontChange}
							  onChange={(e) => CustomFont.toggleFont(editor, e.target.value)}
							  displayEmpty
							  sx={{
								  color: '#333',
								  '.MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '&:hover .MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '.MuiSvgIcon-root': { color: '#333' },
								  minWidth: 120,
								  '& .MuiSelect-select': {
									  padding: '4px 24px 4px 8px', // Reduced padding: top, right, bottom, left
								  },
							  }}
						  >
							  <MenuItem value="" disabled>
								  Font Family
							  </MenuItem>
							  {fonts.map((font) => (
								  <MenuItem key={font} value={font}>
									  {font}
								  </MenuItem>
							  ))}
						  </Select>
						  {/* Block Type */}
						  <Select
							  defaultValue={"default"}
							  //onChange={handleFontChange}
							  onChange={(e) => {
								  e.preventDefault();
								  setBlockType(e.target.value);
							  }
							  }
							  displayEmpty
							  sx={{
								  color: '#333',
								  marginLeft:"5px",
								  '.MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '&:hover .MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '.MuiSvgIcon-root': { color: '#333' },
								  minWidth: 120,
								  '& .MuiSelect-select': {
									  padding: '4px 24px 4px 8px', // Reduced padding: top, right, bottom, left
								  },
							  }}
						  >
							  <MenuItem value="default" disabled>
								  Block Type
							  </MenuItem>
							  <MenuItem value="paragraph">Paragraph</MenuItem>
							  <MenuItem value="heading-one">H1</MenuItem>
							  <MenuItem value="heading-two">H2</MenuItem>
							  <MenuItem value="heading-three">H3</MenuItem>
							  <MenuItem value="heading-four">H4</MenuItem>
							  <MenuItem value="heading-five">H5</MenuItem>
						  </Select>

						  {/* Font Size */}
						  <Select
							  defaultValue={"default"}
							  //onChange={handleFontChange}
							  onChange={(e) => {
								  e.preventDefault();
									Editor.addMark(editor, 'fontSize', e.target.value);

								 // setBlockType(e.target.value);
							  }
							  }
							  displayEmpty
							  sx={{
								  color: '#333',
								  marginLeft:"5px",
								  '.MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '&:hover .MuiOutlinedInput-notchedOutline': { border:"0px", borderColor: '#333' },
								  '.MuiSvgIcon-root': { color: '#333' },
								  minWidth: 120,
								  '& .MuiSelect-select': {
									  padding: '4px 24px 4px 8px', // Reduced padding: top, right, bottom, left
								  },
							  }}
						  >
							  <MenuItem value="default" disabled>
								  Font Size
							  </MenuItem>
							  <MenuItem value="12">12px</MenuItem>
							  <MenuItem value="14">14px</MenuItem>
							  <MenuItem value="16">16px</MenuItem>
							  <MenuItem value="18">18px</MenuItem>
							  <MenuItem value="24">24px</MenuItem>
							  <MenuItem value="32">32px</MenuItem>
							  <MenuItem value="48">48px</MenuItem>

						  </Select>

						  {(opencolorselection) &&
							  <div style={{ position: "relative", top: "0px", display: "inline-block" }}>
								  <div style={{ position: "absolute", top: "20px", right:"-110px", zIndex:9999 }}>
									  <Sketch
										  style={{ marginLeft: 20 }}
										  color={textcolor}
										  onChange={(color) => {
											  setColor(color.hex);
											  //setOpenColorSelection(false);
										  }}
									  />
								  </div>
							  </div>
						  }


						  <IconButton onClick={() => setOpenColorSelection(!opencolorselection)}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <PaletteIcon />
						  </IconButton>

						  <IconButton onClick={() => toggleMark("bold")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatBoldIcon />
						  </IconButton>

						  <IconButton onClick={() => toggleMark("italic")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatItalicIcon />
						  </IconButton>

						  <IconButton onClick={() => toggleMark("underline")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatUnderlinedIcon />
						  </IconButton>

						  <IconButton onClick={() => setAlignment("left")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatAlignLeftIcon />
						  </IconButton>

						  <IconButton onClick={() => setAlignment("center")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatAlignCenterIcon />
						  </IconButton>

						  <IconButton onClick={() => setAlignment("right")}
							  edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
							  <FormatAlignRightIcon onClick={() => setAlignment("right")} />
						  </IconButton>
						  {/* New list buttons */}
						  <IconButton onClick={() => toggleList('bulleted-list')} color="inherit">
							  <FormatListBulletedIcon />
						  </IconButton>
						  <IconButton onClick={() => toggleList('numbered-list')} color="inherit">
							  <FormatListNumberedIcon />
						  </IconButton>
						  <IconButton onClick={() => deleteElement(templateelement)} color="inherit">
							  <DeleteIcon />
						  </IconButton>
					  </MuiToolbar>
				  </AppBar>
			  </ThemeProvider>
		  );
	};

	

	// Render inline marks (bold, italic, font size, color)
	const renderLeaf = ({ attributes, children, leaf }) => {
		console.log('Leaf:', leaf); // Check if color is present
		let styledChildren = children;
		if (leaf.bold) {
			styledChildren = <strong>{styledChildren}</strong>;
		}
		if (leaf.italic) {
			styledChildren = <em>{styledChildren}</em>;
		}
		if (leaf.fontSize) {
			styledChildren = <span style={{ fontSize: `${leaf.fontSize}px`, lineHeight:"normal" }}>{styledChildren}</span>;
		}
		if (leaf.color) {
			styledChildren = <span style={{ color: leaf.color }}>{styledChildren}</span>;
		}
		if (leaf.font) {
			styledChildren = <span style={{ fontFamily: leaf.font}}>{styledChildren}</span>;
		}
		if (leaf.underline) {
			styledChildren = <span style={{ textDecoration: "underline"}}>{styledChildren}</span>;
		}
		if (leaf.type==="list-item"){
			styledChildren = <li>{styledChildren}</li>;
		}
		return <span {...attributes}>{styledChildren}</span>;
	};



 const renderElement = useCallback(({ attributes, children, element, path }) => {
    const style = { textAlign: element.align || 'left' };

    const handleResize = (event, { size }) => {
      Transforms.setNodes(
        editor,
        { width: `${size.width}px`, height: `${size.height}px` },
        { at: path }
      );
      console.log(`Resized image at path ${JSON.stringify(path)} to ${size.width}x${size.height}`);
    };

    const getListItemTextStyles = (listItemNode) => {
      const firstTextNode = listItemNode.children?.find((child) => Text.isText(child));
      if (!firstTextNode) return {};
      return {
        ...(firstTextNode.fontSize && { fontSize: `${firstTextNode.fontSize}px` }),
        ...(firstTextNode.color && { color: firstTextNode.color }),
      };
    };

    switch (element.type) {
      case 'container':
        return (
          <div
            {...attributes}
            style={{
              border: '2px dashed #ccc',
              padding: '10px',
              margin: '10px 0',
              ...style,
            }}
          >
            {children}
          </div>
        );
      case 'image':
        const aspectRatio =
          (parseInt(element.originalWidth) || 300) / (parseInt(element.originalHeight) || 200);
        return (
          <div {...attributes} style={style}>
            <ResizableBox
              width={parseInt(element.width) || 300}
              height={parseInt(element.height) || 300 / aspectRatio}
              lockAspectRatio={true}
              minConstraints={[100, 100 / aspectRatio]}
              maxConstraints={[1600, 1600 / aspectRatio]}
              onResizeStop={handleResize}
              style={{ display: 'inline-block' }}
            >
              <img src={element.url} alt="Uploaded image" style={{ width: '100%', height: '100%' }} />
            </ResizableBox>
          </div>
        );
      case 'heading-one':
        return <h1 style={style} {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 style={style} {...attributes}>{children}</h2>;
      case 'heading-three':
        return <h3 style={style} {...attributes}>{children}</h3>;
      case 'heading-four':
        return <h4 style={style} {...attributes}>{children}</h4>;
      case 'heading-five':
        return <h5 style={style} {...attributes}>{children}</h5>;
      case 'paragraph':
        return <p style={style} {...attributes}>{children}</p>;
      case 'bulleted-list':
        const firstBulletedItem = element.children.find((child) => child.type === 'list-item');
        const bulletStyles = getListItemTextStyles(firstBulletedItem || {});
        return (
          <ul
            {...attributes}
            style={{
              listStyleType: 'disc',
              marginLeft: '20px',
              ...bulletStyles,
              ...style,
            }}
          >
            {children}
          </ul>
        );
      case 'numbered-list':
        const firstNumberedItem = element.children.find((child) => child.type === 'list-item');
        const numberStyles = getListItemTextStyles(firstNumberedItem || {});
        return (
          <ol
            {...attributes}
            style={{
              listStyleType: 'decimal',
              marginLeft: '20px',
              ...numberStyles,
              ...style,
            }}
          >
            {children}
          </ol>
        );
      case 'list-item':
        const listItemStyles = getListItemTextStyles(element);
        return <li {...attributes} style={{ ...style, ...listItemStyles }}>{children}</li>;
      default:
        return <div style={style} {...attributes}>{children}</div>;
    }
  }, [editor]);


	const renderElementWithPath = (props) => {
		const { attributes, children, element } = props;
		// Find the path of this specific element
		const path = Array.from(
			Editor.nodes(editor, {
				match: n => n === element,
				mode: 'highest',
			})
		)[0]?.[1] || [];
		return renderElement({ attributes, children, element, path }, editor);
	};


	const InsertImageButton = () => {
		const editor = useSlateStatic()
		return (
			<Button
				onMouseDown={event => {
					event.preventDefault()
					const url = window.prompt('Enter the URL of the image:')
					if (url && !isImageUrl(url)) {
						alert('URL is not an image')
						return
					}
					url && insertImage(editor, url)
				}}
			>
				<div>image</div>
			</Button>
		)
	}
	const isImageUrl = url => {
		//This isUrl library sucks, don't use it.
		// if (!url) return false
		// if (!isUrl(url)) return false
		const ext = new URL(url).pathname.split('.').pop()
		return imageExtensions.includes(ext)
	}


	const insertImage = (editor, url) => {
		const text = { text: '' }
		const image = { type: 'image', url, children: [text] }
		Transforms.insertNodes(editor, image)
		const paragraph = {
			type: 'paragraph',
			children: [{ text: '' }],
		}
		Transforms.insertNodes(editor, paragraph)
	}



	const handleDrop = async (event) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		console.log('Dropped files:', files.map(f => f.name));
	  
		const imageFiles = files.filter(file => file.type.startsWith('image'));
		if (imageFiles.length === 0) {
		  console.warn('No valid image files detected');
		  return;
		}
	  
		try {
		  const placeholderPaths = [];
		  imageFiles.forEach((_, index) => {
			const placeholder = {
			  type: 'image',
			  url: 'https://deluxepcsimages.s3.amazonaws.com/flextemplates/static/gg.gif',
			  placeholderId: `temp-${Date.now()}-${index}`,
			  width: '100%', // Initial display width
			  height: 'auto', // Initial display height
			  children: [{ text: '' }],
			};
			Transforms.insertNodes(editor, placeholder);
			const [nodeEntry] = Editor.nodes(editor, {
			  match: n => n.type === 'image' && n.placeholderId === placeholder.placeholderId,
			});
			if (nodeEntry) {
			  placeholderPaths.push(nodeEntry[1]);
			} else {
			  console.warn(`Failed to find placeholder ${placeholder.placeholderId} after insertion`);
			}
		  });
		  console.log(`Inserted ${placeholderPaths.length} placeholders at paths:`, placeholderPaths);
	  
		  // Upload images and get original dimensions
		  const uploadPromises = imageFiles.map(async (file) => {
			const url = await uploadImage(file);
			return new Promise((resolve) => {
			  const img = new Image();
			  img.onload = () => {
				resolve({
				  url,
				  originalWidth: img.naturalWidth,
				  originalHeight: img.naturalHeight,
				});
			  };
			  img.src = url;
			});
		  });
		  const imageData = await Promise.all(uploadPromises);
		  console.log('Uploaded images with dimensions:', imageData);
	  
		  // Update placeholders with URLs and original dimensions
		  if (placeholderPaths.length === imageData.length) {
			setTimeout(() => {
			  placeholderPaths.forEach((path, index) => {
				const { url, originalWidth, originalHeight } = imageData[index];
				Transforms.setNodes(
				  editor,
				  {
					url,
					placeholderId: undefined,
					//If the image is huge (greater than 1200, make it small.)
					width: (originalWidth>1200 ? '300px' : `${originalWidth}px`), // Initial display width
					height: (originalWidth>1200 ? `${(300 / originalWidth) * originalHeight}px` : `${originalWidth}px`),
					originalWidth: `${originalWidth}px`, // Store original dimensions
					originalHeight: `${originalHeight}px`,
				  },
				  { at: path }
				);
				console.log(`Updated placeholder at path ${JSON.stringify(path)} with URL: ${url}`);
			  });
	  
			  const remainingPlaceholders = Array.from(
				Editor.nodes(editor, {
				  match: n => n.type === 'image' && n.url === 'https://deluxepcsimages.s3.amazonaws.com/flextemplates/static/gg.gif',
				})
			  );
			  if (remainingPlaceholders.length > 0) {
				console.warn(`Found ${remainingPlaceholders.length} unupdated placeholders`);
			  }
			}, 50);
		  } else {
			console.warn(`Mismatch: Found ${placeholderPaths.length} placeholders, expected ${imageData.length}`);
		  }
		} catch (error) {
		  console.error('One or more image uploads failed:', error);
		  setTimeout(() => {
			const placeholderNodes = Array.from(
			  Editor.nodes(editor, {
				match: n => n.type === 'image' && n.url === 'https://deluxepcsimages.s3.amazonaws.com/flextemplates/static/gg.gif',
			  })
			);
			placeholderNodes.forEach(([, path]) => {
			  Transforms.removeNodes(editor, { at: path });
			});
			console.log('Removed all placeholders due to error');
		  }, 0);
		}
	  };

	const handleDragOver = (event) => {
		event.preventDefault(); // Allow drop
	};



	const handleKeyDown = useCallback((event) => {
		if (event.key === 'Enter') {
		  const { selection } = editor;
		  if (selection) {
			const [match] = Editor.nodes(editor, {
			  match: n => n.type === 'list-item',
			});
	
			if (match) {
			  const [node, path] = match;
	
			  // Check if the list item is empty
			  if (SlateElement.isElement(node) && Editor.isEmpty(editor, node)) {
				event.preventDefault(); // Prevent the default behavior
	
				// Move the cursor to the next line
				Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] });
	
				// Transform the list item to a paragraph
				Transforms.setNodes(
				  editor,
				  { type: 'paragraph' },
				  { at: path }
				);
				return;
			  }
			}
		  }
		}
	  }, [editor]);
	



	const templateelements = [
		{
			Name: "HeadHTML", //Coincides with DB column
			Title: "Head HTML", //Friendly render of option
			TemplateType: "html",
			Introduction: "Optional setup html imports of css, fonts, and the creation of the document as a structured HTML page complete with the remaining elements nested with the alias ${content}.",
			menuoption:true,
		},
		{
			Name: "Header",
			Title: "Header",
			TemplateType: "editor",
			Introduction: "Potential introduction of company, feature our value-added services, or quick links.",
			menuoption:true,
		},
		{
			Name: "TitleAndText",
			Title: "Title And Text",
			TemplateType: "editor",
			Introduction: "Introduce the product with a large title and a paragraph of basic hooks based on product strengths.",
			menuoption:true,
		},
		{
			Name: "FeatureLeft1",
			Title: "Feature Pair 1",
			TemplateType: "image",
			Introduction: "Feature 1 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight1",
			Title: "Feature Right 1",
			TemplateType: "editor",
			Introduction: "Feature 1 Right Side",
			menuoption:false, //Automatically added by Feature 1
		},
		{
			Name: "FeatureLeft2",
			Title: "Feature Pair 2",
			TemplateType: "image",
			Introduction: "Feature 2 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight2",
			Title: "Feature Right 2",
			TemplateType: "editor",
			Introduction: "Feature 2 Right Side",
			menuoption:false, //Automatically added by Feature 2
		},
		{
			Name: "FeatureLeft3",
			Title: "Feature Pair 3",
			TemplateType: "image",
			Introduction: "Feature 3 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight3",
			Title: "Feature Right 3",
			TemplateType: "editor",
			Introduction: "Feature 3 Right Side",
			menuoption:false, //Automatically added by Feature 3
		},
		{
			Name: "FeatureLeft4",
			Title: "Feature Pair 4",
			TemplateType: "image",
			Introduction: "Feature 4 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight4",
			Title: "Feature Right 4",
			TemplateType: "editor",
			Introduction: "Feature 4 Right Side",
			menuoption:false, //Automatically added by Feature 4
		},
		{
			Name: "FeatureLeft5",
			Title: "Feature Pair 5",
			TemplateType: "image",
			Introduction: "Feature 5 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight5",
			Title: "Feature Right 5",
			TemplateType: "editor",
			Introduction: "Feature 5 Right Side",
			menuoption:false, //Automatically added by Feature 5
		},
		{
			Name: "FeatureLeft6",
			Title: "Feature Pair 6",
			TemplateType: "image",
			Introduction: "Feature 6 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight6",
			Title: "Feature Right 6",
			TemplateType: "editor",
			Introduction: "Feature 6 Right Side",
			menuoption:false, //Automatically added by Feature 6
		},
		{
			Name: "FeatureLeft7",
			Title: "Feature Pair 7",
			TemplateType: "image",
			Introduction: "Feature 7 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight7",
			Title: "Feature Right 7",
			TemplateType: "editor",
			Introduction: "Feature 7 Right Side",
			menuoption:false, //Automatically added by Feature 7
		},
		{
			Name: "FeatureLeft8",
			Title: "Feature Pair 8",
			TemplateType: "image",
			Introduction: "Feature 8 Left Side",
			menuoption:true,
		},
		{
			Name: "FeatureRight8",
			Title: "Feature Right 8",
			TemplateType: "editor",
			Introduction: "Feature 8 Right Side",
			menuoption:false, //Automatically added by Feature 8
		},
		
	];

	const EditorContainer = (templateelement) => {

	}

	const GetElementWidth = (templateelement) => {
		var width = 100;
		if (templateelement.Name.includes("Left")){
			var templateindex = templateelement.Name.slice(-1);
			console.log("Index: "+templateindex);
			console.log("About to get: "+"FeatureLeftWidth"+templateindex);
			width = localstate.itemdata["FeatureLeftWidth"+templateindex];
			
		}
		if (templateelement.Name.includes("Right")){
			var templateindex = templateelement.Name.slice(-1);
			console.log("Index: "+templateindex);
			console.log("About to get: "+"FeatureLeftWidth"+templateindex);
			width = 100 - parseInt(localstate.itemdata["FeatureLeftWidth"+templateindex]);
		}
		console.log(width);

		return {
			width:width+"%", minHeight:"20px", display:"inline-block", verticalAlign:"top"
		}
	}

	const ChangeHTML = (event) => { 
		localstate.itemdata.HeadHTML = event.target.value;
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
	}


	const SlateEditor = (props) => {
		return (
			<>
				{(slatevalue.length > 0 || props.editor === currenteditor.Name) &&
				<>
					<Slate
						editor={editor}
						value={slatevalue}
						initialValue={slatevalue}
						onChange={value => {
							onChangeSlateValue(value);
						}}
					>
						{(props.toolbar === "full") &&
							<FullToolbar editor={editor} templateelement={props.templateelement}></FullToolbar>
						}
						{/* Wrap the editor contents in a container that takes care of the outer border */}
						<div style={{borderLeft:"1px solid #CCC", borderRight:"1px solid #CCC"}}>
						
							<Editable
								renderElement={renderElementWithPath}
								renderLeaf={renderLeaf}
								onDrop={handleDrop}
								onDragOver={handleDragOver}
								onKeyDown={handleKeyDown}
								placeholder="Enter some text..."
								className="slate-editable"
							/>
						</div>
					</Slate>
					<div style={{border:"1px solid #CCC", backgroundColor:"#EEE", textAlign:"left", paddingLeft:"5px"}}><b>Now editing {props.templateelement.Title}</b>: {props.templateelement.Introduction}</div>
					</>
				}
			</>
		)
	}




	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */
	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>

			<div style={{ padding: "8px", textAlign: "center", margin: "auto", marginBottom: "100px", overflow: "auto", maxWidth: "1200px", paddingBottom: "200px" }} ref={printRef}>

				{/* Standard Page Header with right floated error message space */}
				<div style={{ position: "relative", paddingTop: "5px", paddingBottom:"25px", minWidth: "750px" }}>
					<div style={{ textAlign: "center" }}>
						<h2>Listing Builder</h2>
					</div>
					<Box sx={{ displayPrint: 'none', height: "26px", display: "block" }}>
						<NewErrorMessage />
					</Box>
					<Button
						className={(userPerms.updateDemoData === 1 && !localstate.auditview) ? classes.bluebtn : classes.hidden}
						color="primary" variant="contained"
						onClick={() => SaveChanges()}
						ref={el => btnSave.current = el}>
						<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
					</Button>
					<Button
						className={(userPerms.updateDemoData === 1 || userPerms.createDemoData === 1) && !localstate.auditview ? classes.bluebtn : classes.hidden}
						color="primary" variant="contained"
						style={{ display: "none" }}
						onClick={() => SaveChanges()}
						ref={el => btnPendingSave.current = el}>
						<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
					</Button>


					{/* New View Options Menu */}
					{/* Compact a lot of table view options into this one menu to save space! */}
					<Button
						ref={el => btnAddElement.current = el}
						className={classes.bluebtn}
						color="primary" variant="contained"
						aria-haspopup="true"
						onClick={ShowAddElementMenu}>
						Add Element
					</Button>

					<Menu
						className={classes.bluebtn}
						color="primary"
						id="view-options-menu"
						anchorEl={showAddElementMenu}
						keepMounted
						open={Boolean(showAddElementMenu)}
						onClose={CloseAddElementMenu}
					>

						{templateelements.map((templateelement, templateindex)=>{
							if (!localstate.itemdata[templateelement.Name] && templateelement.menuoption){
								return (
								<MenuItem onClick={() => AddElement(templateelement.Name)}>{templateelement.Title}</MenuItem>
								);
							}
						})}
					</Menu>

				</div>


				<Typography variant="h6">
					<div style={{ verticalAlign: "bottom" }}>
						<div style={{ display: "inline-block", verticalAlign: "bottom" }}>
							Template Name:
						</div>
						<div style={{ display: "inline-block", textAlign: "left", verticalAlign: "bottom", padding: "0px 5px" }}>
							<DBAutoComplete
								keyedby="ID"
								searchtype="listingtemplates"
								searchkey="Name"
								//itemindex={index}
								inputValue={localstate.itemdata.Name ? localstate.itemdata.Name : ""}
								defaultsearchterm={localstate.itemdata.Name ? localstate.itemdata.Name : ""}
								limit="100"
								disabled={(userPerms.readListingTemplate === 1 ? false : true)}
								errors={errors}
								allowadd={true}
								freeSolo={false}
								onDBAutoCompleteChange={onDBAutoCompleteChange}
							/>
						</div>
					</div>
				</Typography>

				<br></br>

				{/* ################################### ELEMENT BLOCKS ###################################  */}


				{/*
					1. Head (Head \ HTML) - Written HTML to help load fonts, css, create a container with a simple logo header, 
					and a place in which to insert our remaining content. We will let the user know they can insert the remaining 
					elements inside of ${content}. This is the ONLY element we aren't using our Quill Editor. We might toy with a 
					table generating mini-app for specifications?
					2. Header - Potential introduction of company, feature our value-added services, or quick links.
					3. TitleAndText - Large title of the product, text below with the most useful hooks.
					4. .... Etc.
				*/}


				{(localstate.templateloaded || localstate.itemdata.PendingItem === 1) &&
					<div style={{ position: "relative" }} className={classes.isolatedcontainer}>

						{templateelements.map((templateelement, index) => {
							if (localstate.itemdata[templateelement.Name] !== null || currenteditor.Name===templateelement.Name) {
								return (
									<div style={GetElementWidth(templateelement)}>
										{/* Handle 'editor' type of template elements */}
										{(templateelement.TemplateType === "html") &&
											<>
												<TextareaAutosize
													defaultValue={localstate.itemdata[templateelement.Name]}
													onChange={(event)=>ChangeHTML(event)}
													aria-label="Head \ HTML"
													placeholder="Enter markup here"
													style={{ width: "100%" }}
												/>
												
											</>
										}
										{(templateelement.TemplateType === "editor") &&
											<>
												{(currenteditor.Name === templateelement.Name) &&
													<SlateEditor editor={templateelement.Name}
														templateelement={templateelement}
														toolbar="full"
													/>
												}

												{/* Overlay a div on top of the static content for clickable */}
												{(currenteditor.Name !== templateelement.Name && localstate.itemdata[templateelement.Name] !== null) &&
													<div style={{ position: "relative" }}>
														<div className={classes.listingbuildersection} onClick={() => {
															if (currenteditor.Name !== templateelement.Name) {
																setCurrentEditor({
																	Name: templateelement.Name
																});
															}
														}}>
															<div>{templateelement.Title} Section<br></br>
																<span style={{ fontSize: "15px" }}>{templateelement.Introduction}</span>
															</div>
														</div>
														<div className={classes.isolatedcontainer} dangerouslySetInnerHTML={{ __html: localstate.itemdata[templateelement.Name + "HTML"] }} />
													</div>
												}
											</>
										}

										{/* Handle 'image' types of template elements */}
										{(templateelement.TemplateType === "image") &&
											<div style={{ border: "1px solid #CCC", minHeight: "100px", display: "inline-block", verticalAlign: "top", position: "relative", width:"100%" }}>
												<div style={{ position: "absolute", top: "10px", right: "10px" }}>
													<IconButton onClick={() => deleteElement(templateelement)} color="inherit" sx={{
														backgroundColor: "#FFF", '&:hover': {
															backgroundColor: "rgba(255, 255, 255, 0.8)",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
															transform: "scale(1.1)"
														},
														transition: "all 0.3s ease"
													}}>
														<DeleteIcon />
													</IconButton>
												</div>

												<div style={{ textAlign: "center", padding: "10px" }}>
													<ImageUpload templateelement={templateelement} />
												</div>
											</div>
										}


									</div>
								)
							}
						})}


					
					</div>
				}
			</div>

		</LocalizationProvider >
	)
}

export default ListingBuilder;
