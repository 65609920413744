//Don't forget to add this to your reducer (store.js)

import { createSlice } from '@reduxjs/toolkit'

export const progressbarSlice = createSlice({
  name: 'progressbar',
  initialState: {
	progressstate:{
		msg: 'Default Current Progress',
		show: false,
		settimeout:false,
		timeout: 2,
		type: 'items', //Labels your save types such as items, products, skus, parts
		finished:0,
		percent:"0%",
		total: 100,
		pass: 0,
		fail: 0,
		faillist:[], //Can take simple values,
		faillinks:[], //Can take links to things like items or products.
		timeoutid:false,
		errors:[],
	}
  },
  reducers: {
	newProgress: (state, action) =>{
		console.log(action);
		state.progressstate=action.payload;
	},
    incrementPass: (state) => {
      state.progressstate.pass += 1;
	  state.progressstate.finished += 1;
	  //Causes visual glitch, anything past 100%. I wouldn't anticipate too many bugs if we're carefully controlling state.
	  if (state.progressstate.pass<=state.progressstate.finished){
		state.progressstate.percent = parseInt((((state.progressstate.finished)/state.progressstate.total)*100),10).toString()+"%";
	  }
    },
    incrementFail: (state, action) => {
		console.log(action.payload);
      state.progressstate.fail += 1;
	  state.progressstate.finished += 1;
	  if (state.progressstate.pass<=state.progressstate.finished){
		state.progressstate.percent = parseInt((((state.progressstate.finished)/state.progressstate.total)*100),10).toString()+"%";
	  }
	  //state.progressstate.errors.push(action.payload);
	  state.progressstate.errors = [...state.progressstate.errors, action.payload];
    },
	progressTimeout: (state) => {
		state.progressstate.show = false;
	}
    
  },
})

export const { incrementPass, incrementFail, newProgress, progressTimeout } = progressbarSlice.actions

export const setProgressTimeout = (timeout) => (dispatch) => {
	//console.log("THIS");
	console.log("Timeout: "+timeout);
	setTimeout(() => {
		dispatch(progressTimeout())
	}, timeout*1000)
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = (state) => state.counter.value
export const selectProgressState = (state) => state.progressbar;

export default progressbarSlice.reducer
