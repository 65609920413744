////React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';

//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';



//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}



const SpecAliasTable = (props) => {
	document.title = "Spec Alias";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Hardware Agent"));
	dispatch(setCurrentMenuItem("/specalias"));
	const rowRefs = useRef([]);
	

	/* ##########################  UseState Variables  ########################## */
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws and error.
		order: 'asc',
		orderby: 'RawValue',
		selectedcount: 0,
		rowsperpage: 100,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				searchpair1: { type: "RawValue", "value": "", "mode": "like" },
				searchpair2: { type: "", "value": "", "mode": "like" },
				searchpair3: { type: "", "value": "", "mode": "like" },
				searchpair4: { type: "", "value": "", "mode": "like" }
			}
		}
	});


	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Top Button Refs and Functions  ########################## */	 
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnDeleteSelected = useRef();
	

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
	}




	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		RawValue: true,
		AliasName: true,
		AliasType: true,
		ProductID: true,
		ComponentID: true,
		Propagate: true,
		created_at: true
	})

	const UpdateColState = (newcolstate) => {
		setColState(newcolstate);
	}




	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}
















	const [showmenuEl, setMenuEl] = useState(null);

	


	/* ##########################  Reusable Menu Method  ########################## */
	const ShowMenu = (event) => {
		setMenuEl(event.currentTarget);
	}
	const CloseMenu = () => {
		setMenuEl(null);
	}



	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index, row) => {
		if (row.isSelected) {
			handleSelectSingle(index, false);
		} else {
			handleSelectSingle(index, true);
		}
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			for (var i = 0; i < localstate.griditems.length; i++) { localstate.griditems[i].isSelected = true; }
			localstate.selectedindexes = localstate.griditems.map((n, index) => index);
			localstate.selectedcount = localstate.griditems.length;
			UpdateState(localstate);
		} else {
			for (var i = 0; i < localstate.griditems.length; i++) { localstate.griditems[i].isSelected = false; }
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			UpdateState(localstate);
		}
	};

	//Handling checkboxes is very different from text. We should re-render after each row checked.
	const handleSelectSingle = (index, selectbool) => {
		if (selectbool) {
			localstate.griditems[index].isSelected = true;
			localstate.selectedindexes.push(index);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		} else {
			localstate.griditems[index].isSelected = false;
			localstate.selectedindexes.splice(index, 1);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		}
	}




	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Pre-set Key
	const onChangeSearch = debounce(function (searchtype, searchvalue, itembool, searchpair) {
		//If an item is selected, then deselected, itembool is still true, but the searchvalue becomes null.
		//If item selected from list (itembool), grab it's key's value instead 'name'
		if (itembool) {
			if (searchvalue !== null) {
				searchvalue = searchvalue.name;
			} else {
				searchvalue = '';
			}
		}
		//New API:
		console.log(searchtype);
		console.log(searchvalue);
		switch (searchpair) {
			case 'searchpair1':
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case 'searchpair2':
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case 'searchpair3':
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case 'searchpair4':
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 800);


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode = "";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		switch (searchnumber) {
			case "search1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.mode = mode;
				break;
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.mode = mode;
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.mode = mode;
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.mode = mode;
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair5.mode = mode;
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair6.mode = mode;
				break;
			default:
				break;
		}

		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setMenuEl(null);
		}
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function (searchvalue, search) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		switch (search) {
			case "search1":
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.value = searchvalue;
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 600);

	//Key-Value Inputs
	const [showsearch1, setShowSearch1] = useState(true);
	const [showsearch2, setShowSearch2] = useState(false);
	const [showsearch3, setShowSearch3] = useState(false);
	const [showsearch4, setShowSearch4] = useState(false);
	const [lastsearch, setLastSearch] = useState('showsearch1');


	const AddSearch = () => {
		switch (lastsearch) {
			case 'showsearch1':
				setShowSearch2(true);
				setLastSearch('showsearch2');
				break;
			case 'showsearch2':
				setShowSearch3(true);
				setLastSearch('showsearch3');
				break;
			case 'showsearch3':
				setShowSearch4(true);
				setLastSearch('showsearch3');
				break;
			default:
				break;
		}
	}

	const RemoveSearch = (searchinput) => {
		switch (searchinput) {
			case 'search2':
				localstate.searchoptions.searchpairs.searchpair2.value = "";
				localstate.dbreload = true;
				setLastSearch('showsearch1');
				setShowSearch2(false);
				UpdateState(localstate);
				break;
			case 'search3':
				localstate.searchoptions.searchpairs.searchpair3.value = "";
				localstate.dbreload = true;
				setLastSearch('showsearch2');
				setShowSearch3(false);
				UpdateState(localstate);
				break;
			case 'search4':
				localstate.searchoptions.searchpairs.searchpair4.value = "";
				localstate.dbreload = true;
				setLastSearch('showsearch3');
				setShowSearch4(false);
				UpdateState(localstate);
				break;
			default:
				break;
		}
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	let history = useHistory();
	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		localstate.selectedindexes = [];
		localstate.selectedcount = 0;
		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: localstate.searchoptions.searchpairs
			}
		};
		var postoptions = {
			withCredentials: true,
			withXSRFToken: true,
			crossDomain: true,
			mode: 'no-cors',
			timeout: 11800,
		};

		axios.post(dbendpoint + "/specs/getspecaliases?page=" + (localstate.page + 1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status == 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status == 'Success') {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i = 0; i < res.data.pagedata.data.length; i++) {
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
						res.data.pagedata.data[i].ExpandRow = false;
					}
					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status === 'Failure') {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}


	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Spec Aliases";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);

	useEffect(() => {
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);




	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			ID: uuidv4(),
			PendingItem: true,
			RawValue: '',
			AliasName: '',
			AliasType: '',
			ProductID: 0,
			ComponentID: 0
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}
	
	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				if (localstate.griditems[i].RawValue === '') {
					errors.NewError({ errmsg: "Raw Value cannot be a blank string!", errshow: true, errtimeout: 5, errtype: 'warning' })
					return;
				} else {
					updatearray.push(localstate.griditems[i]);
				}
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				var postoptions = {
					withCredentials: true,
					withXSRFToken: true,
					crossDomain: true,
					mode: 'no-cors',
					timeout: 11800,
				};
				axios.post(dbendpoint + "/specs/updatespecalias", postdata, postoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status == 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === 'login') {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!

							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}

	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}




	const [showconfirmation, setShowConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowConfirmation(true);
	}
	const DeleteSelected = () => {
		var finishedrequests = 0;
		var postoptions = {
			withCredentials: true,
			withXSRFToken: true,
			crossDomain: true,
			mode: 'no-cors',
			timeout: 11800,
		};

		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty('PendingItem')) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/specs/deletespecalias", postdata, postoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status == 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === 'login') {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === 'Success') {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
								console.log(finishedrequests);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: 'warning' })
							}
						}
						if (res.data.Status === 'Failure') {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */

	const taborder = ["RawValue", "AliasName", "AliasType", "ProductID", "ComponentID"];
	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		// //Handle Right Arrow
		// if (event.key === "ArrowRight") {
		// 	event.preventDefault();
		// 	//Same Process as Tab
		// 	var tabindex = taborder.indexOf(column);
		// 	for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
		// 		if (i === taborder.length) {
		// 			i = -1;
		// 			if (rowRefs.current[(index + 1) + column]) {
		// 				index = index + 1;
		// 			} else {
		// 				index = 0;
		// 			}
		// 		} else {
		// 			if (taborder[i]) {
		// 				if (colstate[taborder[i]]) {
		// 					rowRefs.current[index + taborder[i]].select();
		// 					break;
		// 				}
		// 			} else {
		// 				i = -1;
		// 			}
		// 		}
		// 	}
		// }
		// //Handle Left Arrow
		// if (event.key === "ArrowLeft") {
		// 	event.preventDefault();
		// 	var tabindex = taborder.indexOf(column);
		// 	var i;
		// 	var rowincrement = 0;
		// 	for (i = (tabindex - 1); i > -2; i--) {
		// 		if (i === -1) {
		// 			rowincrement = -1;
		// 			i = taborder.length; //Go to end of tab order
		// 		} else {
		// 			if (colstate[taborder[i]]) {
		// 				break;
		// 			}
		// 		}
		// 	}
		// 	//If tabindex first and we're in the first row, go to the last element on last row.
		// 	if (tabindex === 0 && index === 0) {
		// 		var lastindex = localstate.griditems.length - 1;
		// 		rowRefs.current[lastindex + taborder[i]].select();
		// 	} else {
		// 		rowRefs.current[(index + rowincrement) + taborder[i]].select();
		// 	}
		// }
	}

	//Useful for capturing Tabs\Enters on inputs
	const DetectTab = (event, index, column) => {
		if (event.key == "Tab") {
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index + 1) + column]) {
				if (column == "Checkbox") {
					console.log("Checkbox!" + (index + 1));
					rowRefs.current[(index + 1) + column].focus();
				} else {
					rowRefs.current[(index + 1) + column].select();
				}
			} else {
				rowRefs.current[('0' + column)].focus();
			}
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		if (event.key !== "Tab") {
			rowRefs.current[index + column].value = event.target.value;
			localstate.griditems[index][column] = event.target.value;

			rowRefs.current[index + 'SaveStatus'].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}

	const PropagateValues = (row) => {
		console.log(row);

		if (row.AliasType === 'Checkin') {
			const postdata = {
				RawValue: row.RawValue,
				AliasName: row.AliasName,
				ID: row.ID
			};

			var postoptions = {
				withCredentials: true,
				withXSRFToken: true,
				crossDomain: true,
				mode: 'no-cors',
				timeout: 11800,
			};
			axios.post(dbendpoint + "/specs/propagatevalues", postdata, postoptions).then(res => {
				if (res.status == 200) {
					if (res.data.Status === 'login') {
						window.location.reload(false);
					}
					if (res.data.Status === 'Success') {
						var itemcount = res.data.itemcount;
						errors.NewError({ errmsg: "Items updated: " + itemcount, errshow: true, errtimeout: 8, errtype: 'ok' })
					} else {
						errors.NewError({ errmsg: "Could not propagate values.", errshow: true, errtimeout: 8, errtype: 'warning' })
					}
					if (res.data.Status === 'Failure') {
						//Failure error
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
					}
				} else {
					//Non-200 message from server.
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
				}
			});
		}
	}


	/* ##########################  TABLE HEAD  ########################## */
	//Custom Head Cells
	const headCells = [
		{ id: 'RawValue', numeric: false, label: 'Raw Value', align: 'left', allowsort: true },
		{ id: 'AliasName', numeric: true, label: 'Alias Name', align: 'right', allowsort: true },
		{ id: 'AliasType', numeric: true, label: 'Alias Type', align: 'left', allowsort: true },
		{ id: 'ProductID', numeric: true, label: 'Product ID', align: 'left', allowsort: true },
		{ id: 'ComponentID', numeric: true, label: 'Component ID', align: 'left', allowsort: true },
		{ id: 'Propagate', numeric: true, label: 'Fn', align: 'left', allowsort: false },
		{ id: 'created_at', numeric: false, label: 'Date', align: 'left', allowsort: true },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
		const createSortHandler = (property, allowsort) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: '1px solid #CCC',
					backgroundColor: '#DDD'
				}}>
					<td style={{ width: '14px', padding: "none", display: "table-cell", padding: "6px 4px 1px 4px" }}>
						<input type="checkbox"
							checked={state.selectedcount > 0 && state.selectedcount === state.griditems.length}
							onChange={onSelectAllClick}
						/>
					</td>
					{/* TRY! Conditional based on colstate */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : 'asc'}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
									>
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
										{localstate.orderby === headCell.id ? (
											<span className={classes.visuallyHidden}>

											</span>
										) : null}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};


	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */


	/* ##########################  Render Function  ########################## */
	return (
		<div style={{ padding: "8px", overflow: "auto", minWidth: "350px" }}>

			{/* Standard Page Header with right floated error message space */}
			<div style={{ minHeight: "50px", paddingTop:"5px" }}>
				<Grid container justifyContent="space-between">
					<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{padding:"5px", minWidth:"270px"}}>{(errors.currenterror.errshow) &&
						<ErrorMessage />
					}</Grid>
					<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{textAlign:"center", margin:"auto", padding:"5px"}}><h2>Spec Alias Table</h2></Grid>
					<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{padding:"5px", minWidth:"270px"}}>
						<div style={{float:"right"}}>
							<ProgressBar />
						</div>
					</Grid>
				</Grid>
			</div>


			{/* Search Tools */}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

			<div>

				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Pair 1 */}
				{(showsearch1) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
							{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
							<Select
								key={localstate.searchoptions.searchpairs.searchpair1.uuid}
								value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search1")}
							>
								<MenuItem value={"RawValue"}>Raw Value</MenuItem>
								<MenuItem value={"AliasName"}>Alias Name</MenuItem>
								<MenuItem value={"ProductID"}>Product ID</MenuItem>
								<MenuItem value={"ComponentID"}>Component ID</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search1" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search1")} />
						<div style={{ width: "30px" }}>&nbsp;</div>
					</div>
				}

				{/* Search Pair 2 */}
				{(showsearch2) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair2.uuid}
								value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search2")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"Date"}>Date</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search2" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search2")} />
						<div style={{ width: "30px", float: "right" }}>
							{(showsearch2 && !showsearch3) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search2')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 3 */}
				{(showsearch3) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair3.uuid}
								value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search3")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"Date"}>Date</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search3" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search3")} />
						<div style={{ width: "30px" }}>
							{(showsearch3 && !showsearch4) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search3')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 4 */}
				{(showsearch4) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{ minWidth: "120px" }}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair4.uuid}
								value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search4")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"Date"}>Date</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search4" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search4")} />
						<div style={{ width: "30px" }}>
							{(showsearch4) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search4')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}



				{/* On last search input, don't show! */}
				{(!showsearch4) &&
					<div style={{ display: "inline-block", paddingLeft: "15px" }}>
						<IconButton size="small" aria-label="Add Search" onClick={() => AddSearch()}>
							<AddIcon color="primary" fontSize="large" style={{ padding: "5px" }}></AddIcon>
						</IconButton>
					</div>
				}

			</div>


			{/* Save Changes & Pagination */}
			<div style={{ height: "5px" }}>&nbsp;</div>
			<div>
				{(!showconfirmation) &&
					<React.Fragment>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							ref={el => btnSave.current = el}>
							<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							style={{ display: "none" }}
							onClick={() => SaveChanges()}
							ref={el => btnPendingSave.current = el}>
							<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
						</Button>

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained" onClick={() => AddRow()}
								ref={el => btnAddRow.current = el}>
								Add Row
							</Button>
						

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained" onClick={() => DeleteSelectedInit()}
								ref={el => btnDeleteSelected.current = el}>
								Delete Selected
							</Button>


							{/* ##########################  Column Toggles  ########################## */}
							<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowColumnMenu}>
										Columns
									</Button>

									<Menu
										className={classes.bluebtn}
										color="primary"
										id="simple-menu"
										anchorEl={showcolumnmenu}
										keepMounted
										open={Boolean(showcolumnmenu)}
										onClose={CloseColumnMenu}
									>
										<MenuItem disableRipple className={classes.columnmenu}>
											<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
												<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
													<FormGroup>
														<FlexColumnOption value="RawValue" label="Raw Value"/>
														<FlexColumnOption value="AliasName" label="Alias Name"/>
														<FlexColumnOption value="AliasType" label="Alias Type"/>
														<FlexColumnOption value="ComponentID" label="Component ID"/>
														<FlexColumnOption value="ProductID" label="Product ID"/>
														<FlexColumnOption value="created_at" label="Date"/>
													</FormGroup>
												</div>
											</div>
										</MenuItem>
									</Menu>
							
					</React.Fragment>
				}

				{/* Confirmation Space */}
				{(showconfirmation) &&
					<div>
						<b>Are you sure you want to delete these items?</b>
						<div style={{ padding: "10px 0px" }}>
							{deleteitems.map((row, index) => {
								if (deleteitems.length == index + 1) {
									return (<span key={index}>{row.RawValue}</span>)
								} else {
									return (<span key={index}>{row.RawValue}, </span>)
								}
							})}
						</div>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
					</div>
				}

				{(localstate.totalitems > 0) &&
					<TablePagination
						style={{ display: "inline-flex", float: "right" }}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[100, 250, 500]}
					/>
				}
			</div>
			{/* End of Top Buttons & Pagination */}


			{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
			<div className={classes.flexgridcontainerA}>
				{/* ##########################  Start of Table  ########################## */}
				<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount}
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>

					<tbody style={{ display: "table-row-group" }}>
						{(localstate.griditems.length > 0) &&
							localstate.griditems.map((row, index) => {
								//Create all-new refs on each render. Helps avoid issues with grid states.
								rowRefs.current[index + 'Checkbox'] = React.createRef();
								rowRefs.current[index + 'SaveStatus'] = React.createRef();
								rowRefs.current[index + 'RawValue'] = React.createRef();
								rowRefs.current[index + 'AliasName'] = React.createRef();
								rowRefs.current[index + 'AliasType'] = React.createRef();
								rowRefs.current[index + 'ProductID'] = React.createRef();
								rowRefs.current[index + 'ComponentID'] = React.createRef();
								return (
									<React.Fragment key={row.ID}>
										<tr className={classes.flexgridrow}>
											{/* Checkbox - Requires inner div to change background color with SaveStatus */}
											<td>
												<div ref={el => rowRefs.current[index + 'SaveStatus'] = el} style={{ padding: "6px 4px 1px 4px" }}>
													<input type="checkbox"
														ref={el => rowRefs.current[index + 'Checkbox'] = el}
														checked={row.isSelected}
														onKeyDown={(event) => HandleKeyDown(event, index, 'Checkbox')}
														onChange={() => SelectRow(index, row)}
													/>
												</div>
											</td>

											{/* RawValue*/}
											{(colstate.RawValue) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + 'RawValue'] = el}
														className={classes.flexgridinput}
														style={{ minWidth: '100px' }}
														onKeyDown={(event) => HandleKeyDown(event, index, 'RawValue')}
														onKeyUp={(event) => onChangeValue(event, index, 'RawValue')}
														defaultValue={row.RawValue} />
												</td>
											}


											{/* AliasName */}
											{(colstate.AliasName) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + 'AliasName'] = el}
														className={classes.flexgridinput}
														style={{ minWidth: '50px', textAlign: "right" }}
														onKeyDown={(event) => HandleKeyDown(event, index, 'AliasName')}
														onKeyUp={(event) => onChangeValue(event, index, 'AliasName')}
														defaultValue={row.AliasName} />
												</td>
											}

											{/* AliasType */}
											{(colstate.AliasType) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + 'AliasType'] = el}
														className={classes.flexgridinput}
														style={{ minWidth: '50px' }}
														onKeyDown={(event) => HandleKeyDown(event, index, 'AliasType')}
														onKeyUp={(event) => onChangeValue(event, index, 'AliasType')}
														defaultValue={row.AliasType} />
												</td>
											}

											{/* ProductID */}
											{(colstate.ProductID) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + 'ProductID'] = el}
														className={classes.flexgridinput}
														style={{ minWidth: '50px' }}
														onKeyDown={(event) => HandleKeyDown(event, index, 'ProductID')}
														onKeyUp={(event) => onChangeValue(event, index, 'ProductID')}
														defaultValue={row.ProductID} />
												</td>
											}

											{/* ComponentID */}
											{(colstate.ComponentID) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + 'ComponentID'] = el}
														className={classes.flexgridinput}
														style={{ minWidth: '50px' }}
														onKeyDown={(event) => HandleKeyDown(event, index, 'ComponentID')}
														onKeyUp={(event) => onChangeValue(event, index, 'ComponentID')}
														defaultValue={row.ComponentID} />
												</td>
											}

											{/* Propagate */}
											{(colstate.Propagate) &&
												<td>
													<a onClick={() => PropagateValues(row)} className={classes.hoverunit}>Propagate Values</a>
												</td>
											}


											{/* Date */}
											{(colstate.created_at) &&
												<td>
													{row.created_at}
												</td>
											}

										</tr>
									</React.Fragment>

								)
							}
							)
						}
						{(localstate.griditems.length === 0) &&
							<tr className={classes.flexgridrow}><td colSpan="99"
								style={{ padding: '12px', fontSize: "18px" }}>No Results</td></tr>
						}
					</tbody>
				</table>
			</div>



			{(localstate.rowsperpage > 50 && localstate.totalitems > 50) &&
				<TablePagination
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[101, 250, 500]}
				/>
			}


		</div>
	);
}

export default SpecAliasTable;
