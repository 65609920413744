//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Search Tools.
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

const SpecsTable = (props) => {
	document.title="Computer Specs";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Hardware Agent"));
	dispatch(setCurrentMenuItem("/specs"));

	const rowRefs = useRef([]);
	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */	  
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws and error.
		order:'desc',
		orderby:'created_at',
		selectedcount:0,
		rowsperpage:50,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				searchpair1:{type:"SerialNumber", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"Model", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"CPU", value: "", mode:"like", uuid:uuidv4()},
				searchpair4:{type:"TotalRAM", value: "", mode:"LIKE", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()}
			}
		}
	});


	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		SerialNumber:true,
		Vendor: false,
		Model:true,
		CPU:true,
		TotalRAM:true,
		HDD1Size:true,
		HDD2Size:false,
		SpecStep:true,
		ClientIP:true,
		created_at:true,
		
	})

	const UpdateColState = (newcolstate) =>{
		setColState(newcolstate);
	}

	const [showmenuEl, setMenuEl] = useState(null);

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	
	function UpdateState(stateobject) {
		setState(stateobject);
	}




	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}


	/* ##########################  Post Test  ########################## */
	//Useful for making test API calls.
	
	const TestPost = () =>{
		const postdata = {
			specs:{
				BootID:uuidv4()
			}
		};
		var postoptions = {
			withCredentials:false,
			withXSRFToken: true,
			crossDomain:true,
			mode:'no-cors',
			timeout:11800,
		};
		axios.post(dbendpoint+"/postspecs?page="+(localstate.page+1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status==200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status=='Success'){
					console.log(res);
				}
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}
	




	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index, row) => {
		if (row.isSelected){
			handleSelectSingle(index, false);
		} else {
			handleSelectSingle(index, true);
		}
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			for (var i=0; i<localstate.griditems.length; i++){localstate.griditems[i].isSelected = true;}
			localstate.selectedindexes = localstate.griditems.map((n, index) => index);
			localstate.selectedcount = localstate.griditems.length;
			UpdateState(localstate);
		} else {
			for (var i=0; i<localstate.griditems.length; i++){localstate.griditems[i].isSelected = false;}
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			UpdateState(localstate);
		}
	};

	//Handling checkboxes is very different from text. We should re-render after each row checked.
	const handleSelectSingle = (index, selectbool) => {
		if (selectbool){
			localstate.griditems[index].isSelected = true;
			localstate.selectedindexes.push(index);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		} else {
			localstate.griditems[index].isSelected = false;
			localstate.selectedindexes.splice(index,1);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		}
	}




	/* ##########################  Search Options  ########################## */



	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";

		localstate.searchoptions.searchpairs["searchpair"+searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].mode = mode;        
 
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, search) {
		switch (search) {
			case "search1":
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.value = searchvalue;
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload = true;
		UpdateState(localstate);
	},600);

	//Key-Value Inputs
	const showsearch1 = true;
	const [showsearch2, setShowSearch2] = useState(true);
	const [showsearch3, setShowSearch3] = useState(true);
	const [showsearch4, setShowSearch4] = useState(true);
	const [showsearch5, setShowSearch5] = useState(false);
	const [showsearch6, setShowSearch6] = useState(false);
	const [lastsearch, setLastSearch] = useState("showsearch4");


	const AddSearch = () => {
		switch (lastsearch){
			case "showsearch1":
				setShowSearch2(true);
				setLastSearch("showsearch2");
				break;
			case "showsearch2":
				setShowSearch3(true);
				setLastSearch("showsearch3");
				break;
			case "showsearch3":
				setShowSearch4(true);
				setLastSearch("showsearch4");
				break;
			case "showsearch4":
				setShowSearch5(true);
				setLastSearch("showsearch5");
				break;
			case "showsearch5":
				setShowSearch6(true);
				setLastSearch("showsearch6");
				break;
			default:
				break;
		}
	}

	const RemoveSearch = (searchinput) => {
		switch (searchinput){
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch1");
				setShowSearch2(false);
				UpdateState(localstate);
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch2");
				setShowSearch3(false);
				UpdateState(localstate);
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch3");
				setShowSearch4(false);
				UpdateState(localstate);
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch4");
				setShowSearch5(false);
				UpdateState(localstate);
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch5");
				setShowSearch6(false);
				UpdateState(localstate);
				break;
			default:
				break;
		}
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		//This evaluates if we're requesting the same property and if it's asc.
		const isAsc = localstate.orderby === property && localstate.order === "asc";	
		//We need to evaluate if we should start back at page 1:
		if (localstate.orderby !== property){
			localstate.page = 0;
		}
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		localstate.selectedindexes = [];
		localstate.selectedcount=0;
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs
			}
		};
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:"no-cors",
			timeout:11800,
		};
		
		axios.post(dbendpoint+"/specs/getspecs?page="+(localstate.page+1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i =0; i<res.data.pagedata.data.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						res.data.pagedata.data[i].GridKey=i;
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
						res.data.pagedata.data[i].ExpandRow = false;

						
						//Helper for Mobile vs Desktop
						res.data.pagedata.data[i].isMobile = false;
						res.data.pagedata.data[i].isDesktop = false;

						
						switch (res.data.pagedata.data[i].SystemType){
							case '1':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case '2':
								res.data.pagedata.data[i].isMobile = true;
								break;
							case '3':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case '4':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case '5':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case '6':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case '7':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case 'desktop':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case 'laptop':
								res.data.pagedata.data[i].isMobile = true;
								break;
							case 'convertible':
								res.data.pagedata.data[i].isMobile = true;
								break;
							case 'low-profile':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case 'mini-tower':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							case 'notebook':
								res.data.pagedata.data[i].isMobile = true;
								break;
							case 'space-saving':
								res.data.pagedata.data[i].isDesktop = true;
								break;
							default:
								break;
						}
					}


					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	useEffect(() => {
		document.title="Specifications";
		if (state.dbreload){
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			id:uuidv4(),
			Name:'',
			PendingItem:true,
			Cost:0.00,
			Price:0.00,
			Margin:0.00
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	



	/* ##########################  Cell Interaction  ########################## */

	const onChangeName = (event, index) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+"Name"]){
				rowRefs.current[(index+1)+"Name"].focus();
			} else {
				rowRefs.current[("0Name")].focus();
			}
		} else {
			//Update ref
			rowRefs.current[index+"Name"].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].Name = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	//Useful for capturing Tabs\Enters on inputs
	const DetectTab = (event, index, column) =>{
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				if (column=="Checkbox"){
					console.log("Checkbox!"+(index+1));
					rowRefs.current[(index+1)+column].focus();
				} else {
					rowRefs.current[(index+1)+column].select();
				}
			} else {
				rowRefs.current[('0'+column)].focus();
			}
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		if (event.key !== "Tab"){
			//Conditionals for Types:
			if (column=='Cost'){
				//If Cost changes, so does Margin
				rowRefs.current[index+'Margin'].textContent = (rowRefs.current[index+'Price'].value - event.target.value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+'Margin'].textContent;
			}
			if (column=='Price'){
				//If Price changes, so does Margin
				rowRefs.current[index+'Margin'].textContent = (event.target.value - rowRefs.current[index+'Cost'].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+'Margin'].textContent;
			}
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+'SaveStatus'].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.classList.add(['MuiButton-containedPrimary']);
		}
	}

	//Useful for changes to related items: (cost vs margin)
	const onChangeCost = (event, index, column) => {
		if (event.key == "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[('0'+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+'Margin'].value = (rowRefs.current[index+'Price'].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+'SaveStatus'].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.classList.add(['MuiButton-containedPrimary']);
		}
	}

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	/* ##########################  TABLE HEAD  ########################## */
	//Custom Head Cells
	const headCells = [
		{ id: 'SerialNumber', numeric: false, label: 'Serial Number', align:'left', allowsort:true, show:true},
		{ id: 'Model', numeric: true, label: 'Model', align:'left', allowsort:true, show:true},
		{ id: 'CPU', numeric: true, label: 'CPU', align:'left', allowsort:false, show:true},
		{ id: 'TotalRAM', numeric: true, label: 'RAM', align:'left', allowsort:true, show:true},
		{ id: 'HDD1Size', numeric: false, label: 'HDD1',  align:'left', allowsort:true, show:true},
		{ id: 'HDD2Size', numeric: false, label: 'HDD2',  align:'left', allowsort:true, show:true},
		{ id: 'SpecStep', numeric: false, label: 'Spec Step',  align:'left', allowsort:true, show:true},
		{ id: 'ClientIP', numeric: false, label: 'Client IP',  align:'left', allowsort:true, show:true},
		{ id: 'created_at', numeric: false, label: 'Date',  align:'left', allowsort:true, show:true},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
		const createSortHandler = (property, allowsort) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:'1px solid #CCC',
							backgroundColor:'#DDD'}}>
					<td style={{width: '14px', padding:"none", display:"table-cell", padding:"6px 4px 2px 4px"}}>
						<input type="checkbox"  
							checked={state.selectedcount > 0 && state.selectedcount === state.griditems.length}
							onChange={onSelectAllClick}
						/>
					</td>
						{/* TRY! Conditional based on colstate */}
					{headCells.map((headCell) => 
					colstate[headCell.id] &&
					( 	
						<td
								key={headCell.id}
								align={headCell.align}				
							>
								{(headCell.allowsort) && 
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : 'asc'}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
									>
										{(headCell.id==="Serial Number") &&
											<div style={{width:"30px", display:"inline-block"}}></div>
										}
										{(localstate.orderby === headCell.id) 
											? <span style={{fontWeight:"bold"}}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) && 
									<span>{headCell.label}</span>
								}
							</td>
					))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};
	

	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/


	/* ##########################  Render Function  ########################## */
	return (
        <div style={{padding:"8px"}}>
			{/* Standard Page Header with right floated error message space */ }
			<div style={{height:"35px"}}>
				<div style={{textAlign:'center'}}>
					<h2>Specs Table</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{position:"relative", float:"right", bottom:"26px", height:"25px", fontSize:"12px"}}>
						<ErrorMessage />
					</div>
				}
			</div>

			{/* /* ##########################  Search Inputs  ########################## */	}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}
			
			<div>

				{/* Key-Value Searches */}


				{/* Search Pair 1 */}
				{(showsearch1) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
							<Select
								key={localstate.searchoptions.searchpairs.searchpair1.uuid}
								value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "1")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>l
							</Select>
						</FormControl>
						<TextField id="search1" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair1.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search1")} />
						<div style={{ width: "30px" }}>&nbsp;</div>
					</div>
				}

				{/* Search Pair 2 */}
				{(showsearch2) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair2.uuid}
								value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "2")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search2" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair2.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search2")} />
						<div style={{ width: "30px", float:"right" }}>
							{(showsearch2 && !showsearch3) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search2')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 3 */}
				{(showsearch3) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair3.uuid}
								value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "3")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search3" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair3.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search3")} />
						<div style={{ width: "30px" }}>
							{(showsearch3 && !showsearch4) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search3')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 4 */}
				{(showsearch4) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair4.uuid}
								value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "4")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search4" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair4.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search4")} />
						<div style={{ width: "30px" }}>
							{(showsearch4 && !showsearch5) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search4')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* Search Pair 5 */}
				{(showsearch5) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair5.uuid}
								value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "5")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search5" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair5.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search5")} />
						<div style={{ width: "30px" }}>
							{(showsearch5 && !showsearch6) &&
								<IconButton className={"transparenticon"} size="small" aria-label="delete" onClick={() => RemoveSearch("search5")}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 6 */}
				{(showsearch6) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair6.uuid}
								value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "6")}
							>
								<MenuItem value={"SerialNumber"}>Serial Number</MenuItem>
								<MenuItem value={"Model"}>Model</MenuItem>
								<MenuItem value={"CPU"}>CPU</MenuItem>
								<MenuItem value={"TotalRAM"}>RAM</MenuItem>
								<MenuItem value={"HDD1Size"}>HDD1 Size</MenuItem>
								<MenuItem value={"HDD2Size"}>HDD2 Size</MenuItem>
								<MenuItem value={"SpecStep"}>Spec Step</MenuItem>
								<MenuItem value={"ClientIP"}>Client IP</MenuItem>
								<MenuItem value={"SystemType"}>System Type</MenuItem>
								<MenuItem value={"RAM1Capacity"}>RAM 1 Capacity</MenuItem>
								<MenuItem value={"RAM2Capacity"}>RAM 2 Capacity</MenuItem>
								<MenuItem value={"RAM3Capacity"}>RAM 3 Capacity</MenuItem>
								<MenuItem value={"RAM4Capacity"}>RAM 4 Capacity</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search6" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair6.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search6")} />
						<div style={{ width: "30px" }}>
							{(showsearch6) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search6')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* On last search input, don't show! */}
				{(!showsearch6) &&
					<div style={{display:"inline-block", paddingLeft:"15px"}}>
						<IconButton size="small" aria-label="Add Search" onClick={()=>AddSearch()}>
							<AddIcon color="primary" fontSize="large" style={{padding:"5px"}}></AddIcon>
						</IconButton>
					</div>
				}

			</div>


			{/* Save Changes & Pagination */}
			<div style={{height:"5px"}}>&nbsp;</div>
			<div>
				

				{/* ##########################  Column Toggles  ########################## */}
				<Button
					className={classes.bluebtn}
					color="primary" variant="contained"
					aria-haspopup="true"
					onClick={ShowColumnMenu}>
					Columns
				</Button>

				<Menu
					className={classes.bluebtn}
					color="primary"
					id="simple-menu"
					anchorEl={showcolumnmenu}
					keepMounted
					open={Boolean(showcolumnmenu)}
					onClose={CloseColumnMenu}
				>
					<MenuItem disableRipple className={classes.columnmenu}>
						<div style={{ verticalAlign: "top" }}> {/* Optional container for 2 column menu! */}
							<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
								<FormGroup>
									<FlexColumnOption value="SerialNumber" label="Serial Number" />
									<FlexColumnOption value="Model" label="Model" />
									<FlexColumnOption value="CPU" label="CPU" />
									<FlexColumnOption value="RAM" label="RAM" />
									<FlexColumnOption value="HDD1Size" label="HDD  1Size" />
									<FlexColumnOption value="HDD2Size" label="HDD 2 Size" />
									<FlexColumnOption value="created_at" label="created_at" />
								</FormGroup>
							</div>
						</div>
					</MenuItem>
				</Menu>
				
									
					<Button
						className={classes.bluebtn}
						color="primary" variant="contained" onClick={() => ExpandAll()}>
						Expand All
					</Button>



				{(localstate.totalitems>0) &&
					<TablePagination className={classes.paginationalign}
						style={{display:"inline-flex", float:"right"}}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[50,100,500]}
					/>
				}
			</div>
			{/* End of Top Buttons & Pagination */}


			{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
			<div className={classes.flexgridcontainerA}>
				{/* ##########################  Start of Table  ########################## */}
				<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount} 
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>

					<tbody style={{display:"table-row-group"}}>
						{(localstate.griditems.length>0) &&	
							localstate.griditems.map((row, index) => { 
								//Create all-new refs on each render. Helps avoid issues with grid states.
								rowRefs.current[index+'Checkbox'] = React.createRef();
								rowRefs.current[index+'SaveStatus'] = React.createRef();
								rowRefs.current[index+'SerialNumber'] = React.createRef();
								rowRefs.current[index+'Model'] = React.createRef();
								rowRefs.current[index+'CPU'] = React.createRef();
								rowRefs.current[index+'TotalRAM'] = React.createRef();
								rowRefs.current[index+'HDD1Size'] = React.createRef();
								rowRefs.current[index+'HDD2Size'] = React.createRef();
								rowRefs.current[index+'SpecStep'] = React.createRef();
								rowRefs.current[index+'created_at'] = React.createRef();
								return (
									<React.Fragment key={row.ID}>
										<tr	className={classes.flexgridrow}>
											{/* Checkbox */}
											<td>
												<div ref={el => rowRefs.current[index + 'SaveStatus'] = el} style={{ padding: "6px 4px 2px 4px" }}>
													<input type="checkbox"
														ref={el => rowRefs.current[index + 'Checkbox'] = el}
														checked={row.isSelected}
														onKeyDown={(event) => DetectTab(event, index, 'Checkbox')}
														onChange={() => SelectRow(index, row)}
													/>
												</div>
											</td>

											{/* Serial Number - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
											{(colstate.SerialNumber) &&
												<td onClick={() => ExpandRowToggle(index)} className={classes.hoverunit}>
													{(!row.ExpandRow) &&
														<div style={{width:"30px", display:"inline-block", textAlign:"center", fontSize:"18px", lineHeight:"15px", verticalAlign:"middle"}}>
															<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandMoreIcon>
														</div>
													}
													{(row.ExpandRow) &&
														<div style={{width:"30px", display:"inline-block", textAlign:"center", fontSize:"18px", lineHeight:"15px", verticalAlign:"middle"}}>
															<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandLessIcon>
														</div>
													}
													{row.SerialNumber}
												</td>
											}
											

											{/* Model */}
											{(colstate.Model) &&
												<td>
													{row.Model}
												</td>
											}

											{/* CPU */}
											{(colstate.CPU) &&
												<td>
													{row.CPU}
												</td>
											}
											
											{/* TotalRAM */}
											{(colstate.TotalRAM) &&
												<td>
													{row.TotalRAM}
												</td>
											}

											{/* HDD1Size */}
											{(colstate.HDD1Size) &&
												<td>
													{row.HDD1Size}
												</td>
											}

											{/* HDD2Size */}
											{(colstate.HDD2Size) &&
												<td>
													{row.HDD2Size}
												</td>
											}

											{/* SpecStep */}
											{(colstate.SpecStep) &&
												<td>
													{row.SpecStep}
												</td>
											}

											{/* Client IP */}
											{(colstate.ClientIP) &&
												<td>
													{row.ClientIP}
												</td>
											}

											{/* Date */}
											{(colstate.created_at) &&
												<td>
													{row.created_at}
												</td>
											}
										</tr>
										{/* Try: conditional for any render whatsoever! */}
										{(row.ExpandRow===true) && 
											<tr>
												<td colSpan="99">
													<div style={{ margin: "25px" }}>
														<Grid container spacing={1}>
															<Grid item sm={12} md={6}>
																<div style={{fontSize:"18px"}}>
																	{(row.Manufacturer) && <React.Fragment>Manufacturer: {row.Manufacturer}<br></br></React.Fragment>}
																	Model: {row.Model}<br></br>
																	CPU: {row.CPU}<br></br>
																	Total RAM: {row.TotalRAM}<br></br>
																	{(row.HDD1Size) && <React.Fragment>HDD 1 Size: {row.HDD1Size} {row.HDD1Interface}<br></br></React.Fragment>}
																	{(row.HDD2Size) && <React.Fragment>HDD 2 Size: {row.HDD2Size} {row.HDD1Interface}<br></br></React.Fragment>}
																	{(row.OpticalDesc) && <React.Fragment>Optical: {row.OpticalDesc}<br></br></React.Fragment>}
																	{(row.OS) && <React.Fragment>OS: {row.OS}<br></br></React.Fragment>}
																	{(row.Video1Desc) && <React.Fragment>Video 1: {row.Video1Desc}<br></br></React.Fragment>}
																	{(row.Video2Desc) && <React.Fragment>Video 2: {row.Video2Desc}<br></br></React.Fragment>}
																	{(row.HorizontalResolution) && <React.Fragment>Resolution: {row.HorizontalResolution}x{row.VerticalResolution}<br></br></React.Fragment>}

																	{/* Notebook/Laptop Specs: */}
																	{(row.isMobile && row.WebcamBool===0) &&
																		<React.Fragment>Webcam: No<br></br></React.Fragment>
																	} 
																	{(row.isMobile && row.WebcamBool===1) &&
																		<React.Fragment>Webcam: Yes, {row.Webcam}<br></br></React.Fragment>
																	} 

																	{(row.isMobile && row.TouchscreenBool===0) &&
																		<React.Fragment>Touchscreen: No<br></br></React.Fragment>
																	} 
																	{(row.isMobile && row.TouchscreenBool===1) &&
																		<React.Fragment>Touchscreen: Yes, {row.TouchscreenDev}<br></br></React.Fragment>
																	} 

																	{(row.isMobile && row.BluetoothBool===0) &&
																		<React.Fragment>Bluetooth: No<br></br></React.Fragment>
																	} 
																	{(row.isMobile && row.BluetoothBool===1) &&
																		<React.Fragment>Bluetooth: Yes, {row.BluetoothDevices}<br></br></React.Fragment>
																	} 
	
																	

																	{(row.isMobile && row.Batt1Health!==null) &&
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>Batt 1</span><br></br>
																			Batt1 Health: {row.Batt1Health}%<br></br>
																			Batt1 Name: {row.Batt1Name}<br></br>
																			Batt1 Manufacturer: {row.Batt1Manufacturer}<br></br>
																			Batt1 Serial Number: {row.Batt1SerialNumber}<br></br>
																			Batt1 Power State: {row.Batt1PowerState}<br></br>
																		</React.Fragment>
																	} 
																	{(row.isMobile && row.Batt2Health!==null) &&
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>Batt 2</span><br></br>
																			Batt2 Health: {row.Batt2Health}%<br></br>
																			Batt2 Name: {row.Batt2Name}<br></br>
																			Batt2 Manufacturer: {row.Batt2Manufacturer}<br></br>
																			Batt2 Serial Number: {row.Batt2SerialNumber}<br></br>
																			Batt2 Power State: {row.Batt2PowerState}<br></br>
																		</React.Fragment>
																	} 

																	{/* HDDs */}
																	{(row.HDD1Size) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>HDD1</span><br></br>
																			HDD 1 Size: {row.HDD1Size} {row.HDD1Interface}<br></br>
																			HDD 1 Manufacturer: {row.HDD1Manufacturer}<br></br>
																			HDD 1 Model: {row.HDD1Model}<br></br>
																			HDD 1 Serial Number: {row.HDD1SerialNumber}<br></br>
																			HDD 1 SMART Status: {row.HDD1SMARTStatus}<br></br>
																			HDD 1 Reallocated Sectors: {row.HDD1ReallocatedSectors}<br></br>
																			HDD 1 Pending Sectors: {row.HDD1PendingSectors}<br></br>
																			HDD 1 Temp: {row.HDD1Temp}<br></br>
																			HDD 1 Power On Hours: {row.HDD1PowerOnHours}<br></br>
																		</React.Fragment>
																	}
																	{(row.HDD2Size) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>HDD2</span><br></br>
																			HDD 2 Size: {row.HDD2Size} {row.HDD2Interface}<br></br>
																			HDD 2 Manufacturer: {row.HDD2Manufacturer}<br></br>
																			HDD 2 Model: {row.HDD2Model}<br></br>
																			HDD 2 Serial Number: {row.HDD2SerialNumber}<br></br>
																			HDD 2 SMART Status: {row.HDD2SMARTStatus}<br></br>
																			HDD 2 Reallocated Sectors: {row.HDD2ReallocatedSectors}<br></br>
																			HDD 2 Pending Sectors: {row.HDD2PendingSectors}<br></br>
																			HDD 2 Temp: {row.HDD2Temp}<br></br>
																			HDD 2 Power On Hours: {row.HDD2PowerOnHours}<br></br>
																		</React.Fragment>
																	}
																	{(row.HDD3Size) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>HDD3</span><br></br>
																			HDD 3 Size: {row.HDD3Size} {row.HDD3Interface}<br></br>
																			HDD 3 Manufacturer: {row.HDD3Manufacturer}<br></br>
																			HDD 3 Model: {row.HDD3Model}<br></br>
																			HDD 3 Serial Number: {row.HDD3SerialNumber}<br></br>
																			HDD 3 SMART Status: {row.HDD3SMARTStatus}<br></br>
																			HDD 3 Reallocated Sectors: {row.HDD3ReallocatedSectors}<br></br>
																			HDD 3 Pending Sectors: {row.HDD3PendingSectors}<br></br>
																			HDD 3 Temp: {row.HDD3Temp}<br></br>
																			HDD 3 Power On Hours: {row.HDD3PowerOnHours}<br></br>
																		</React.Fragment>
																	}
																	{(row.HDD4Size) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>HDD4</span><br></br>
																			HDD 4 Size: {row.HDD4Size} {row.HDD4Interface}<br></br>
																			HDD 4 Manufacturer: {row.HDD4Manufacturer}<br></br>
																			HDD 4 Model: {row.HDD4Model}<br></br>
																			HDD 4 Serial Number: {row.HDD4SerialNumber}<br></br>
																			HDD 4 SMART Status: {row.HDD4SMARTStatus}<br></br>
																			HDD 4 Reallocated Sectors: {row.HDD4ReallocatedSectors}<br></br>
																			HDD 4 Pending Sectors: {row.HDD4PendingSectors}<br></br>
																			HDD 4 Temp: {row.HDD4Temp}<br></br>
																			HDD 4 Power On Hours: {row.HDD4PowerOnHours}<br></br>
																		</React.Fragment>
																	}


																	{/* Network Devices */}
																	<br></br>
																	<span style={{textDecoration:"underline"}}>Network Devices</span><br></br>
																	{(row.WifisFound) && <React.Fragment>Wifis Found: {row.WifisFound}<br></br></React.Fragment>}
																	{(row.Network1) && <React.Fragment>Network 1: {row.Network1}<br></br></React.Fragment>}
																	{(row.Network2) && <React.Fragment>Network 1: {row.Network2}<br></br></React.Fragment>}
																	{(row.Network3) && <React.Fragment>Network 1: {row.Network3}<br></br></React.Fragment>}
																	{(row.Network4) && <React.Fragment>Network 1: {row.Network4}<br></br></React.Fragment>}
																	{(row.Network5) && <React.Fragment>Network 1: {row.Network5}<br></br></React.Fragment>}
																	{(row.Network6) && <React.Fragment>Network 1: {row.Network6}<br></br></React.Fragment>}

																</div>
															</Grid>
															<Grid item sm={12} md={6}>
																<div style={{fontSize:"18px"}}>
																	
																	{/* RAM */}
																	{(row.RAM1Capacity) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>RAM 1</span><br></br>
																			RAM 1 Capacity: {row.RAM1Capacity}<br></br>
																			RAM 1 Manufacturer: {row.RAM1Manufacturer}<br></br>
																			RAM 1 Model: {row.RAM1Model}<br></br>
																			RAM 1 SerialNumber: {row.RAM1SerialNumber}<br></br>
																			RAM 1 Speed: {row.RAM1Speed}<br></br>																		
																		</React.Fragment>
																	}
																	{(row.RAM2Capacity) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>RAM 2</span><br></br>
																			RAM 2 Capacity: {row.RAM2Capacity}<br></br>
																			RAM 2 Manufacturer: {row.RAM2Manufacturer}<br></br>
																			RAM 2 Model: {row.RAM2Model}<br></br>
																			RAM 2 SerialNumber: {row.RAM2SerialNumber}<br></br>
																			RAM 2 Speed: {row.RAM2Speed}<br></br>																		
																		</React.Fragment>
																	}
																	{(row.RAM3Capacity) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>RAM 3</span><br></br>
																			RAM 3 Capacity: {row.RAM3Capacity}<br></br>
																			RAM 3 Manufacturer: {row.RAM3Manufacturer}<br></br>
																			RAM 3 Model: {row.RAM3Model}<br></br>
																			RAM 3 SerialNumber: {row.RAM3SerialNumber}<br></br>
																			RAM 3 Speed: {row.RAM3Speed}<br></br>																		
																		</React.Fragment>
																	}
																	{(row.RAM4Capacity) && 
																		<React.Fragment>
																			<br></br>
																			<span style={{textDecoration:"underline"}}>RAM 4</span><br></br>
																			RAM 4 Capacity: {row.RAM4Capacity}<br></br>
																			RAM 4 Manufacturer: {row.RAM4Manufacturer}<br></br>
																			RAM 4 Model: {row.RAM4Model}<br></br>
																			RAM 4 SerialNumber: {row.RAM4SerialNumber}<br></br>
																			RAM 4 Speed: {row.RAM4Speed}<br></br>																		
																		</React.Fragment>
																	}

																	<br></br>
																	<span style={{textDecoration:"underline"}}>Tests</span><br></br>
																	Keyboard Keys: {row.KeysHit}<br></br>
																	Wifis Found: {row.WifisFound}<br></br>		

																	<br></br>
																	{(row.SystemType===1) &&
																		<React.Fragment>System Type: Desktop<br></br></React.Fragment>
																	} 
																	{(row.SystemType===2) &&
																		<React.Fragment>System Type: Mobile<br></br></React.Fragment>
																	} 
																	{(row.SMBIOSVersion) && <React.Fragment>SMBIOS Version: {row.SMBIOSVersion}<br></br></React.Fragment>}
																	{(row.WindowsProductKey) && <React.Fragment>Windows Product Key: {row.WindowsProductKey}<br></br></React.Fragment>}
																	{(row.WindowsBiosKey) && <React.Fragment>Windows Bios Key: {row.WindowsBiosKey}<br></br></React.Fragment>}
																	{(row.OSVersion) && <React.Fragment>OS Version: {row.OSVersion}<br></br></React.Fragment>}
																	{(row.ThermalState) && <React.Fragment>Thermal State: {row.ThermalState} (Warning:4, Critical: 5)<br></br></React.Fragment>}
																	{(row.ClientIP) && <React.Fragment>Client IP: {row.ClientIP}<br></br></React.Fragment>}
																</div>
															</Grid>
														</Grid>
													</div>
												</td>
											</tr>
										}
									</React.Fragment>
									
								)
							}
						)
						}
						{(localstate.griditems.length===0) &&	
							<tr className={classes.flexgridrow}><td colSpan="100%"
							style={{padding:'12px', fontSize:"18px"}}>No Results</td></tr>
						}
					</tbody>
				</table> 
			</div>


			

		
			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[50,100,500]}
				/>
			}
	

		</div>
    );
}

export default SpecsTable;
