//Orders Table

//Goals: Preserve search variables between rerenders

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


/* ##########################  Notes on Configuration  ########################## */
// Edit Mode: Controls if we render inputs on the grid (renders take longer). Edit mode button disabled if more than 100 (configurable on button) items.

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';
import moment from 'moment';
import dayjs from 'dayjs'; //Used with new datetimepickers

//Common Utilities
import SearchInput from "../common/SearchInput";
//import RestrictInputNumber from "../common/RestrictInputNumber";
//import DBAutoComplete from '../common/DBAutoComplete';


//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Tables
//import TablePagination from '@mui/material/TablePagination';
import FlexTablePagination from '../common/FlexTablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import MarketIcon from "../common/MarketIcon";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

//PAS Icons
import pstatus1 from '../images/Pstatus1.png';
//import pstatus2 from '../images/Pstatus2.png'; Unused.
import pstatus3 from '../images/Pstatus3.png';
import sstatus1 from '../images/Sstatus1.png';
//import sstatus2 from '../images/Sstatus2.png'; Unused
import sstatus3 from '../images/Sstatus3.png';
import astatus1 from '../images/Astatus1.png';
import astatus2 from '../images/Astatus2.png';
import astatus3 from '../images/Astatus3.png';
import astatus4 from '../images/Astatus4.png';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


//Export
import ExportCSV from '../common/ExportCSV';

import theme from '../../ui/theme';

import OrdersView from "./OrdersView";


//import { dispatch } from 'd3';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};


const OrdersTable = (props) => {
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Orders"));
	dispatch(setCurrentMenuItem("/orders"));
	//Init with URL search parameters:
	// could be '?Name=Chad'
	/*
	const params = new URLSearchParams(useLocation().search);
	var nameparameter = params.get('Name');
	if (!nameparameter){nameparameter=""};
	*/


	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);
	const btnSave = useRef();
	const btnResetSearches = useRef();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;


	/* Params */
	const params = new URLSearchParams(useLocation().search);
	// These parameters can help auto-fill an autocomplete search input!
	// Links from: ProductsTable
	var productparameter = false;
	var productidparameter = params.get('ProductID');
	var productnameparamter = params.get('ProductName');
	if (productidparameter){
		productparameter = [{
				ProductID: productidparameter,
				Name: productnameparamter
			}
		];
	}


	//Container and Key Sizes
	const containersizes="260px";
	const keysizes="130px";


	//Search Configuration:
	const activesearchescount = 7;

	//Search Select Inputs for Type Drop Down
	const defaultselectinputs = [
		{value:"multisearch", text:"OmniSearch"},
		{value:"OrderID", text:"Order Number"},
		{value:"Name", text:"Name"},
		{value:"products", text:"Product"},
		{value:"Title", text:"Product Title"},
		{value:"Sku", text:"Sku"},
		{value:"markets", text:"Market"},
		{value:"Address", text:"Address"},
		{value:"City", text:"City"},
		{value:"StateCode", text:"Abbr State"},
		{value:"PostalCode", text:"ZIP"},
		{value:"Total >", text:"Total >"},
		{value:"Total <", text:"Total <"},
		{value:"DateTimeAfter", text:"Date After"},
		{value:"DateTimeBefore", text:"Date Before"},
		{value:"PrintedDate", text:"Print Date"},
		{value:"Refunded", text:"Refunded"},
		{value:"PrintStatus", text:"Printed"},
		{value:"AssetStatus", text:"Assets"},
		{value:"ShippedStatus", text:"Shipped"},
	]

	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		multisearch:{
			type:"multisearch",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		OrderID:{
			type:"OrderID",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		Name:{
			type:"Name",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		//Products AutoComplete
		products:{
			type:"products",
			keyedby:"ProductID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey:"Name", //This is the column we are searching from the table
			value: (productparameter ? productparameter : []),
			//inputValue:"", //Text Search! This *CAN* be used... but seems nicer without it!
			mode:"autocomplete", //May morph to "select" before LoadItems
			limit:10, //Database return limit, keep below ~100
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			multiple:true,
			grow:1,
			debouncetime:150
		},
		Title:{
			type:"Title",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		DateTimeAfter:{
			type:"DateTimeAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 5); //Set to 5 years ago
				// return date;
				return dayjs().subtract(5, 'year');
			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		DateTimeBefore:{
			type:"DateTimeBefore",
			value: dayjs(), //new Date,
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		Sku:{
			type:"Sku",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		//Store (market) AutoComplete
		markets:{
			type:"markets",
			keyedby:"ID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey:"Name", //This is the column we are searching from the table
			value: [], 
			mode:"autocomplete",
			defaultsearchterm:"",
			limit:10, //Database return limit, keep below ~100
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			multiple:true,
			grow:1,
			debouncetime:0 //Not a lot of typing or results.
		},
		Address:{
			type:"Address",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		City:{
			type:"City",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		StateCode:{
			type:"StateCode",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		PostalCode:{
			type:"PostalCode",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		"Total >":{
			type:"Total >",
			value:"",
			mode:"greaterthan",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		"Total <":{
			type:"Total <",
			value:"",
			mode:"lessthan",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		PrintedDate:{
			type:"PrintedDate",
			value:"",
			mode:"exactdate",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		Refunded:{
			type:"Refunded",
			value:"",
			mode:"bool",
			uuid:uuidv4(),		
			container:containersizes,
			keysize: keysizes,
			defaultvalue:"",
			grow:1,
		},
		PrintStatus:{
			//0 Not Printed, 2 Printed
			type:"PrintStatus",
			value:"",
			mode:"select",
			//Select Menu Items:
			menuitems:[
				{	
					value:"",
					key:uuidv4(),
					text:"Any",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Any",
					chipclass:classes.gradeunknown,	
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value:0,
					key:uuidv4(),
					text:"Not Printed",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Not Printed",
					chipclass:classes.gradec,
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value: 2,
					key:uuidv4(),
					text:"Printed",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Printed",
					chipclass:classes.gradea, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
			],
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			//center:true,
			grow:1,
			usechips:true
		},
		AssetStatus:{
			type:"AssetStatus",
			value:[],
			mode:"select",
			//Select Menu Items:
			menuitems:[
				{	
					value:0,
					key:uuidv4(),
					text:"None",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"None",
					chipclass:classes.gradec, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value:1,
					key:uuidv4(),
					text:"Partial",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Partial",
					chipclass:classes.gradeb, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value:2,
					key:uuidv4(),
					text:"Filled",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Full",
					chipclass:classes.gradea, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value:4,
					key:uuidv4(),
					text:"Overfilled",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Overfilled",
					chipclass:classes.gradebad, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
			],
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			//Experimental Multiple!
			multiple:true,//Change 'value' to array if true.
			//center:true,
			grow:1,
			usechips:true
		},
		ShippedStatus:{
			type:"ShippedStatus",
			value:"",
			mode:"select",
			//Select Menu Items:
			menuitems:[
				{	
					value:"",
					key:uuidv4(),
					text:"Any",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Any",
					chipclass:classes.gradeunknown,	
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value: 2,
					key:uuidv4(),
					text:"Shipped",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Shipped",
					chipclass:classes.gradea, //Test this.		
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
				{	
					value:0,
					key:uuidv4(),
					text:"Not Shipped",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Not Shipped",
					chipclass:classes.gradec,
					//Optional Chip Height and Centering:
					height:"24px",
					center:true,
				},
			],
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			//Experimental Multiple!
			multiple:false,//Change 'value' to array if true.
			//center:true,
			grow:1,
			usechips:true
		}
	}


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Empty object
		defaultvalues: [],   //Default values: Useful for initial render.
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "desc",
		orderby: "OrderDate",
		selectedcount: 0,
		rowsperpage: 50,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		expandsearch:false,
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Be sure to adjust activesearchescount
				searchpair1: inputdefaults.multisearch,
				searchpair2: inputdefaults.OrderID,
				searchpair3: inputdefaults.Name,
				searchpair4: inputdefaults.products,
				
				searchpair5: inputdefaults.markets,
				//Setup PAS
				searchpair6: inputdefaults.PrintStatus,
				searchpair7: inputdefaults.AssetStatus,
				searchpair8: inputdefaults.ShippedStatus,
			},
			nestedrelationships: {

			},
			itemlist: ""
		},
		//OrderView
		orderview: false, //Defaults to table view.
		currentids: [],
		viewmode: "edit" //edit, invoice, worksheet
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	const [key5, setKey5] = useState(uuidv4());
	const [key6, setKey6] = useState(uuidv4());
	const [key7, setKey7] = useState(uuidv4());
	const [key8, setKey8] = useState(uuidv4());

	//Expand Search
	const ToggleExpandSearch = () => {
		localstate.expandsearch = !localstate.expandsearch;

		if (!localstate.expandsearch){
			for (var i=5; i<=activesearchescount; i++){
				//Only rerender if one of the searches wasn't blank!
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'object'){
					if (localstate.searchoptions.searchpairs["searchpair" + i].value.length>0){
						//ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'string'){
					console.log(localstate.searchoptions.searchpairs["searchpair" + i].value);
					if (localstate.searchoptions.searchpairs["searchpair" + i].value!==""){
						//ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
			}
			//Proceed to Reset Partial Searches 5-8:
			localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.components;
			localstate.searchoptions.searchpairs.searchpair6 = inputdefaults['Cost >'];
			localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.NewCheckbox;
			localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.SomeSelectable;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}


	//Handle Children
	const ToggleOrderView = () => {
		localstate.orderview = !localstate.orderview;
		localstate.dbreload = true;
		UpdateState(localstate);
	}



	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length === 0) {
			//Lock in potential localstate.pendingsaves
			if (localstate.pendingsaves) {
				UpdateState(localstate);
			}
			errors.NewError({ errmsg: "No orders selected.", errshow: true, errtimeout: 5, errtype: 'neutral' });
		} else if (localstate.pendingsaves) {
			errors.NewError({ errmsg: "One or more orders have a pending save.", errshow: true, errtimeout: 5, errtype: 'warning' });
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false);
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States - ColStates  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		OrderDate: true,
		OrderID: true,
		Store: true,
		Name: true, //Derived from SFullName/BFullName, etc
		OrderItems: true,
		Total: true, //I believe this is derived from the order view and is a calculated value based on the form inputs.
		PAS: true,
		Items: false, //Sub rows
		Shipments: false, //Sub rows - Will Expand all shipments

	});

	const UpdateColState = (colstate) => {
		setColState(colstate);
	}



	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = !newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
			</React.Fragment>
		)
	}

	/* View Options Menu */
	const [showViewOptionsMenu, setViewOptionsMenu] = useState(null);
	const ShowViewOptionsMenu = (event) => {
		setViewOptionsMenu(event.currentTarget); 
	}
	const CloseViewOptionsMenu = () => {
		setViewOptionsMenu(null);
	}

	/* Edit Menu */
	const [showViewEditMenu, setViewEditMenu] = useState(null);
	const ShowViewEditMenu = (event) => {
		setViewEditMenu(event.currentTarget); 
	}
	const CloseViewEditMenu = () => {
		setViewEditMenu(null);
	}
	

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {
		setExportMenu(event.currentTarget);
	}
	const CloseExportMenu = () => {
		setExportMenu(null);
	}



	/* ##########################  Selected Rows  ########################## */
	/* Order System may require reliance on state to show/not show invoice/worksheet buttons */
	/* Test Performance.... */
	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Test without:
			// //Check for condition that would check Select All Checkbox - Requires Rerender
			// if (localstate.griditems.length === localstate.selectedindexes.length) {
			// 	UpdateState(localstate);
			// }
			localstate.griditems[index].isSelected = true;
			UpdateState(localstate);
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Test without:
			// //Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			// if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
			// 	UpdateState(localstate);
			// }
			localstate.griditems[index].isSelected = false;
			UpdateState(localstate);
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Used as prop on SearchInput
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Only rerender table if searchvalue wasn't blank!
		console.log("Typeof: "+ typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value);
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'object'){
			console.log("Object");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value.length>0){
				//ResetPendingSaves(); 
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'string'){
			console.log("String");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value!==""){
				//ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'number'){
			//Doesn't matter, 0 or 1, we still need to allow this bool to clear
			console.log("number");
			//ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
		//Ensures the input is rerendered and will work correctly:
		ResetKeys([searchnumber], false);
	};

	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber, defaultsearchterm) {
		//Clears out changes because table is about to be reloaded:
		//Not Yet Implemented on this controller:  ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1200);

	//AutoComplete Still needs to send an update so that our inputValue can remain between multiple selections:
	const onChangeAutoCompleteInput = (searchpair, searchnumber) => {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
	};

	
	

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1: true,
		show2: true,
		show3: true,
		show4: true,
		show5: true,
		show6: true,
		show7: true,
		show8: true,
		lastsearch: 8
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch + 1)] = true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch)] = false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}



	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby = property;
		localstate.dbreload = true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		if (localstate.clearselection) {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection = true;
		}

		//Need to DEEP clone:
		var newsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));

		//Optional Search Handlers:

		//AUTOCOMPLETE Handler
		//Here, we may want to convert our SearchInput searchpairs of mode "autocomplete" to things
		//that our PHP controller SearchTools can use such as modes "like" or "strict".
		//Keep in mind, if two searchpairs have the same type and the mode is "like", it will normally morph
		//into an OR statement. There are only a few reserved non-OR types: Sku, Model, Name, NotSku, NotModel, SkuStarting
		//This list may be expanded or we may possibly write something to override non-OR types when needed.
		for (var i=1; i<(activesearchescount+1); i++){
			//Products Autocomplete
			if (newsearchpairs["searchpair"+i].mode==="autocomplete" && newsearchpairs["searchpair"+i].type==="products"){
				newsearchpairs["searchpair"+i].type="ProductID";
			}
			//Markets Autocomplete
			if (newsearchpairs["searchpair"+i].mode==="autocomplete" && newsearchpairs["searchpair"+i].type==="markets" && typeof newsearchpairs["searchpair"+i].value === 'object'){
				newsearchpairs["searchpair"+i].type="Store";
				let stopat = newsearchpairs["searchpair"+i].value.length;
				for (var j=0; j<stopat; j++){
					if (newsearchpairs["searchpair"+i].value[j]['Name']==="BackMarket"){
						newsearchpairs["searchpair"+i].value.push({"Name":"Back Market"}); 
						
					}
				}
			}
			//Print, Asset, Shipped statuses
			if (newsearchpairs["searchpair"+i].mode==="select" && (newsearchpairs["searchpair"+i].type==="PrintStatus" || newsearchpairs["searchpair"+i].type==="ShippedStatus")){
				newsearchpairs["searchpair"+i].mode="like";
			}
		}

		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: newsearchpairs,
				nestedrelationships: localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length > 0) {
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint + "/orders/ordersnew?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i = 0; i < resultdata.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey = i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)) {
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Set Defaults for selectables:
						resultdata[i].SomeSelectableDefault = resultdata[i].SomeSelectable;
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = Object.assign({}, resultdata);
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		//Some controller have child views without tables
		if (thead) {
			const cols = thead.querySelectorAll('td');
			// Loop over them
			[].forEach.call(cols, function (col) {
				// Create a resizer element
				const resizer = document.createElement('div');
				resizer.classList.add([classes.resizer]);
				// Set the height
				resizer.style.height = `${table.offsetHeight - 2}px`;
				// Add a resizer element to the column
				col.appendChild(resizer);
				createResizableColumn(col, resizer);
			});
		}
	}

	/* ############### END OF RESIZER ####################*/


	useEffect(() => {
		document.title = "Orders";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	},);




	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	//Unusable at this time:
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem: true,
			ID: uuidv4(),
			Name: "",
			Cost: 0.00,
			Price: 0.00,
			Margin: 0.00,
			SomeBoolean: 0,
			SomeSelectable: "",
			NewCheckbox: false
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}


	//UNUSED - OrdersController will soon take postdata: order and orderitems - That will most definitely break this.
	// const SaveChanges = () => {
	// 	var updatearray = [];
	// 	for (var i = 0; i < localstate.griditems.length; i++) {
	// 		if (localstate.griditems[i].unsaved) {
	// 			updatearray.push(localstate.griditems[i]);
	// 		}
	// 	}
	// 	if (localstate.pendingsaves) {
	// 		if (updatearray.length > 0) {
	// 			i = 0;
	// 			var limit = updatearray.length - 1;
	// 			var updateitems = setInterval(function () {
	// 				var item = updatearray[i];
	// 				//Do work here, API call.
	// 				const postdata = {
	// 					item: item
	// 				};
	// 				axios.post(dbendpoint + "/orders/update", postdata, defaultpostoptions).then(res => {
	// 					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
	// 					if (res.status === 200) {
	// 						//If ValidateUser() fails to verify user, it sends back 'login' error. 
	// 						if (res.data.Status === "login") {
	// 							//Not logged in. Reload page causes redirect to /login
	// 							window.location.reload(false);
	// 						}
	// 						if (res.data.Status === "Success") {
	// 							//Success response also includes the item!
	// 							//If we sent new rows, we'll need to reference the old ID.
	// 							var itemindex = 0;
	// 							if (res.data.OldID) {
	// 								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
	// 								localstate.griditems[itemindex].unsaved = false;
	// 								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
	// 								//Set New ID
	// 								localstate.griditems[itemindex].ID = res.data.item.ID;
	// 							} else {
	// 								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
	// 								localstate.griditems[itemindex].unsaved = false;
	// 								//Refs allow us to update the grid live!
	// 								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
	// 							}
	// 						}
	// 						if (res.data.Status === "Failure") {
	// 							//Failure error
	// 							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" })
	// 						}
	// 					} else {
	// 						//Non-200 message from server.
	// 						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
	// 					}
	// 				});
	// 				//If we have completed all items, clear this interval and update state.
	// 				if (i === limit) {
	// 					localstate.pendingsaves = false;
	// 					clearInterval(updateitems);
	// 					UpdateState(localstate);
	// 				}
	// 				i++;
	// 			}, 200);
	// 		}
	// 		btnSave.current.style.color = "rgba(0, 0, 0, 0.87)";
	// 		btnSave.current.style.backgroundColor = "#DFDFDF";
	// 	} else {
	// 		alert("Nothing to save!");
	// 	}
	// }


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */ }
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () => {
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);




	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current = "";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value === "Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value === "Cost" || event.target.value === "Price") {
			setChangeIsFloat(true);
		}

		if (event.target.value === "SomeSelectable") {
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value === "NewCheckbox") {
			setChangeIsBool(true);
		}

		if (event.target.value === "DBItem") {
			setChangeIsDBItem(true);
		}

		if (event.target.value === "Date") {
			setChangeIsDate(true);
		}


		//Unused Examples:
		if (event.target.value === "LotID" || event.target.value === "SkidNumber") {
			setChangeIsInt(true);
		}

		if (event.target.value === "Status") {
			setChangeIsStatus(true);
		}

		if (event.target.value === "LotID" || event.target.value === "SkidNumber") {
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype === "Cost" || changetype === "Price") {
			changevalue.current = RestrictNumber(event.target.value, changevalue.current);
			//Updates view:
			changevaluefloat.current.value = changevalue.current;
			//Integers	
		} else if (changetype === "LotID" || changetype === "SkidNumber") {
			changevalue.current = RestrictNumberInteger(event.target.value, changevalue.current);
			//Updates view:
			changevalueint.current.value = changevalue.current;

		} else if (changetype === "NewCheckbox") {
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype === "Location" && event.target.value === "") {
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({ errmsg: "Value cannot be blank.", errshow: true, errtimeout: 3, errtype: "neutral" });

			//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length === 0) {
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function (searchvalue) {
		setLoadingDBItemOptions(true);
		const postdata = {
			key: dbitemkey,
			search: searchvalue,
			limit: 20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype === "Product") {
			table = "products";
		}
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint + "/" + table + "/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingDBItemOptions(false);
		});
	}, 600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype === "Name" ||
			changetype === "Price" ||
			changetype === "Cost" ||
			changetype === "SomeSelectable" ||
			changetype === "Date" ||
			changetype === "NewCheckbox"
		) {
			for (i = 0; i < items.length; i++) {
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype === "DBItem") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype === "Price") {
			for (i = 0; i < items.length; i++) {
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype === "Cost") {
			for (i = 0; i < items.length; i++) {
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata = {
			items: items
		}



		axios.post(dbendpoint + "/orders/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					//console.log(res.data);
					if (res.data['SuccessCount'] > 0) {
						errors.NewError({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 5,
							errtype: 'ok'
						})
					}
				}

				if (res.data.Status === 'Failure') {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/orders/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */
	/* Not currently used, edit mode is not setup */
	const taborder = ["Name", "Cost", "Price"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow - Special cases:
		/*
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
		*/
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		console.log("Restrict Number:" + newvalue);
		//45.
		var len = newvalue.length;
		//len=3
		var index = newvalue.indexOf('.');
		//index=2
		if (index > 0) {
			//true
			var decimalchars = (len - 1) - index;
			//decimalchars = 3-1  - 2 = 0
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:" + event.target.value);
		console.log("Raw:" + newvalue);
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ArrowLeft" &&
			event.key !== "ArrowRight" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			if (column === "Cost") {
				if (!oldvalue) {
					oldvalue = "0";
				}
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index + "Margin"].textContent = (rowRefs.current[index + "Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index + "Margin"].textContent;
				//Backspace on a decimal should yield same value for old and new
				if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
					//No need to change front end, simply update griditem
					localstate.griditems[index][column] = newvalue;
				} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			if (column === "Price") {
				if (!oldvalue) {
					oldvalue = "0";
				}
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index + "Margin"].textContent = (newvalue - rowRefs.current[index + "Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index + "Margin"].textContent;
				//Backspace on a decimal should yield same value for old and new
				if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
					//No need to change front end, simply update griditem
					localstate.griditems[index][column] = newvalue;
				} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column === "NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked) {
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column === "SomeSelectable" || column === "Cost" || column === "Price") {
					//Avoids Refs! Required for numeric input and seletables. Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;

			btnSave.current.style.color = "white";
			btnSave.current.style.backgroundColor = "#01579B";
		}
	}

	//Custom Function - Optional
	const onChangeCost = (event, index, column) => {
		if (event.key === "Tab") {
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				rowRefs.current[("0" + column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index + "Margin"].value = (rowRefs.current[index + "Price"].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index + column].value = event.target.value;
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.style.color = "white";
			btnSave.current.style.backgroundColor = "#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */
	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.OrderID;
		localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.Name;
		localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.Sku;
		localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.products;
		localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.markets;
		localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.PrintStatus;
		localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.AssetStatus;
		localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.ShippedStatus;
		

		//Set Defaults:
		setItemListSearch('');
		localstate.dbreload = true;
		UpdateState(localstate);

		//Forcefully rerender search inputs!
		ResetKeys([], true);
	}

	const ResetKeys = (keyarray, resetallbool) =>{
		if (resetallbool){
			setKey1(uuidv4());
			setKey2(uuidv4());
			setKey3(uuidv4());
			setKey4(uuidv4());
			setKey5(uuidv4());
			setKey6(uuidv4());
			setKey7(uuidv4());
			setKey8(uuidv4());
		} else {
			for (var i=0; i<keyarray.length; i++){
				if (keyarray[i]===1){
					setKey1(uuidv4());
				}
				if (keyarray[i]===2){
					setKey2(uuidv4());
				}
				if (keyarray[i]===3){
					setKey3(uuidv4());
				}
				if (keyarray[i]===4){
					setKey4(uuidv4());
				}
				if (keyarray[i]===5){
					setKey5(uuidv4());
				}
				if (keyarray[i]===6){
					setKey6(uuidv4());
				}
				if (keyarray[i]===7){
					setKey7(uuidv4());
				}
				if (keyarray[i]===8){
					setKey8(uuidv4());
				}
			}
		}
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "OrdersCSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint + "/basictable?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}


	//Show Printed
	//We're kind of out of search pair space. And to be certain we get ALL of the matching
	//print dates, we'll clear out every other search.

	const ShowPrintedDate = (printdate) =>{
		localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.OrderID; 
		localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.PrintedDate;
		localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.Sku;
		localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.PrintStatus;
		localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.AssetStatus;
		localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.ShippedStatus;
		localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.products;
		
		localstate.searchoptions.searchpairs.searchpair2.value=printdate;

		localstate.dbreload=true;
		UpdateState(localstate);

		//Forcefully rerender search inputs!
		ResetKeys([], true);
	}

	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState('');

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length === 0) {
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) => {
		//Set value for current search
		// Example:
		// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
		// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
		// localstate.dbreload = true;
		// UpdateState(localstate);

		//Or set value for your item:
		//localstate.itemdata['ProductID'] = newvalue['ProductID'];
		// localstate.pendingsaves = true;
		// btnSave.current.disabled = false;
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
		//alert(newvalue['ProductID']);



	}

	const ProductSearch = debounce(function (searchvalue) {
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {
			search: searchvalue,
			limit: 20
		};
		axios.post(dbendpoint + "/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingProductOptions(false);
		});
	}, 600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("Default basic select value");


	const CopyContent = (content) =>{
		navigator.clipboard.writeText(content);
	}



	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		/*
			OrderDate:true,
		OrderID:true,
		Store:true,
		Name:true, //Derived from SFullName/BFullName, etc
		Address:true, //These are derived values from Billing vs Shipping in view and non-editable: Line1, Line2, Line3
		City:true, 
		State:true,
		PostalCode:true,
		Total:true, //I believe this is derived from the order view and is a calculated value based on the form inputs.
		PAS: false, //Do this later on.

		Items:false, //Sub rows
		Shipments:false, //Sub rows - Will Expand all shipments

		*/
		{ id: "OrderDate", numeric: false, label: "Date", align: "left", allowsort: true, style: { width: "130px" } },
		{ id: "OrderID", numeric: false, label: "Order Number", align: "left", allowsort: true, style: { width: "150px" } },
		{ id: "Store", numeric: false, label: "Mkt", align: "center", allowsort: true, style: { width: "20px" } },
		{ id: "Name", numeric: false, label: "Name", align: "left", allowsort: false, style: { width: "100px" } }, //One good reason bulk edits and in-line editing is probably a really bad idea on this controller. This value is derived from possibly many.
		{ id: "OrderItems", numeric: false, label: "Item Summary", align: "left", allowsort: false, style: {} },
		{ id: "Total", numeric: false, label: "Total", align: "right", allowsort: true, style: { width: "50px" } },
		{ id: "PAS", numeric: false, label: "PAS", align: "center", allowsort: false, style: { width: "60px" } },

	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>

										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */ }
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () => {
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Item List - General purpose boilerplate */ }
	const [showitemlist, setShowItemList] = useState(false);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () => {
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length > 0) {
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		localstate.searchoptions.itemlist = event.target.value;
		//setItemList(event.target.value);
	}

	{/* Print Labels */ }
	const PrintLabels = () => {
		var itemids = [];
		//Grab all IDs
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids: JSON.stringify(itemids),
			labeltype: 'basiclabel',
			autoclose: false,
			seriallist: '',
			orderby: localstate.orderby,
			order: localstate.order
		};

		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", "../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				// Creating the input
				var input = document.createElement('input');
				input.type = 'hidden';
				input.name = i;
				input.value = params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	//New Order - Make DB call for new order, have it create an order number, send back InternalID and route user to new order
	const NewOrder = () => {
		axios.get(dbendpoint+"/orders/createorder").then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					console.log(res);

					OpenSingleOrder(res.data.item.InternalID);
					// if (res.data.item.Draft){
					// 	console.log("this");
					// }
					// if (res.data.item.Draft){
					// 	console.log("that");
					// }
				}
				if (res.data.Status==="Failure"){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	//Replace. Don't really want to use localstorage.
	//Want to be able to go back to table without losing search results.
	/*
	const OpenOrders = (viewtype) =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['InternalID']);
		}
		localStorage.setItem("ordersarray", JSON.stringify(itemids));
		window.open(viewtype, "_blank");
	}

	const OpenOrder = (orderarray) =>{
		//localStorage only takes strings.
		localStorage.setItem("ordersarray", JSON.stringify(orderarray));
		window.open("ordersview", "_blank");

	}
	*/
	const OpenOrders = (viewmode) => {
		var itemids = [];
		//Grab all IDs
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['InternalID']);
		}
		localstate.currentids = itemids;
		localstate.viewmode = viewmode;
		localstate.orderview = true;
		UpdateState(localstate);
		//localStorage.setItem("ordersarray", JSON.stringify(itemids));
		//window.open(viewtype, "_blank");
	}


	//This method allows us to go back to a batch of orders
	const OpenOrder = (orderid) => {
		localstate.currentids = [orderid];
		localstate.viewmode = "details";
		localstate.orderview = true;
		UpdateState(localstate);
	}

	//This method lets us open a new order in a new tab from the table view
	const OpenSingleOrder = (orderid) => {
		window.open('/order/'+orderid, "_blank"); 
	} 

	//Method to view orders requiring a print job
	const PendingPrints = (viewmode) => {
		var itemids = [];
		//Select all unprinted orders in view:
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].PrintStatus===0){
				itemids.push(localstate.griditems[i]['InternalID']);
			}
			if (itemids.length>0){
				localstate.currentids = itemids;
				localstate.viewmode = viewmode;
				localstate.orderview = true;
				UpdateState(localstate);
			} else {
				dispatch(newErrorMessage({errmsg:"No orders in view required printing.", errshow:true, errtimeout: 5, errtype:'warning'}));
				setErrorTimeout(5);
			}
			
		}
	}

	const SelectUnprinted = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].PrintStatus===0 && localstate.griditems[i].isSelected!==true){
				localstate.selectedindexes.push(i);
				localstate.griditems[i].isSelected = true;
				UpdateState(localstate);
			}
		}
	}



	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */

	/* ##########################  Render Function  ########################## */
	return (
		<React.Fragment>
			{(!localstate.orderview) &&
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<div style={{ padding: "8px", minWidth: "350px", maxWidth:"100%" }}>
						{/* Standard Page Header with right floated error message space */}
						<div style={{ height: "50px", paddingTop: "5px" }}>
							<div style={{ textAlign: "center" }}>
								<h2>Order Manager</h2>
							</div>
							{(errors.currenterror.errshow) &&
								<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
									<ErrorMessage />
									<NewErrorMessage />
								</div>
							}
						</div>

						{/* /* ##########################  Search Inputs  ########################## */}
						{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
						{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

						{(!isPrintView) &&
							<>
								{/* Inputs can be Grouped inside of new flex containers: */}
								<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
									{/* Group 1 */}
									<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>							
										<SearchInput
											searchinput={1}
											key={key1}
											localstate={state}
											inputdefaults={inputdefaults}
											onChangeSearchType={onChangeSearchType}
											onChangeSearchValue={onChangeSearchValue}
											onChangeAutoCompleteInput={onChangeAutoCompleteInput}
											selectinputs={defaultselectinputs}
										/>
										<SearchInput
												searchinput={2}
												key={key2}
												localstate={state}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
										/>
										<SearchInput
												searchinput={3}
												key={key3}
												localstate={localstate}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
											/>
										<SearchInput
											searchinput={4}
											key={key4}
											localstate={localstate}
											inputdefaults={inputdefaults}
											onChangeSearchType={onChangeSearchType}
											onChangeSearchValue={onChangeSearchValue}
											onChangeAutoCompleteInput={onChangeAutoCompleteInput}
											selectinputs={defaultselectinputs}
										/>
									</div>
		
									{/* Group 2 */}
									{(localstate.expandsearch) &&
										<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
											<SearchInput
												searchinput={5}
												key={key5}
												localstate={localstate}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
											/>
										
											<SearchInput
												searchinput={6}
												key={key6}
												localstate={localstate}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
											/>
											<SearchInput
												searchinput={7}
												key={key7}
												localstate={localstate}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
											/>
											<SearchInput
												searchinput={8}
												key={key8}
												localstate={localstate}
												inputdefaults={inputdefaults}
												onChangeSearchType={onChangeSearchType}
												onChangeSearchValue={onChangeSearchValue}
												onChangeAutoCompleteInput={onChangeAutoCompleteInput}
												selectinputs={defaultselectinputs}
											/>
										</div>
									}
								</div>
							</>
						}

						{/* End of Search Inputs */}


						{/* Top Buttons & Pagination */}
						{(!isPrintView) &&
							<React.Fragment>
								<div style={{ height: "5px" }}>&nbsp;</div>
								<div style={{ display: "flex", flexGrow: 0, flexDirection: "row", flexWrap: "wrap" }}>
									{/* Buttons - Wrap inside container to prevent them from growing and walking away. */}
									<div>
									{(!showdeleteconfirmation && !showexportconfirmation) &&
										<React.Fragment>


											{/* ##########################  Column Toggles  ########################## */}

											{(userPerms.createOrder === 1) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => NewOrder()}>
													<AddIcon />&nbsp;New Order
												</Button>
											}



											{/* New VIEW OPTIONS menu */}
											{/* DEMO how we might still get column options up in here.*/}
											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												aria-haspopup="true"
												onClick={ShowViewOptionsMenu}>
												View Options
											</Button>

							
											<Menu
												className={classes.bluebtn}
												color="primary"
												id="simple-menu"
												anchorEl={showViewOptionsMenu} 
												keepMounted
												open={Boolean(showViewOptionsMenu)}
												onClose={CloseViewOptionsMenu}
											>
												<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
													<div style={{ fontWeight: "bold", textAlign: "center" }}>Columns</div>
													<MenuItem disableRipple className={classes.columnmenu}>
														<div style={{ verticalAlign: "top" }}> {/* Optional container for 2 column menu! */}
															<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
																<FormGroup>
																	<FlexColumnOption value="OrderDate" label="Date" />
																	<FlexColumnOption value="OrderID" label="Order ID" />
																	<FlexColumnOption value="Store" label="Market" />
																	<FlexColumnOption value="Name" label="Name" />
																	<FlexColumnOption value="OrderItems" label="Items" />
																	<FlexColumnOption value="Total" label="Total" />
																	<FlexColumnOption value="PAS" label="PAS" />
																</FormGroup>
															</div>
														</div>
													</MenuItem>
												</div>
												<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top", maxWidth: "200px", padding: "0px 8px" }}>
												<div style={{ fontWeight: "bold", textAlign: "center" }}>Search Options</div>
													<Button
														className={classes.bluebtn}
														sx={{ width: "100%" }}
														color="primary" variant="contained"
														onClick={() => ToggleExpandSearch()}
													>
														{(!localstate.expandsearch) &&
															<>Expand Search</>
														}
														{(localstate.expandsearch) &&
															<>Collapse Search</>
														}
													</Button>
													<br></br><br></br>
													<div style={{ fontWeight: "bold", textAlign: "center" }}>Table Options</div>
													<Button
														className={classes.bluebtn}
														sx={{ width: "100%" }}
														color="primary" variant="contained"
														onClick={() => ExpandAll()}
													>
														Expand All
													</Button>
												</div>
											</Menu>
											{/* End of View Options Menu */}



											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												aria-haspopup="true"
												onClick={ShowExportMenu}>
												Export
											</Button>

											<Menu id="simple-menu"
												anchorEl={showexportmenu}
												keepMounted
												open={Boolean(showexportmenu)}
												onClose={CloseExportMenu}>
												<MenuItem onClick={() => RejectIfInvalidSelected("selected", InitExport)}>Export Selected</MenuItem>
												<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 2000)</MenuItem>
											</Menu>

											{/* <Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												onClick={() => PrintLabels()}>
												Print Labels
											</Button> */}

											{/* <Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												onClick={() => PendingPrints("worksheet")}
												>

												Pending Prints
											</Button> */}

											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												onClick={() => SelectUnprinted()}>
												Select Unprinted
											</Button>

											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												disabled={((localstate.selectedindexes.length > 50) || (localstate.selectedindexes.length === 0)) ? true : false}
												onClick={() => RejectIfInvalidSelected("invoice", OpenOrders)}
											>

												View Invoices
											</Button>

											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												disabled={((localstate.selectedindexes.length > 50) || (localstate.selectedindexes.length === 0)) ? true : false}
												onClick={() => RejectIfInvalidSelected("worksheet", OpenOrders)}>
													View Worksheets
												</Button>
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => ResetSearches()}
													ref={el => btnResetSearches.current = el}>
													<RestartAltIcon />&nbsp;Reset
												</Button>	
										</React.Fragment>
									}

									{/* Delete Items Confirmation */}
									{(showdeleteconfirmation) &&
										<div>
											<b>Are you sure you want to delete these items?</b>
											<div style={{ padding: "10px 0px" }}>
												{deleteitems.map((row, index) => {
													if (deleteitems.length === index + 1) {
														return (<span key={index}>{row.Name}</span>)
													} else {
														return (<span key={index}>{row.Name}, </span>)
													}
												})}
											</div>
											<Button
												className={classes.bluebtn}
												color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
											<Button
												className={classes.bluebtn}
												color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
										</div>
									}

									{/* Export Mode Confirmation */}
									{(showexportconfirmation) &&
										<div>
											<b>Export Mode</b><br></br>
											In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
											<div style={{ paddingTop: "10px" }}>
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => PrepareExport("simple")}>
													Simple
												</Button>
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => PrepareExport("expanded")}>
													Expanded
												</Button>
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => PrepareExport("exhaustive")}>
													Exhaustive
												</Button>
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => setShowExportConfirmation(false)}>
													Cancel
												</Button>
											</div>
										</div>
									}

									</div>

									<div style={{flexGrow: 1, flexShrink:1}}>
										{/* Spacer that grows between buttons and pagination! */}
									</div>
							

									<div style={{flexGrow: 1, textAlign:"right"}}>
									{(localstate.totalitems > 0) &&
										<FlexTablePagination 
											localstate={localstate}
											classes={classes} 
											editmode={editmode}
											handleChangePage={handleChangePage}
											handleChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									}
								</div>
							</div>
							</React.Fragment>
						}
						{/* End of Top Buttons & Pagination */}

						{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
						<div className={classes.flexgridcontainerA}>
							{/* ##########################  Start of Table  ########################## */}
							<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
								<EnhancedTableHead
									numSelected={localstate.selectedcount}
									classes={classes}
									order={localstate.order}
									orderBy={localstate.orderby}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={state.griditems.length}
								/>
								{/* /* ##########################  Row Design  ########################## */}
								{/* If DB reload, don't allow view of table. */}
								{(!localstate.dbreload) &&
									<tbody style={{ display: "table-row-group" }}>
										{(localstate.griditems.length > 0) &&
											localstate.griditems.map((row, index) => {
												//Create all-new refs on each render. Helps avoid issues with grid states.
												/*
													OrderDate:true,
													OrderID:true,
													Store:true,
													Name:true, //Derived from SFullName/BFullName, etc
													Address:true, //These are derived values from Billing vs Shipping in view and non-editable: Line1, Line2, Line3
													City:true, 
													State:true,
													PostalCode:true,
													Total:true, //I believe this is derived from the order view and is a calculated value based on the form inputs.
													PAS: false, //Do this later on.
													Items:false, //Sub rows
													Shipments:false, //Sub rows - Will Expand all shipments
					*/
												rowRefs.current[index + "Checkbox"] = React.createRef();
												rowRefs.current[index + "SaveStatus"] = React.createRef();

												return (
													<React.Fragment key={row.InternalID}>
														<tr className={classes.flexgridrow}>
															{/* Checkbox - Requires inner div to change background color with SaveStatus */}
															<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
																<div style={{ padding: "3px 4px 1px 4px" }}>
																	{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
																	{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	checked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

																</div>
															</td>

															{/* OrderDate */}
															{(colstate.OrderDate) &&
																<td className={classes.flexgridstaticcontainer} onClick={() => ExpandRowToggle(index)}>
																	<Moment element='span' format="YYYY-MM-DD h:mm A">{row.OrderDate}</Moment>
																</td>
															}

															{/* OrderID */}
															{(colstate.OrderID) &&
																<td className={classes.flexgridstaticcontainer}>
																	<a label={row.InternalID} href={"/order/"+row.InternalID} className={classes.hoverunit} target="_blank">{row.OrderID}</a>

																</td>
															}

															{/* Market (Store) */}
															{(colstate.Store) &&
																<td style={{ textAlign: "center", paddingTop: "4px" }} onClick={() => ExpandRowToggle(index)}>
																	<MarketIcon height="20px" storename={row.Store} verticalalignment="bottom" />
																</td>
															}


															{/* Name */}
															{(colstate.Name) &&
																<td className={classes.flexgridstaticcontainer} style={{ textAlign: "left" }} onClick={() => ExpandRowToggle(index)}>
																	{(row.SFullName) &&
																		<>{row.SFullName}</>
																	}
																	{(!row.SFullName && row.SFirstName) &&
																		<>{row.SFirstName} {row.SLastName}</>
																	}
																	{(!row.SFullName && !row.SFirstName) &&
																		<>{row.BFirstName} {row.BLastName}</>
																	}
																	{(row.ExpandRow) &&
																		<>
																			<br></br>
																			{(row.SLine1 && row.SCity && row.SStateCode && row.SPostalCode) &&
																				<>
																					{row.SLine1}<br></br>
																					{row.SCity}{(row.SCity ? "," : "")} {row.SStateCode} {row.SPostalCode.substring(0, 5)}
																				</>
																			}
																			{(!row.SLine1 && row.BLine1 && row.BCity && row.BStateCode && row.BPostalCode) &&
																				<>
																					{row.BLine1}<br></br>
																					{row.BCity}{(row.BCity ? "," : "")} {row.BStateCode} {row.BPostalCode.substring(0, 5)}
																				</>
																			}
																			<br></br>

																		</>
																	}
																</td>
															}

															{/* Items */}
															{(colstate.OrderItems) &&
																<td className={classes.flexgridstaticcontainer} style={{ textAlign: "left" }}>
																	{(row.orderitems.length > 0) &&
																		<div className={(row.orderitems.length>1) ? classes.flexgridspace30 : ""} style={{height:(row.ExpandRow ? (row.orderitems.length)*37+"px" : "37px")}}>
																			<div className={classes.truncatedtextcontainer}>
																				<div className={classes.truncatedtext}>
																					<span className={classes.hoverunit} onClick={() => ExpandRowToggle(index)}><b>({row.orderitems[0].Quantity})</b> {row.orderitems[0].ProductName}</span><br></br>
																					Sku: {row.orderitems[0].Code}&nbsp;
																					{(row.ExpandRow) &&
																						<ContentCopyIcon 
																						className={classes.hoverunit}
																						style={{fontSize:"14px"}} 
																						onClick={()=>CopyContent(row.orderitems[0].Code)} /> 
																					}
																					{(row.orderitems[0].sku['Configured']===0) &&
																						<div style={{color:"orange", fontSize:"12px", display:"inline-block", marginBottom:"2px"}}>&nbsp;&nbsp;Not Configured</div>
																					}

																					{(row.orderitems.length > 1 && row.ExpandRow) &&
																						row.orderitems.map((orderitem, orderitemindex) => {
																							//Avoid render of first item already rendered.
																							return (
																								(orderitemindex !== 0) &&
																								<React.Fragment key={"orderitem" + orderitemindex}>
																									<br></br>
																									<b>({orderitem.Quantity})</b> {orderitem.ProductName}<br></br>
																									Sku: {orderitem.Code}&nbsp;<ContentCopyIcon 
																									className={classes.hoverunit}
																									style={{fontSize:"14px"}} 
																									onClick={()=>CopyContent(orderitem.Code)} /> 
																								</React.Fragment>
																							)
																						})
																					}
																				</div>
																			</div>
																		</div>
																	}
																	{(row.orderitems.length > 1) &&
																		<div style={{ display: "inline-block", verticalAlign:"top"}} onClick={() => ExpandRowToggle(index)}>
																			{(!row.ExpandRow) &&
																				<div className={classes.flexgridexpand} style={{ float: "right" }}>
																					<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandMoreIcon>
																				</div>
																			}
																			{(row.ExpandRow) &&
																				<div className={classes.flexgridexpand} style={{ float: "right" }}>
																					<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandLessIcon>
																				</div>
																			}
																		</div>
																	}
																</td>
															}

															{/* Total */}
															{(colstate.Total) &&
																<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
																	{row.Total}
																</td>
															}

															{/* PAS */}
															{(colstate.PAS) &&
																<td className={classes.flexgridstaticcontainer} style={{ textAlign: "center" }}>
																	{(row.Canceled === 1) &&
																		<React.Fragment>
																			Canceled
																		</React.Fragment>
																	}

																	{(row.Canceled === 0) &&
																		<React.Fragment>
																			{(row.PrintStatus === 0) &&
																				<img style={{ height: "25px", marginLeft: "5px" }} src={pstatus1} />
																			}
																			{(row.PrintStatus === 2) &&
																				<a onClick={() => {ShowPrintedDate(row.PrintedDate)}} className={classes.hoverunit}>
																					<img style={{ height: "25px", marginLeft: "5px" }} src={pstatus3} />
																				</a>
																			}

																			{/*
																		0: no asset
																		1: Under Capacity
																		2: Normal Fulfilled
																		4: Over capacity
																	*/}
																			{(row.AssetStatus === 0) &&
																				<img style={{ height: "25px", marginLeft: "5px" }} src={astatus1} />
																			}
																			{(row.AssetStatus === 1) &&
																				<a href={"/order/" + row.InternalID} target="_blank"><img style={{ height: "25px", marginLeft: "5px" }} src={astatus2} /></a>
																			}

																			{(row.AssetStatus === 2) &&
																				<a href={"/order/" + row.InternalID} target="_blank"><img style={{ height: "25px", marginLeft: "5px" }} src={astatus3} /></a>
																			}
																			{(row.AssetStatus === 4) &&
																				<a href={"/order/" + row.InternalID} target="_blank"><img style={{ height: "25px", marginLeft: "5px" }} src={astatus4} /></a>
																			}



																			{(row.ShippedStatus === 0) &&
																				<a href={"/order/" + row.InternalID} target="_blank"><img style={{ height: "25px", marginLeft: "5px" }} src={sstatus1} /></a>
																			}
																			{(row.ShippedStatus === 2) &&
																				<a href={"/order/" + row.InternalID} target="_blank"><img style={{ height: "25px", marginLeft: "5px" }} src={sstatus3} /></a>
																			}
																		</React.Fragment>

																	}

																	

																</td>
															}


															{/* Price */}
															{(editmode && colstate.Price) &&
																<td className={classes.flexgridinputcontainer}>
																	<input
																		type="number" step="0.01"
																		ref={el => rowRefs.current[index + 'Price'] = el}
																		className={classes.flexgridinput}
																		style={{ minWidth: '50px', textAlign: "right" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'Price')}
																		onKeyUp={(event) => onChangeValue(event, index, 'Price')}
																		onBlur={(event) => DetectBlankNumber(event, index, "Price")}
																		defaultValue={localstate.defaultvalues[index].Price} />
																</td>
															}

															{(!editmode && colstate.Price) &&
																<td className={classes.flexgridstaticcontainer}>
																	{row.Price}
																</td>
															}

															{/* Margin */}
															{(colstate.Margin) &&
																<td className={classes.flexgridstaticcontainer}>
																	<span ref={el => rowRefs.current[index + "Margin"] = el}>
																		{row.Margin}
																	</span>
																</td>
															}

															{/* SomeBoolean */}
															{(colstate.SomeBoolean) &&
																<td>
																	{/* Try not to use this version of a boolean checkbox. It incurs expensive rerenders.*/}
																	<div style={{ padding: "3px 4px 1px 4px" }}>
																		<input type="checkbox"
																			ref={el => rowRefs.current[index + "SomeBoolean"] = el}
																			checked={row.SomeBoolean === 1}
																			onChange={(event) => onChangeValue(event, index, "SomeBoolean")}
																		/>
																		{row.SomeBoolean}
																	</div>
																</td>
															}

															{/* SomeSelectable */}
															{(colstate.SomeSelectable) &&
																<td>
																	<FormControl className={classes.formControl} variant="standard">
																		{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
																		{/* Need: Set default value if row.SomeSelectable is null. */}
																		{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

																		{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
																		{/* Override select prop class with new padding specs */}

																		<Select
																			defaultValue={row.SomeSelectableDefault ? row.SomeSelectableDefault : "--"} disableUnderline
																			onChange={(event) => onChangeValue(event, index, "SomeSelectable")}
																			classes={{
																				icon: classes.nodisplay,
																				select: classes.selectpadding
																			}}
																		>
																			<MenuItem value="--">
																				<em>None</em>
																			</MenuItem>
																			<MenuItem value={10}>Ten</MenuItem>
																			<MenuItem value={"A"}>
																				<Chip size="small" label="A" clickable className={classes.gradea} />
																			</MenuItem>
																			<MenuItem value={"B"}>
																				<Chip size="small" label="B" clickable className={classes.gradeb} />
																			</MenuItem>
																			<MenuItem value={"C"}>
																				<Chip size="small" label="C" clickable className={classes.gradec} />
																			</MenuItem>
																			<MenuItem value={"Bad"}>
																				<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																			</MenuItem>
																			<MenuItem value={"Repair"}>
																				<Chip size="small" label="Repair" clickable className={classes.graderepair} />
																			</MenuItem>
																			<MenuItem value={"Scrap"}>
																				<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
																			</MenuItem>
																		</Select>
																	</FormControl>
																</td>
															}

															{/* NewCheckbox */}
															{(colstate.NewCheckbox) &&
																<td>
																	<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
																		<Checkbox
																			className={classes.gridcheckbox}
																			disableRipple
																			color="default"
																			defaultChecked={row.NewCheckbox === 1 ? true : false}
																			checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																			icon={<span className={classes.icon} />}
																			onChange={(event) => onChangeValue(event, index, "NewCheckbox")}
																		/>
																	</div>
																</td>
															}

															{/* Date */}
															{(colstate.Date) &&
																<td className={classes.flexgridstaticcontainer}>
																	{row.Date}
																</td>
															}

														</tr>
													</React.Fragment>

												)
											}
											)
										}
										{(localstate.griditems.length === 0) &&
											<tr className="flexgridrow"><td colSpan="100%"
												style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
										}
									</tbody>
								}
								{(localstate.dbreload) &&
									<tbody>
										<tr>
											<td colSpan="100%">
												<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
													<CircularProgress />
												</div>
											</td>
										</tr>
									</tbody>
								}
							</table>
						</div>


						{(localstate.totalitems > localstate.rowsperpage) &&
							<FlexTablePagination 
								localstate={localstate}
								classes={classes} 
								editmode={editmode}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						}

					</div>
				</LocalizationProvider>
			}

			{(localstate.orderview) &&
				<OrdersView 
					isChild={true} 
					currentids={localstate.currentids} 
					viewmode={localstate.viewmode} 
					ToggleOrderView={ToggleOrderView} 
					draft={false}
					
				/>
			}
		</React.Fragment>

	);
}

export default OrdersTable;
