import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import axios from "axios";

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

const EbayAuth = (props) => {
	//URL Param for code
	const params = new URLSearchParams(useLocation().search);
	var code = params.get('code');
	console.log(code);
	
	//Need to run once, a request to save the code as SecretKey
	useEffect(() => {
		SetEbayUserConsentCode(code);
	}, []);

	const SetEbayUserConsentCode = (code)=>{
		var postdata ={
			code:code
		};
	
		axios.post(dbendpoint+'/skuintegrations/setebayauth', postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					console.log(res);
				}
				if (res.data.Status==="Failure"){
					console.log("failure");
				}
			} else {
				console.log("Non-200.");
			}
		}).catch(err => {
			console.log("Error.");
		});
		
	}


	return(
		<>
			Ebay Auth<br></br>
			Code: {code}
		
		</>
	);
}

export default EbayAuth;